import {
  Box,
  Flex,
  Heading,
  Image,
  Spacer,
  Stack,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import { MuiColorInput } from "mui-color-input";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import "../../../assets/styles/App.css";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosImageCropper,
  PosInput,
  PosLable,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const CategoryCreate = (props) => {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const { addToast } = PosTostMessage();
  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state); // store in state

  const [buttonDisable, setButtonDisable] = useState(false);
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const myContext = useContext(AppContext);
  const effectRun = useRef(true);
  const [allTouchedField, setAllTouchedField] = useState(false);

  const [backgroundColor, setBackgroundColor] = React.useState(
    Constants.DEFAULT_BACKGROUND_COLOR
  );
  const [borderColor, setBorderColor] = React.useState(
    Constants.DEFAULT_BORDER_COLOR
  );
  const [textColor, setTextColor] = React.useState(
    Constants.DEFAULT_TEXT_COLOR
  );
  const [imageUploadedFlag, setImageUploadedFlag] = useState(0);
  const [base64OfUploadedImg, setBase64OfUploadedImg] = useState("");
  const [croppedImgDetails, setCroppedImgDetails] = useState({
    filename: "",
    filetype: "",
    filesize: "",
    base64: "",
  });
  const [catImage, setCatImage] = useState("");

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (actionRun.current === true) {
      if (
        undefined != actionResponse &&
        null != actionResponse &&
        {} != actionResponse
      ) {
        setButtonDisable(false);
        if (
          undefined != actionResponse?.error &&
          null != actionResponse?.error &&
          actionResponse?.error
        ) {
          let actionData = actionResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else {
          props.navigate(Constants.CATEGORY_LIST, {
            state: paramData,
          });
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.CATEGORY_MODULE,
            alertDescription: actionResponse.data.message,
          });
          myContext.handleLoading(false);
        }
        /* else {
                  let actionData = actionResponse;
                  error({ actionData });
                } */
      }
      // return () => {
      //     actionRun.current = false;
      // };
    }
  }, [actionResponse]);

  const handleChangeBackgroundColor = (bgColor) => {
    bgColor = bgColor.toUpperCase();
    setBackgroundColor(bgColor);
  };
  const handleChangeBorderColor = (borderColor) => {
    borderColor = borderColor.toUpperCase();
    setBorderColor(borderColor);
  };
  const handleChangeTextColor = (textColor) => {
    textColor = textColor.toUpperCase();
    setTextColor(textColor);
  };

  const handleCallback = (base64Details, fileDetails) => {
    if (
      null != base64Details &&
      "" != base64Details &&
      null != fileDetails &&
      "" != fileDetails
    ) {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: fileDetails["name"],
          filetype: fileDetails["type"],
          filesize: fileDetails["size"],
          base64: base64Details.toDataURL().split(",")[1],
        };
      });
      setBase64OfUploadedImg(base64Details.toDataURL());
      setImageUploadedFlag(1);
    } else {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: "",
          filetype: "",
          filesize: "",
          base64: "",
        };
      });
      setBase64OfUploadedImg("");
      setImageUploadedFlag(0);
    }
  };

  // const formik = useFormik({
  //     initialValues: {
  //         name: "",
  //         category_sort: "",
  //         online_ordering_sort: "",
  //         stay_on_cat_prod_page: false,
  //         hide_cat_from_pos_attr: false,
  //         show_in_online_ordering: true,
  //         background_color: "",
  //         border_color: "",
  //         text_color: ""
  //     },
  //     validationSchema: Yup.object({
  //         name: Yup.string().required(Constants.NAME_REQUIRED).max(190, Constants.NAME_LENGTH_GREATER_THEN_191),
  //         category_sort: Yup.number().min(1, Constants.CATEGORY_SORT_MIN_ERROR).max(999, Constants.CATEGORY_SORT_MAX_ERROR),
  //         online_ordering_sort: Yup.number().min(1, Constants.ONLINE_ORDERING_SORT_MIN_ERROR).max(999, Constants.ONLINE_ORDERING_SORT_MAX_ERROR),
  //     }),
  //     onSubmit: async (values) => {
  //         try {
  //             values.background_color = backgroundColor;
  //             values.border_color = borderColor;
  //             values.text_color = textColor;
  //             if (undefined != values.stay_on_cat_prod_page.target && null != values.stay_on_cat_prod_page.target && '' != values.stay_on_cat_prod_page.target) {
  //                 values.stay_on_cat_prod_page = values.stay_on_cat_prod_page.target.checked;
  //             }
  //             if (undefined != values.hide_cat_from_pos_attr.target && null != values.hide_cat_from_pos_attr.target && '' != values.hide_cat_from_pos_attr.target) {
  //                 values.hide_cat_from_pos_attr = values.hide_cat_from_pos_attr.target.checked;
  //             }
  //             if (undefined != values.show_in_online_ordering.target && null != values.show_in_online_ordering.target && '' != values.show_in_online_ordering.target) {
  //                 values.show_in_online_ordering = values.show_in_online_ordering.target.checked;
  //             }
  //             if (imageUploadedFlag == 1) {
  //                 var imageDetails = {
  //                     "categorypic": croppedImgDetails
  //                 }
  //                 values.categoryImage = imageDetails;
  //             }

  //             categoryCreate(values)
  //                 .then((response) => {
  //                     if (
  //                         response.data[Constants.FLAGE] !== null &&
  //                         response.data[Constants.FLAGE] === true
  //                     ) {
  //                         //top-right,success,warning,Tax Created,code,999,warning,Field is reqired.,401
  //                         addToast({
  //                             alertStatus: Constants.TOAST_TYPE_SUCESS,
  //                             alertTitle: "Category Created",
  //                             alertDescription: response.data.message,
  //                         });
  //                         props.navigate(Constants.CATEGORY_LIST);
  //                     } else {
  //                         if (
  //                             response.data[Constants.CODE] === Constants.REQUEST_DENIED_CODE
  //                         ) {
  //                             addToast({
  //                                 alertStatus: Constants.TOAST_TYPE_WARNING,
  //                                 alertTitle: Constants.TOKEN_EXPIRTED,
  //                                 alertDescription: response.data.message,
  //                             });
  //                             props.navigate(Constants.LOGOUT_PAGE);
  //                         } else if (
  //                             response.data[Constants.CODE] === Constants.UNAUTHORIZE_CODE
  //                         ) {
  //                             //unauthorise page par redirect
  //                             props.navigate(Constants.UNAUTHORIZE_PAGE);
  //                         } else if (response.data.validationCheck) {
  //                             addToast({
  //                                 alertStatus: Constants.TOAST_TYPE_WARNING,
  //                                 alertTitle: Constants.FIELD_IS_REQUIRED,
  //                                 alertDescription: response.data.message,
  //                             });
  //                         } else {
  //                             addToast({
  //                                 alertStatus: Constants.TOAST_TYPE_WARNING,
  //                                 alertTitle: Constants.FIELD_IS_REQUIRED,
  //                                 alertDescription: response.data.message,
  //                             });
  //                         }
  //                     }
  //                 })
  //                 .catch((error) => {
  //                     console.error("Error fetching user data:", JSON.stringify(error));
  //                     if (
  //                         error.response.data[Constants.CODE] ===
  //                         Constants.REQUEST_DENIED_CODE
  //                     ) {
  //                         addToast({
  //                             alertStatus: Constants.TOAST_TYPE_WARNING,
  //                             alertTitle: Constants.TOKEN_EXPIRTED,
  //                             alertDescription: error.response.data.message,
  //                         });
  //                         props.navigate(Constants.LOGOUT_PAGE);
  //                     } else if (
  //                         error.response.data[Constants.CODE] === Constants.UNAUTHORIZE_CODE
  //                     ) {
  //                         props.navigate(Constants.UNAUTHORIZE_PAGE);
  //                     } else if (error.response.data.validationCheck) {
  //                         addToast({
  //                             alertStatus: Constants.TOAST_TYPE_WARNING,
  //                             alertTitle: Constants.FIELD_IS_REQUIRED,
  //                             alertDescription: error.response.data.message,
  //                         });
  //                     } else {
  //                         addToast({
  //                             alertStatus: Constants.TOAST_TYPE_WARNING,
  //                             alertTitle: Constants.FIELD_IS_REQUIRED,
  //                             alertDescription: error.response.data.message,
  //                         });
  //                     }
  //                 });
  //         } catch (error) {
  //             console.error("Error:", error);
  //         }
  //     },
  // });

  const handleImage = (img) => {
    setCatImage(img);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      category_sort: "",
      online_ordering_sort: "",
      stay_on_cat_prod_page: false,
      hide_cat_from_pos_attr: false,
      show_in_online_ordering: true,
      background_color: "",
      border_color: "",
      text_color: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.NAME_REQUIRED)
        .max(191, Constants.NAME_LENGTH_GREATER_THEN_191),
      category_sort: Yup.number()
        .typeError(Constants.CATEGORY_SORT_INTEGER_ERROR)
        .integer(Constants.CATEGORY_SORT_INTEGER_ERROR)
        .min(1, Constants.CATEGORY_SORT_MIN_ERROR)
        .max(999, Constants.CATEGORY_SORT_MAX_ERROR),
      online_ordering_sort: Yup.number()
        .typeError(Constants.ONLINE_ORDERING_SORT_INTEGER_ERROR)
        .integer(Constants.ONLINE_ORDERING_SORT_INTEGER_ERROR)
        .min(1, Constants.ONLINE_ORDERING_SORT_MIN_ERROR)
        .max(999, Constants.ONLINE_ORDERING_SORT_MAX_ERROR),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      actionRun.current = true;
      setButtonDisable(true);

      values.background_color = backgroundColor;
      values.border_color = borderColor;
      values.text_color = textColor;
      if (
        undefined != values.stay_on_cat_prod_page &&
        null != values.stay_on_cat_prod_page &&
        "" != values.stay_on_cat_prod_page
      ) {
        values.stay_on_cat_prod_page = values.stay_on_cat_prod_page;
      }
      if (
        undefined != values.hide_cat_from_pos_attr &&
        null != values.hide_cat_from_pos_attr &&
        "" != values.hide_cat_from_pos_attr
      ) {
        values.hide_cat_from_pos_attr = values.hide_cat_from_pos_attr;
      }
      if (
        undefined != values.show_in_online_ordering &&
        null != values.show_in_online_ordering &&
        "" != values.show_in_online_ordering
      ) {
        values.show_in_online_ordering = values.show_in_online_ordering;
      }
      if (imageUploadedFlag == 1) {
        var imageDetails = {
          categorypic: croppedImgDetails,
        };
        values.categoryImage = JSON.stringify(imageDetails);
      }
      setAllTouchedField(false);
      submit(values, {
        method: Constants.POST,
        path: Constants.CATEGORY_CREATE_API_ROUTE,
      });
    },
  });

  const handleKeyDown = (event) => {
    // if (
    //   event.key === "Enter" &&
    //   !(formik.values?.name?.length <= 0 ||
    //   formik.errors?.name ||
    //   formik.errors?.category_sort ||
    //   formik.errors?.online_ordering_sort ||
    //   buttonDisable
    //     ? true
    //     : false)
    // ) {
    //   formik.handleSubmit();
    // }
    if (event.key === "Enter" && !buttonDisable) {
      if (!formik.isValid) {
        if (!allTouchedField) {
          formik.handleSubmit();
          setAllTouchedField(true);
        }
        // addToast({
        //   alertStatus: Constants.TOAST_TYPE_ERROR,
        //   alertTitle: Constants.SUBMIT_TIME_FORM_NOT_VALID_TITLE,
        //   alertDescription: Constants.SUBMIT_TIME_FORM_NOT_VALID,
        // });
      } else {
        formik.handleSubmit();
      }
    }
  };

  const formatCategoryName = (name) => {
    if (!name) return "PR";
    const firstLetter = name.charAt(0).toUpperCase();
    const secondLetter = name.charAt(1).toUpperCase();
    return firstLetter + secondLetter;
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const formatedCategoryName = (name) => {
    const capitalized = capitalizeWords(name);
    return truncateText(capitalized, 30);
  };

  const formattingName = formatedCategoryName(formik.values.name);
  const formattedName = formatCategoryName(formik.values.name);
  const capitalizedProductName = capitalizeWords(formik.values.name);
  return (
    // <MainLayout>
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" onKeyDown={handleKeyDown}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.CATEGORY_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Categories", "Create Category"]}
            breadCrumTitle={"Create Category"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              isDisabled={buttonDisable ? true : false}
              onClick={() => {
                props.navigate(Constants.CATEGORY_LIST, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={buttonDisable ? true : false}
              // isDisabled={
              //   formik.values.name.length <= 0 ||
              //   formik.errors.name ||
              //   formik.errors.category_sort ||
              //   formik.errors.online_ordering_sort ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              // onClick={formik.handleSubmit}
              onClick={() => {
                // submit time all validation fired and error massage display.
                if (!formik.isValid) {
                  if (!allTouchedField) {
                    formik.handleSubmit();
                    setAllTouchedField(true);
                  }
                  // addToast({
                  //   alertStatus: Constants.TOAST_TYPE_ERROR,
                  //   alertTitle: Constants.SUBMIT_TIME_FORM_NOT_VALID_TITLE,
                  //   alertDescription: Constants.SUBMIT_TIME_FORM_NOT_VALID,
                  // });
                } else {
                  formik.handleSubmit();
                }
              }}
            />
          </Stack>
        </Flex>

        <Flex
          gap={"0.63rem"}
          flexDirection={!isMobile ? "row" : "column"}
          mt={!isMobile ? "3.06rem" : "1rem"}
          ml={!isMobile ? "3.06rem" : "1rem"}
          mr={!isMobile ? "3.06rem" : "1rem"}
          mb={!isMobile ? "3.06rem" : "1rem"}
        >
          <Box
            flex={1}
            flexShrink={"0"}
            bg={Colors.posPageDataBackground}
            borderRadius={"0.31rem"}
            boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
          >
            <VStack
              align={"stretch"}
              pl={{ base: "1.69rem", md: "1.69rem" }}
              pt={{ base: "2.31rem", md: "2.31rem" }}
              pr={{ base: "1.69rem", md: "1.69rem" }}
              pb={{ base: "4.38rem", md: "4.38rem" }}
              gap={"0rem"}
            >
              <Box>
                <Heading
                  as={"h3"}
                  color={Colors.posTextInfo}
                  fontSize={"1.13rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.02rem"}
                >
                  Basic Details
                </Heading>
              </Box>
              <Box>
                <PosLable requiredLabel={true} name={"Category Name"} />
                <PosInput
                  id="name"
                  placeholder={"Enter a Category Name"}
                  // handleInputChange={formik.handleChange}
                  handleInputChange={(e) => {
                    const value = e.target.value.replace(/,/g, "");
                    formik.setFieldValue("name", value);
                    if (e.target.value.includes(",")) {
                      addToast({
                        alertStatus: Constants.TOAST_TYPE_WARNING,
                        alertTitle: Constants.CATEGORY_MODULE,
                        alertDescription: Constants.COMMA_NOT_ALLOWED,
                      });
                    }
                  }}
                  inputValue={formik.values.name}
                  handleBlur={(e) => {
                    formik.setFieldValue("name", formik.values.name?.trim());
                    formik.handleBlur(e);
                  }}
                  posInput={true}
                  inputType={"text"}
                  inputError={formik.touched.name && formik.errors.name}
                />
                <Text color={Colors.errorColor}>
                  {formik.touched.name && formik.errors.name ? (
                    <span>{formik.errors.name}</span>
                  ) : null}
                </Text>
              </Box>
              {/* <Box mt={{ base: "2rem", md: "2rem" }}>
                <PosImageCropper
                  parentCallback={handleCallback}
                  imgUploadLabel="Upload Category Image"
                  canvasWidth="14.75rem"
                  canvasHeight="14.75rem"
                  cropperWidth="136"
                  cropperHeight="115"
                />
              </Box> */}
              <Box mt={{ base: "2rem", md: "2rem" }}>
                <PosImageCropper
                  parentCallback={handleCallback}
                  imgUploadLabel="Upload Category Image"
                  canvasWidth="14.75rem"
                  canvasHeight="14.75rem"
                  cropperWidth={236}
                  cropperHeight={193}
                  cropperMinWidth={96} // Minimum width of the crop area Cropper hide na thay ena mate
                  cropperMaxWidth={236} // Maximum width of the crop area when edit open at time full cropper display
                  cropperMinHeight={79} // Minimum height of the crop area Cropper hide na thay ena mate
                  cropperMaxHeight={193} // Maximum height of the crop area when edit open at time full cropper display
                  handleImage={handleImage}
                  image={catImage}
                />
              </Box>
              <Box>
                <PosLable label={true} name={"Choose Background Color"} />
                <MuiColorInput
                  id="background_color"
                  format="hex"
                  value={backgroundColor}
                  onChange={handleChangeBackgroundColor}
                />
              </Box>
              {/* <Box>
                                <PosLable label={true} name={"Choose Border Color"} />
                                <MuiColorInput id="border_color" format="hex" value={borderColor} onChange={handleChangeBorderColor} />
                            </Box> */}
              <Box>
                <PosLable label={true} name={"Choose Text Color"} />
                <MuiColorInput
                  id="text_color"
                  format="hex"
                  value={textColor}
                  onChange={handleChangeTextColor}
                />
              </Box>
              {/* <Box position='relative' h='150px' mt={{ base: "1.69rem", md: "1.69rem" }}>
                                {!base64OfUploadedImg ? (
                                    <AbsoluteCenter p='4' axis='both' h={"8rem"} w={"8rem"} fontWeight={"500"} border={"4px solid"} borderRadius={"0.41rem"} borderColor={borderColor} backgroundColor={backgroundColor} color={textColor} display={"flex"} textAlign={"center"} alignItems={"center"} justifyContent={"center"} >
                                        Category Name
                                    </AbsoluteCenter>
                                ) : (
                                    <Image src={base64OfUploadedImg} h={"8rem"} w={"8rem"} objectFit={"contain"} border={"4px solid"} borderRadius={"0.41rem"} borderColor={borderColor} display={"block"} ml={"auto"} mr={"auto"} />
                                )}
                            </Box> */}
              {/* <Box
                position="relative"
                h="14rem"
                // w="16.37rem"
                mt={{ base: "1.69rem", md: "1.69rem" }}
              >
                {!base64OfUploadedImg ? (
                  <AbsoluteCenter
                    p="20"
                    axis="both"
                    h={"3.38rem"}
                    w={"15.56rem"}
                    fontWeight={"500"}
                    // border={"4px solid"}
                    borderRadius={"0.41rem"}
                    // borderColor={borderColor}
                    backgroundColor={backgroundColor}
                    color={textColor}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Text pb={"10"} m={-29}>
                      {formattedName}
                    </Text>
                    <Box
                      position="absolute"
                      bottom="0"
                      w={"15.56rem"}
                      h={"3.38rem"}
                      textAlign={"center"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      alignSelf={"center"}
                      display={"flex"}
                      p="2"
                      fontSize="sm"
                      backgroundColor={"rgba(217, 217, 217, 0.85)"}
                      color={"#010048"}
                    >
                      {formattingName || "Category Name"}
                    </Box>
                  </AbsoluteCenter>
                ) : (
                  <Flex
                    direction="column"
                    align="center"
                    position="relative"
                    w="100%"
                  >
                    <Image
                      src={base64OfUploadedImg}
                      w="90%"
                      maxW="15.56rem"
                      h="auto"
                      objectFit={"contain"}
                      borderRadius={"0.41rem"}
                      borderColor={borderColor}
                      display={"block"}
                      ml={"auto"}
                      mr={"auto"}
                      p="2"
                    />
                    <Box
                      textAlign={"center"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                      p="4"
                      fontSize="sm"
                      backgroundColor={"rgba(217, 217, 217, 0.85)"}
                      color={"#010048"}
                      //   w="85%"
                      w="calc(90% - 1rem)"
                      maxW="14.56rem"
                      borderBottomRadius={"0.4rem"}
                      mt="-3.81rem"
                    >
                      {formattingName || "Category Name"}
                    </Box>
                  </Flex>
                )}
              </Box> */}
              <PosLable label={true} name={"POS Category Button Preview"} />
              <Box
                position="relative"
                // h="14rem"
                // w="16.37rem"
                mt={{ base: "1.69rem", md: "1.69rem" }}
              >
                {!base64OfUploadedImg ? (
                  <>
                    {/* <AbsoluteCenter
                          p="20"
                          axis="both"
                          h={"3.38rem"}
                          w={"13.5rem"}
                          // fontWeight={"500"}
                          // border={"4px solid"}
                          borderRadius={"0.42rem"}
                          // borderColor={borderColor}
                          backgroundColor={backgroundColor}
                          color={textColor}
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <Text pb={"10"} m={-29}  fontSize={"xl"}   fontWeight={"500"}> 
                            {formattedName}
                          </Text>
                          <Box
                            position="absolute"
                            bottom="0"
                            h={"3.38rem"}
                            w={"13.5rem"}
                            textAlign={"center"}
                            borderRadius={"0.42rem"}
                            borderTopStartRadius={"0rem"}
                            borderTopEndRadius={"0rem"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            alignSelf={"center"}
                            display={"flex"}
                            p="2"
                            // fontSize="sm"
                            backgroundColor={"rgba(217, 217, 217, 0.85)"}
                            color={"#010048"}
                          >
                             <Text fontSize={"sm"} fontWeight={formattingName.length > 27 ? "thin":"500"}>
                             {formattingName || "Product Name"}
                          </Text>
                           
                          </Box>
                        </AbsoluteCenter>  */}
                    <Box
                      style={{ position: "relative" }}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      {/* Avatar view */}
                      <Box
                        style={{
                          width: "225px",
                          height: "180px",
                          // backgroundColor: item.backgroundColor_hexcode ? item.backgroundColor_hexcode :  'rgba(217, 217, 217, 0.85)', // Semi-transparent background
                          backgroundColor: backgroundColor,
                          justifyContent: "center",
                          display: "flex",
                          alignSelf: "center",
                          alignContent: "center",
                          alignItems: "center",
                          borderRadius: "7px",
                        }}
                      >
                        <Text
                          style={{
                            marginBottom: "30px",
                            color: textColor,
                            fontSize: "xl",

                            textAlign: "center",
                          }}
                        >
                          {formattedName}
                        </Text>
                      </Box>

                      <Box
                        style={{
                          display: "flex",
                          position: "absolute",
                          bottom: 0,
                          backgroundColor: "#D9D9D9",
                          borderBottomLeftRadius: "7px",
                          borderBottomRightRadius: "7px",
                          alignItems: "center",
                          justifyContent: "center",
                          alignSelf: "center",
                          width: "225px",
                          height: "55px",
                        }}
                      >
                        <Text
                          fontSize={"sm"}
                          fontWeight={
                            formattingName.length > 27 ? "thin" : "500"
                          }
                        >
                          {formattingName || "Category Name"}
                        </Text>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Box
                    style={{ position: "relative" }}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Image
                      style={{
                        width: "225px",
                        height: "180px",
                        borderTopLeftRadius: "7px",
                        borderTopRightRadius: "7px",
                        justifyContent: "center",

                        borderRadius: "7px",
                      }}
                      src={base64OfUploadedImg}
                    />
                    <Box
                      style={{
                        display: "flex",
                        position: "absolute",
                        bottom: 0,
                        backgroundColor: "rgba(217, 217, 217, 0.85)",
                        borderBottomLeftRadius: "7px",
                        borderBottomRightRadius: "7px",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                        width: "225px",
                        height: "55px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "sm",
                          textAlign: "center",
                          color: "#010048",
                        }}
                        fontWeight={formattingName.length > 27 ? "thin" : "500"}
                      >
                        {formattingName || "Category Name"}
                      </Text>
                    </Box>
                  </Box>

                  // <Flex
                  //   direction="column"
                  //   align="center"
                  //   position="relative"
                  //   w="100%"
                  // >
                  //   <Image
                  //     src={base64OfUploadedImg}
                  //     w="13.5rem"
                  //     maxW="13.5rem"
                  //     // h="auto"
                  //     objectFit={"contain"}
                  //     borderRadius={"0.41rem"}
                  //     borderColor={borderColor}
                  //     display={"block"}
                  //     ml={"auto"}
                  //     mr={"auto"}
                  //     p="2"
                  //   />
                  //   <Box
                  //     textAlign={"center"}
                  //     justifyContent={"center"}
                  //     alignItems={"center"}
                  //     display={"flex"}
                  //     p="4"
                  //     fontSize="sm"
                  //     backgroundColor={"rgba(217, 217, 217, 0.85)"}
                  //     color={"black"}
                  //     w="calc(90% - 19rem)"
                  //     // w="85%"
                  //     // maxW="13.5rem"
                  //     borderBottomRadius={"0.4rem"}
                  //     mt="-3.50rem"
                  //   >
                  //     {formattingName || "Product Name"}
                  //   </Box>
                  // </Flex>
                )}
              </Box>
            </VStack>
          </Box>
          <Box
            flex={1}
            flexShrink={"0"}
            bg={Colors.posPageDataBackground}
            borderRadius={"0.31rem"}
            boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
          >
            <VStack
              align={"stretch"}
              pl={{ base: "1.69rem", md: "1.69rem" }}
              pt={{ base: "2.19rem", md: "2.19rem" }}
              pr={{ base: "1.69rem", md: "1.69rem" }}
              pb={{ base: "3.06rem", md: "3.06rem" }}
              gap={"0rem"}
            >
              <Box>
                <Heading
                  as={"h3"}
                  color={Colors.posTextInfo}
                  fontSize={"1.13rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.02rem"}
                >
                  Category Organization
                </Heading>
              </Box>
              <Box>
                <Flex flexDirection={"row"}>
                  <PosLable label={true} name={"Stay on Category Page"} />
                  <Tippy
                    content="This decides if you stay on the category page even after you select a product from it. This helps in faster checkout times if you have multiple sales from the same category."
                    interactive={true}
                    maxWidth={350}
                    placement="top"
                    animation="fade"
                    inertia={true}
                    moveTransition="transform 0.2s ease-out"
                    theme="tomato"
                  >
                    <Image
                      src={toolTip}
                      alt="Tooltip"
                      mt={"2rem"}
                      ml={"0.3rem"}
                    />
                  </Tippy>
                  <Spacer />
                  <PosSwitchButton
                    id="stay_on_cat_prod_page"
                    switchValue={formik.values.stay_on_cat_prod_page}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                </Flex>
                {/* <Stack
                  direction={"row"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                >
                  <PosSwitchButton
                    id="stay_on_cat_prod_page"
                    switchValue={formik.values.stay_on_cat_prod_page}
                    onChange={formik.handleChange}
                  />
                </Stack> */}
                {/* <Text
                  mt={{ base: "0.94rem", md: "0.94rem" }}
                  color={Colors.taxCreateExemptEbt}
                  fontSize={"0.94rem"}
                  fontStyle={"normal"}
                  fontWeight={"300"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                >
                  Turn on, to allow visibility and you stay on category page
                  during checkout to speed up multiple sales time
                </Text> */}
              </Box>
              <Box>
                <Flex flexDirection={"row"}>
                  <PosLable label={true} name={"Hide Category from POS"} />
                  <Tippy
                    content="With this option selected, it will remain invisible on the category page within the POS tablet."
                    interactive={true}
                    maxWidth={350}
                    placement="top"
                    animation="fade"
                    inertia={true}
                    moveTransition="transform 0.2s ease-out"
                    theme="tomato"
                  >
                    <Image
                      src={toolTip}
                      alt="Tooltip"
                      mt={"2rem"}
                      ml={"0.3rem"}
                    />
                  </Tippy>
                  <Spacer />
                  <PosSwitchButton
                    id="hide_cat_from_pos_attr"
                    switchValue={formik.values.hide_cat_from_pos_attr}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                </Flex>
                {/* <Stack
                  direction={"row"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                >
                  <PosSwitchButton
                    id="hide_cat_from_pos_attr"
                    switchValue={formik.values.hide_cat_from_pos_attr}
                    onChange={formik.handleChange}
                  />
                </Stack> */}
                {/* <Text
                  mt={{ base: "0.94rem", md: "0.94rem" }}
                  color={Colors.taxCreateExemptEbt}
                  fontSize={"0.94rem"}
                  fontStyle={"normal"}
                  fontWeight={"300"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                >
                  Turn on, to allow invisibility on the category page within the
                  POS tablet
                </Text> */}
              </Box>
              <Box>
                <Flex flexDirection={"row"}>
                  <PosLable label={true} name={"Show In Online Ordering"} />
                  <Tippy
                    content="With this option selected, it will remain visible during online ordering."
                    interactive={true}
                    maxWidth={350}
                    placement="top"
                    animation="fade"
                    inertia={true}
                    moveTransition="transform 0.2s ease-out"
                    theme="tomato"
                  >
                    <Image
                      src={toolTip}
                      alt="Tooltip"
                      mt={"2rem"}
                      ml={"0.3rem"}
                    />
                  </Tippy>
                  <Spacer />
                  <PosSwitchButton
                    id="show_in_online_ordering"
                    switchValue={formik.values.show_in_online_ordering}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                </Flex>
                {/* <Stack
                  direction={"row"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                >
                  <PosSwitchButton
                    id="show_in_online_ordering"
                    switchValue={formik.values.show_in_online_ordering}
                    onChange={formik.handleChange}
                  />
                </Stack> */}
                {/* <Text
                  mt={{ base: "0.94rem", md: "0.94rem" }}
                  color={Colors.taxCreateExemptEbt}
                  fontSize={"0.94rem"}
                  fontStyle={"normal"}
                  fontWeight={"300"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                >
                  With this option selected, it will remain visible during
                  online ordering.
                </Text> */}
              </Box>
              <Box>
                <Flex flexDirection={"row"}>
                  <PosLable label={true} name={"Category Sort"} />
                  <Tippy
                    content="This determines the location of the category on the Category page. From 1 being the first one on the row and so on."
                    interactive={true}
                    maxWidth={350}
                    placement="top"
                    animation="fade"
                    inertia={true}
                    moveTransition="transform 0.2s ease-out"
                    theme="tomato"
                  >
                    <Image
                      src={toolTip}
                      alt="Tooltip"
                      mt={"2rem"}
                      ml={"0.3rem"}
                    />
                  </Tippy>
                </Flex>
                <PosInput
                  id="category_sort"
                  placeholder={"Enter a Category Sort"}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.category_sort}
                  handleBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  posNumberInput={true}
                  inputType={"text"}
                  inputError={
                    formik.touched.category_sort && formik.errors.category_sort
                  }
                />
                <Text color={Colors.errorColor}>
                  {formik.touched.category_sort &&
                  formik.errors.category_sort ? (
                    <span>{formik.errors.category_sort}</span>
                  ) : null}
                </Text>
              </Box>
              <Box>
                <Flex flexDirection={"row"}>
                  <PosLable label={true} name={"Online Ordering Sort"} />
                  <Tippy
                    content="This determines the location of the category on the online ordering. From 1 being the first row and so on."
                    interactive={true}
                    maxWidth={350}
                    placement="top"
                    animation="fade"
                    inertia={true}
                    moveTransition="transform 0.2s ease-out"
                    theme="tomato"
                  >
                    <Image
                      src={toolTip}
                      alt="Tooltip"
                      mt={"2rem"}
                      ml={"0.3rem"}
                    />
                  </Tippy>
                </Flex>
                <PosInput
                  id="online_ordering_sort"
                  placeholder={"Enter a Online Ordering Sort"}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.online_ordering_sort}
                  handleBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  posNumberInput={true}
                  inputType={"text"}
                  inputError={
                    formik.touched.online_ordering_sort &&
                    formik.errors.online_ordering_sort
                  }
                />
                <Text color={Colors.errorColor}>
                  {formik.touched.online_ordering_sort &&
                  formik.errors.online_ordering_sort ? (
                    <span>{formik.errors.online_ordering_sort}</span>
                  ) : null}
                </Text>
              </Box>
            </VStack>
          </Box>
        </Flex>
      </Box>
    </Flex>
    // </MainLayout>
  );
};

export default WithRouter(CategoryCreate);
