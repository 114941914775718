import {
  Box,
  Flex,
  Spacer,
  Stack,
  Table,
  TableContainer,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
//   import Phone from "../../../../../assets/images/";
import {
  AppContext,
  PosBreadCrumb,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosInput,
  PosLable,
  PosTab,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import { twofix } from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { generateTillDetailsPdf } from "./TillsService";

const TillEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [posStations, setposStationDatas] = useState([]);
  const [posStation, setposStationData] = useState([]);
  const [selectedPosStation, setSelectedPosStation] = useState([]);
  const [users, setUserData] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [selectedPosstationData, setSelectedPosstationDataData] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [selectedUserCloseData, setSelectedUserCloseData] = useState([]);
  const [selectedUsersClose, setselectedUsersClose] = useState([]);
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [tillData, setTillData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [station, setStationData] = useState([]);
  const [curruntTab, setCurruntTab] = useState(0);
  const [isSecondTabVisible, setIsSecondTabVisible] = useState(false);
  // const tabNames = ["Till Details", "Difference"];
  const tabNames = isSecondTabVisible
    ? ["Till Details", "Difference"]
    : ["Till Details"];

  const handlePdfReport = () => {
    myContext.handleLoading(true);
    let data = posStations.id;
    try {
      generateTillDetailsPdf(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            var url = Constants.REACT_APP_API_URL + response.data.data;
            // var url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setposStationDatas(loaderResponse?.data?.data?.till);
          setTillData(loaderResponse?.data?.data?.calculate_difference);
          setStationData(loaderResponse?.data?.data?.payInOutDtls);
          setposStationData(loaderResponse?.data?.data?.stations);
          const posStation = loaderResponse.data.data.till.station.id;
          const matchingPoslocations = loaderResponse.data.data.stations.find(
            (posLocation) =>
              posLocation.id == loaderResponse.data.data.till.station.id
          );
          setSelectedPosstationDataData(matchingPoslocations);
          setUserData(loaderResponse?.data?.data?.users);
          const UserData = loaderResponse.data.data.users;
          const UserId = loaderResponse.data.data.till.opened_by.id;
          const matchingUsers = UserData.find((users) => users.id === UserId);
          setSelectedUserData(matchingUsers);
          setselectedUsersClose(loaderResponse?.data?.data?.users);
          const UserDataClose = loaderResponse.data.data.users;
          const UserCloseId = loaderResponse.data.data.till.closed_by
            ? loaderResponse.data.data.till.closed_by.id
            : "";
          const matchingCloseUsers = UserDataClose.find(
            (usersClose) => usersClose.id === UserCloseId
          );
          setSelectedUserCloseData(matchingCloseUsers);
          if (loaderResponse.data?.data?.till.is_closed == 1) {
            setIsSecondTabVisible(true);
          } else {
            setIsSecondTabVisible(false);
          }
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.TILL_REPORT_ROUTE, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.TILLS_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedPosStation(e.value);
      formik.setFieldValue("selectedPosStation", e.value.name);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedPosStation:
        undefined !== loaderResponse?.data?.data?.till?.station?.id &&
        null != loaderResponse?.data?.data?.till?.station?.id
          ? loaderResponse?.data?.data?.till?.station?.id
          : "",
      selectedUsers:
        undefined !== loaderResponse?.data?.data?.till?.name &&
        null != loaderResponse?.data?.data?.till?.station?.name
          ? loaderResponse?.data?.data?.till?.station?.name
          : "",
      starting_amount:
        undefined !== loaderResponse?.data?.data?.till?.starting_amount &&
        null != loaderResponse?.data?.data?.till?.starting_amount
          ? loaderResponse?.data?.data?.till?.starting_amount
          : "",
      closed_by_dropdown: "",
      closing_amount:
        undefined !== loaderResponse?.data?.data?.till?.closing_amount &&
        null != loaderResponse?.data?.data?.till?.closing_amount
          ? loaderResponse?.data?.data?.till?.closing_amount
          : "",
      is_closed:
        undefined !== loaderResponse?.data?.data?.till?.is_closed &&
        null != loaderResponse?.data?.data?.till?.is_closed
          ? loaderResponse?.data?.data?.till?.is_closed
          : "",
      id:
        undefined !== loaderResponse?.data?.data?.till?.id &&
        null != loaderResponse?.data?.data?.till?.id
          ? loaderResponse?.data?.data?.till?.id
          : "",
      difference:
        undefined !== loaderResponse?.data?.data?.till?.difference &&
        null != loaderResponse?.data?.data?.till?.difference
          ? loaderResponse?.data?.data?.till?.difference
          : "",
      selectedUsersClose: "",
    },
    validationSchema: Yup.object({
      selectedPosStation: Yup.string().required(
        Constants.TILLS_SELECT_POS_STATION
      ),
      selectedUsers: Yup.string().required(Constants.TILLS_SELECT_OPEN_BY),
      starting_amount: Yup.number()
        .typeError(Constants.PAYOUT_PLEASE_ENTER_A_VALID_AMOUNT)
        .required(Constants.TILLS_ENTER_STARING_AMOUNT)
        .max(999999.99, Constants.TILLS_STARTING_AMOUNT_MAX_VALUE)
        .min(0.01, Constants.TILLS_STARTING_AMOUNT_MIN_VALUE),
      selectedUsersClose: Yup.string().required(
        Constants.TILLS_SELCT_CLOSED_BY
      ),
      closing_amount: Yup.number()
        .typeError(Constants.PAYOUT_PLEASE_ENTER_A_VALID_AMOUNT)
        .required(Constants.TILLS_ENTER_CLOSING_AMOUNT)
        .min(-999999.99, Constants.TILLS_ENTER_CLOSED_AMOUNT_MIN_VALUE)
        .max(999999.99, Constants.TILLS_ENTER_CLOSED_AMOUNT_MAX_VALUE)
        .typeError(Constants.TILLS_ENTER_VALID_CLOSED_AMOUNT),
    }),
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async () => {
      // myContext.handleLoading(true);
      // setButtonDisable(true);
      // actionRun.current = true;
      // submit(payload, {
      //   method: Constants.POST,
      //   path: Constants.TILL_REPORT_ROUTE,
      // });
    },
  });

  const handleSubmitButton = () => {
    // Mark all fields as touched to trigger validation
    formik.setTouched(
      {
        selectedUsersClose: true,
        closing_amount: true,
      },
      true
    );

    if (
      formik.values.selectedUsersClose?.length <= 0 ||
      (formik.errors.selectedUsersClose ||
      formik.errors.closing_amount)
    ) {
      return;
    }
    myContext.handleLoading(true);
    const payload = {
      closed_by: selectedUsersClose.id,
      closed_by_dropdown: JSON.stringify({
        id: selectedUsersClose.id,
        name: selectedUsersClose.name,
      }),
      pos_station_id: loaderResponse?.data?.data?.till?.station?.id,
      closing_amount: formik.values.closing_amount,
      difference: formik.values.difference,
      id: formik.values.id,
      is_closed: formik.values.is_closed,
      opened_by: loaderResponse?.data?.data?.till?.id,
      starting_amount: formik.values.starting_amount,
      station: JSON.stringify({
        id: loaderResponse?.data?.data?.till?.station?.id,
        name: loaderResponse?.data?.data?.till?.station?.name,
      }),
    };
    actionRun.current = true;
    setButtonDisable(true);
    submit(payload, {
      method: Constants.POST,
      path: Constants.TILL_REPORT_ROUTE,
    });
  };

  const handleUserSelect = (e) => {
    if (undefined !== e.value) {
      setselectedUsers(e.value);
      formik.setFieldValue("selectedUsers", e.value.name);
    }
  };

  const handleUserCloseSelect = (e) => {
    if (undefined !== e.value) {
      setselectedUsersClose(e.value);
      formik.setFieldValue("selectedUsersClose", e.value.name);
    }
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      // setSearchQuery("");
      // setPaginationModel({
      //   page: 0,
      //   pageSize: 25,
      // });
      effectRun.current = true;
      setLoading(true);
    } else {
      // setSearchQuery("");
      // setPaginationModel({
      //   page: 0,
      //   pageSize: 25,
      // });
      // fetchOrderData();
      effectRun.current = true;
      setLoading(true);
    }
  };

  const handleKeyDown = (event) => {
    // if (
    //   event.key === "Enter" &&
    //   !(
        // formik.values.selectedUsersClose.length <= 0 ||
        // (formik.errors.selectedUsersClose ||
        // formik.errors.closing_amount ||
    //     buttonDisable
    //       ? true
    //       : false)
    //   )
    // ) {
    //   formik.handleSubmit();
    // }
    if (event.key === "Enter" && !buttonDisable) {
      handleSubmitButton();
    }
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        // gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              if (i === 0) {
                props.navigate(Constants.REPORT_PATH, {
                  state: stateData.state,
                });
              } else if (i === 1) {
                props.navigate(Constants.TILL_REPORT_ROUTE, {
                  state: stateData.state,
                });
              }
            }}
            breadCrumNames={["Report", "Tills", "Edit a Till"]}
            breadCrumTitle={"Edit a Till"}
          />
        </Box>
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              props.navigate(Constants.TILL_REPORT_ROUTE, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          {formik.values.is_closed >= 1 ? (
            <Box ml="1rem"></Box>
          ) : (
            <PosFormButton
              isDisabled={buttonDisable}
              // isDisabled={
              //   formik.values.selectedUsersClose.length <= 0 ||
              //   formik.errors.selectedUsersClose ||
              //   formik.errors.closing_amount ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={handleSubmitButton}
            />
          )}
        </Stack>
      </Flex>
      <Tabs onChange={(index) => handleTabChange(index)}>
        <PosTab tabNames={tabNames} />
        <TabPanels>
          <TabPanel>
            <Box>
              {formik.values.is_closed < 1 ? null : (
                <Box
                  // ml={{ base: 4, md: "3.44rem" }}
                  // mr={{ base: 4, md: "3.19rem" }}
                  borderRadius="0.63rem"
                  bg={Colors.errorColor}
                  boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                  p="5"
                  color={Colors.posPageDataBackground}
                >
                  <Text>Till is already closed, you cannot update it.</Text>
                </Box>
              )}
              <Box
                // mt={{ base: 2, md: "0" }}
                // ml={{ base: 4, md: "3.44rem" }}
                // mr={{ base: 4, md: "3.19rem" }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                p="8"
                onKeyDown={handleKeyDown}
              >
                <Text
                  fontSize="1.23rem"
                  w="10.5rem"
                  // pt={"1.56rem"}
                  color={Colors.posTextInfo}
                  style={{
                    fontStyle: "normal",
                    fontSize: "1.13rem",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                >
                  Edit a Till
                </Text>
                <Stack spacing={0}>
                  <PosLable
                    id="selectedPosStation"
                    name={"Pos Station"}
                    requiredLabel={true}
                    fontSize="0.94rem"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosDropDown
                    id="selectedPosStation"
                    options={posStation}
                    value={selectedPosstationData}
                    onChange={handleOptionSelect}
                    onBlur={(e) => {
                      formik.setFieldTouched("selectedPosStation");
                      formik.handleBlur(e);
                    }}
                    multiSelect={false}
                    disabled={true}
                    optionLabel="name"
                    placeholder="Select A Pos Station"
                    className="w-full md:w-20rem"
                    width={"100%"}
                  />
                  {/* <Text color={Colors.errorColor}>
                    {formik.touched.selectedPosStation && errors.length > 0 ? (
                      <span>{errors}</span>
                    ) : null}
                  </Text> */}
                </Stack>
                <Stack spacing={0}>
                  <PosLable
                    name={"Opened By"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosDropDown
                    id="selectedUsers"
                    options={users}
                    value={selectedUserData}
                    onChange={handleUserSelect}
                    onBlur={(e) => {
                      formik.setFieldTouched("selectedUsers");
                      formik.handleBlur(e);
                    }}
                    disabled={true}
                    multiSelect={false}
                    optionLabel="name"
                    placeholder="Select A User"
                    className="w-full md:w-20rem"
                    width={"100%"}
                  />
                  {/* <Text color={Colors.errorColor}>
                    {formik.touched.selectedUsers && errors.length > 0 ? (
                      <span>{errors}</span>
                    ) : null}
                  </Text> */}
                </Stack>
                <Stack spacing={0}>
                  <PosLable
                    name={"Starting Amount"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosInput
                    id="starting_amount"
                    mt={"1rem"}
                    placeholder={"Enter a Starting Amount"}
                    color={Colors.posCommon}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.starting_amount}
                    handleBlur={formik.handleBlur}
                    onKeyDown={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "ArrowUp" ||
                        e.key === "ArrowDown"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    posInput={true}
                    disabled={true}
                    inputType={"text"}
                    inputError={
                      formik.touched.starting_amount &&
                      formik.errors.starting_amount
                    }
                  />
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.starting_amount &&
                    formik.errors.starting_amount ? (
                      <span>{formik.errors.starting_amount}</span>
                    ) : null}
                  </Text>
                </Stack>
                <Stack spacing={0}>
                  <PosLable
                    name={"Closed By"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  {formik.values.is_closed < 1 ? (
                    <PosDropDown
                      id="selectedUsersClose"
                      options={users}
                      value={selectedUsersClose}
                      onChange={handleUserCloseSelect}
                      onBlur={(e) => {
                        formik.setFieldTouched("selectedUsersClose");
                        formik.handleBlur(e);
                      }}
                      multiSelect={false}
                      optionLabel="name"
                      placeholder="Select A User"
                      className="w-full md:w-20rem"
                      width={"100%"}
                    />
                  ) : (
                    <PosDropDown
                      id="selectedUsersClose"
                      options={users}
                      value={selectedUserCloseData}
                      onChange={handleUserCloseSelect}
                      onBlur={(e) => {
                        formik.setFieldTouched("selectedUsersClose");
                        formik.handleBlur(e);
                      }}
                      multiSelect={false}
                      optionLabel="name"
                      placeholder="Select A User"
                      className="w-full md:w-20rem"
                      width={"100%"}
                      disabled={true}
                    />
                  )}
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.selectedUsersClose &&
                    formik.errors.selectedUsersClose ? (
                      <span>{formik.errors.selectedUsersClose}</span>
                    ) : null}
                  </Text>
                </Stack>
                <Stack spacing={0}>
                  <PosLable
                    name={"Closing Amount"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  {formik.values.is_closed < 1 ? (
                    <PosInput
                      id="closing_amount"
                      mt={"1rem"}
                      placeholder={"Enter a Closing Amount"}
                      color={Colors.posCommon}
                      handleInputChange={formik.handleChange}
                      inputValue={formik.values.closing_amount}
                      handleBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldValue(
                          "closing_amount",
                          twofix(formik.values.closing_amount) || ""
                        );
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.key === "-" ||
                          e.key === "ArrowUp" ||
                          e.key === "ArrowDown"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      posNumberInput={true}
                      inputType={"number"}
                      inputError={
                        formik.touched.closing_amount &&
                        formik.errors.closing_amount
                      }
                    />
                  ) : (
                    <PosInput
                      id="closing_amount"
                      mt={"1rem"}
                      placeholder={"Enter a Closing Amount"}
                      color={Colors.posCommon}
                      handleInputChange={formik.handleChange}
                      inputValue={formik.values.closing_amount}
                      // handleBlur={formik.handleBlur}
                      handleBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldValue(
                          "closing_amount",
                          twofix(formik.values.closing_amount) || ""
                        );
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.key === "-" ||
                          e.key === "ArrowUp" ||
                          e.key === "ArrowDown"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      posNumberInput={true}
                      inputType={"number"}
                      inputError={
                        formik.touched.closing_amount &&
                        formik.errors.closing_amount
                      }
                      disabled={true}
                    />
                  )}
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.closing_amount &&
                    formik.errors.closing_amount ? (
                      <span>{formik.errors.closing_amount}</span>
                    ) : null}
                  </Text>
                </Stack>
                {formik.values.is_closed < 1 ? null : (
                  <Stack spacing={2}>
                    <PosLable
                      name={"Difference"}
                      label={true}
                      fontStyle="normal"
                      fontSize="0.94rem"
                      fontWeight={500}
                      lineHeight="normal"
                      letterSpacing="-0.02rem"
                    />
                    <PosInput
                      id="difference"
                      mt={"1rem"}
                      placeholder={"Enter a Difference Amount"}
                      color={Colors.posCommon}
                      handleInputChange={formik.handleChange}
                      inputValue={formik.values.difference}
                      handleBlur={formik.handleBlur}
                      disabled={true}
                      onKeyDown={(e) => {
                        if (
                          e.key === "-" ||
                          e.key === "ArrowUp" ||
                          e.key === "ArrowDown"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      posInput={true}
                      inputType={"text"}
                      inputError={
                        formik.touched.difference && formik.errors.difference
                      }
                    />
                    <Text color={Colors.posCancleButtonRed}>
                      {formik.touched.difference && formik.errors.difference ? (
                        <span>{formik.errors.difference}</span>
                      ) : null}
                    </Text>
                  </Stack>
                )}
              </Box>
            </Box>
          </TabPanel>
          {isSecondTabVisible && (
            <TabPanel>
              <Box
                // mt={{ base: 4, md: 4 }}
                // mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                // p="8"
                // pt={8}
                // pr={8}
              >
                {tillData && (
                  <Box
                    // mt={{ base: 2, lg: 2.5 }}
                    // ml={{ base: 4, md: "3.44rem" }}
                    // mr={{ base: 4, md: "3.19rem" }}
                    borderRadius="0.63rem"
                    bg={Colors.posPageDataBackground}
                    boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                    p="8"
                    onKeyDown={handleKeyDown}
                  >
                    <Flex
                      alignItems={"center"}
                      // mt={{ base: "1rem", md: "2rem" }}
                      pb="1rem"
                    >
                      <Text
                        color={Colors.posTextInfo}
                        style={{
                          fontSize: "1.12rem",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          letterSpacing: "-0.02rem",
                        }}
                      >
                        Calculate Difference:
                      </Text>
                      <Spacer />

                      {tillData ? (
                        <PosIconButton
                          name={Constants.EXPORT_PDF_BUTTON}
                          onClick={handlePdfReport}
                          pdfIcon={true}
                          width={"8.5rem"}
                        />
                      ) : (
                        <Text
                          mr={{ base: 0, md: "1rem" }}
                          color={Colors.posTextInfo}
                        >
                          {posStation?.difference || "N.A"}
                        </Text>
                      )}
                    </Flex>
                    {tillData && (
                      <Flex direction={{ base: "column", md: "row" }}>
                        <Flex
                          direction="column"
                          flex="1"
                          mr={{ md: "2rem" }}
                          mb={{ base: "1rem" }}
                        >
                          <Text
                            pb={{ base: "1rem", md: "1rem" }}
                            fontSize="1.12rem"
                            fontStyle="normal"
                            fontWeight={500}
                            lineHeight="normal"
                            letterSpacing="-0.02rem"
                          >
                            Adding Amount
                          </Text>
                          <TableContainer>
                            <Table variant="simple">
                              <Thead bg={Colors.modifierTable}>
                                <Tr>
                                  <Th>Amount Type</Th>
                                  <Th>Amount</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td>Starting Amount</Td>
                                  <Td>${tillData.starting_amount}</Td>
                                </Tr>
                                <Tr>
                                  <Td>Pay In Amount</Td>
                                  <Td>${tillData.pay_in_amount}</Td>
                                </Tr>
                                <Tr>
                                  <Td>Order Payment Amount</Td>
                                  <Td>${tillData.net_amount}</Td>
                                </Tr>
                                {/* <Tr>
                              <Td>Credit Account Payment</Td>
                              <Td>{tillData.cash_credit_account_payment}</Td>
                            </Tr> */}
                                <Tr>
                                  <Td>Credit Account Payments</Td>
                                  <Td>${tillData.pay_invoices_payment}</Td>
                                </Tr>
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th>Total</Th>
                                  <Td>${tillData.add_amount}</Td>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </Flex>
                        <Flex direction="column" flex="1">
                          <Text
                            pb={{ base: "1rem", md: "1rem" }}
                            fontSize="1.12rem"
                            fontStyle="normal"
                            fontWeight={500}
                            lineHeight="normal"
                            letterSpacing="-0.02rem"
                          >
                            Subtracting Amount
                          </Text>
                          <TableContainer>
                            <Table variant="simple">
                              <Thead bg={Colors.modifierTable}>
                                <Tr>
                                  <Th>Amount Type</Th>
                                  <Th>Amount</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td>Pay Out Amount</Td>
                                  <Td>${tillData.pay_out_amount}</Td>
                                </Tr>
                                <Tr>
                                  <Td>Cash Refunded Amount With Order</Td>
                                  <Td>${tillData.cash_refund_amount}</Td>
                                </Tr>
                                <Tr>
                                  <Td>Cash Refunded Amount Without Order</Td>
                                  <Td>${tillData.refund_without_order}</Td>
                                </Tr>
                                <Tr>
                                  <Td>Closing Amount</Td>
                                  <Td>${tillData.closing_amount}</Td>
                                </Tr>
                                <Tr>
                                  <Td>Bank Drop Amount</Td>
                                  <Td>${tillData.bank_drop_amount}</Td>
                                </Tr>
                                <Tr>
                                  <Td>Cashback</Td>
                                  <Td>${tillData.cashback}</Td>
                                </Tr>
                                <Tr>
                                  <Td>Bottle Deposit Refund</Td>
                                  <Td>${tillData.bottle_deposit_refund}</Td>
                                </Tr>
                              </Tbody>
                              <Tfoot>
                                <Tr>
                                  <Th>Total</Th>
                                  <Td>${tillData.minus_amount}</Td>
                                </Tr>
                              </Tfoot>
                            </Table>
                          </TableContainer>
                        </Flex>
                      </Flex>
                    )}
                    {tillData && (
                      <Flex>
                        <Text
                          fontSize="1.12rem"
                          fontStyle="normal"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                        >
                          Difference($):
                        </Text>
                        {tillData.add_amount} - {tillData.minus_amount} ={" "}
                        {posStations.difference}
                      </Flex>
                    )}
                    {station && station.length > 0 && (
                      <Flex direction="column" flex="1" mt="2rem">
                        <TableContainer>
                          <Table variant="simple">
                            <Thead bg={Colors.modifierTable}>
                              <Tr>
                                <Th>Paid In/Out Details</Th>
                                <Th>Amount</Th>
                                <Th>Reason</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {station.map((checkresult, i) => (
                                <Tr>
                                  <Td>
                                    {checkresult.type}
                                    {checkresult.date}
                                    <Text>{checkresult.desc}</Text>
                                  </Td>
                                  <Td>
                                    {checkresult.type === "Paid out at "
                                      ? `-$${checkresult.amount}`
                                      : checkresult.type === "Paid in at "
                                      ? `$${checkresult.amount}`
                                      : `$${checkresult.amount}`}
                                  </Td>
                                  <Td>{checkresult.reason}</Td>
                                </Tr>
                              ))}
                            </Tbody>
                            <Tfoot>
                              {station && station.length > 0 && (
                                <Tr>
                                  <Th>Total paid In/Out($)</Th>
                                  <Td>{tillData.pay_in_out_calculation}</Td>
                                </Tr>
                              )}
                            </Tfoot>
                          </Table>
                        </TableContainer>
                      </Flex>
                    )}
                  </Box>
                )}
              </Box>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default WithRouter(TillEdit);
