import {
  Box,
  Divider,
  Flex,
  Image,
  Img,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import voriLogo from "../../../assets/images/Black_LOGO_with_Name copy.png";
import octoposLogo from "../../../assets/images/cropped_logo.png";
import { AppContext, PosMainProgress } from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { commonDateFormate, twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
import { getSmsDataApi } from "../AuthService";

const PublicSms = (props) => {
  const [loading, setLoading] = useState(true);
  const myContext = useContext(AppContext);
  const [giftCardData, setGiftCardData] = useState([]);
  const [giftCardCode, setGiftCardCode] = useState("");
  const [locImgNotFound, setLocImgNotFound] = useState(1);
  const [businessNameNotFound, setBusinessNameNotFound] = useState(1);
  const [businessHref, setBusinessHref] = useState("");
  const [businessName, setBusinessName] = useState("");
  // const [businessImage, setBusinessImage] = useState("");
  const loaderResponse = useLoaderData();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let obj = useParams();
  obj.key1 = queryParams.key1;
  obj.key2 = queryParams.key2;
  obj.key3 = queryParams.key3;
  obj.key4 = queryParams.key4;
  obj.count = 0;

  const [htmlData, setHtmlData] = useState([]);
  const [allLines, setAllLines] = useState([]);
  const [rewardsData, setRewardsData] = useState([]);
  const [taxCollected, setTaxCollected] = useState([]);
  const [feeCollected, setFeeCollected] = useState([]);
  const [genCoupData, setGetCouponData] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [subdomain, setSubDomain] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  const [businessImageUrl, setBusinessImageUrl] = useState("");
  const [columnProductViewNames, setColumnProductViewNames] = useState([
    {
      columnNames: "#",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Product name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Quantity",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Total Price ($)",
      sortName: "",
      width: "",
    },
  ]);
  const [columnRewards, setColumnRewardNames] = useState([
    {
      columnNames: "Reward Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Required Points",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Current Points",
      sortName: "",
      width: "",
    },
  ]);

  useEffect(() => {
    fetchData();
    // if (effectRun.current === true) {
    //   if (
    //     undefined != loaderResponse &&
    //     null != loaderResponse &&
    //     {} != loaderResponse
    //   ) {
    //     if (
    //       undefined != loaderResponse?.error &&
    //       null != loaderResponse?.error &&
    //       loaderResponse?.error
    //     ) {
    //       let actionData = loaderResponse?.response?.data;
    //       error({ actionData });
    //       myContext.handleLoading(false);
    //     } else if (
    //       null !== loaderResponse.data[Constants.FLAGE] &&
    //       true === loaderResponse.data[Constants.FLAGE]
    //     ) {
    //       myContext.handleLoading(false);
    //     } else {
    //       let actionData = loaderResponse;
    //       error({ actionData });
    //     }
    //   }
    //   return () => {
    //     effectRun.current = false;
    //   };
    // }
    // if (
    //   undefined != actionResponse &&
    //   null != actionResponse &&
    //   {} != actionResponse
    // ) {
    //   if (
    //     undefined != actionResponse?.error &&
    //     null != actionResponse?.error &&
    //     actionResponse?.error
    //   ) {
    //     let actionData = actionResponse?.response?.data;
    //     error({ actionData });
    //     myContext.handleLoading(false);
    //   } else {
    //     myContext.handleLoading(false);
    //   }
    // }
  }, [actionResponse]);

  useEffect(() => {
    if (giftCardData) {
      setGiftCardCode(giftCardData?.giftCard?.code);

      if (giftCardData.businessLogo) {
        setLocImgNotFound(0);
      }

      if (giftCardData.businessName) {
        setBusinessNameNotFound(0);
      }
    }
  }, [giftCardData]);

  useEffect(() => {
    // myContext.handleLoading(true);
    const subdomain = window.location.host.split(".");
    if (subdomain[1] !== Constants.SANE_POS_DOMAIN) {
      setBusinessHref(Constants.VORI_URL);
      setBusinessName(Constants.VORI_FOOTER);
      setBusinessImage(octoposLogo);
    } else {
      setBusinessHref(Constants.SANE_POS_URL);
      setBusinessName(Constants.SANE_POS);
      setBusinessImage(octoposLogo);
    }
  }, []);

  const fetchData = async () => {
    myContext.handleLoading(true);
    let data = {
      key1: queryParams.get("key1"),
      key2: queryParams.get("key2"),
      key3: queryParams.get("key3"),
      key4: queryParams.get("key4"),
      count: 0,
    };
    setLoading(true);
    try {
      getSmsDataApi(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            if (response.data.data.fee_collected && 
              Array.isArray(response.data.data.fee_collected)) {
              const feeTotal = response.data.data.fee_collected.reduce((sum, tax) => {
                return sum + (Number(tax.fee_collected) || 0);
              }, 0);
              response.data.data.total_fee = feeTotal;
            }
            else {
              response.data.data.total_fee = 0;
            }
            if (response.data.data.taxes_collected && 
              Array.isArray(response.data.data.taxes_collected)) {
              const taxTotal = response.data.data.taxes_collected.reduce((sum, tax) => {
                return sum + (Number(tax.tax_collected) || 0);
              }, 0);
              response.data.data.total_tax = taxTotal;
            }
            else {
              response.data.data.total_tax = 0;
            }
            setHtmlData(response.data.data);
            setBusinessImageUrl(response.data.data.businessLogo);
            setAllLines(response.data.data.all_lines);
            setRewardsData(response.data.data.rewards_data);
            setTaxCollected(response.data.data.taxes_collected);
            setFeeCollected(response.data.data.fee_collected);
            setGetCouponData(response.data.data.generic_coupon_dtls);
            if (
              undefined != response.data.data.customer_data &&
              null != response.data.data.customer_data &&
              "" != response.data.data.customer_data
            ) {
              setCustomerData(response.data.data.customer_data);
            }
            // setGiftCardData(response.data.data);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setGiftCardData([]);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setGiftCardData([]);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setGiftCardData([]);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  return (
    <Flex
      minHeight={"100vh"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      background={Colors.loginMainBackground}
    >
      <Box
        width={{ base: "100%", md: "37.13rem" }}
        sx={{
          position: "-webkit-sticky",
          /* Safari */ position: "sticky",
          top: "0",
          zIndex: 999,
        }}
      >
        <Box
          width={{ base: "100%", md: "37.13rem" }}
          sx={{
            /* Safari */ position: "absolute",
            top: "0",
            zIndex: 99999999,
          }}
        >
          {myContext.loading ? <PosMainProgress /> : null}
        </Box>
        <Box
          width={{ base: "100%", md: "37.13rem" }}
          // mt={{ base: 10, md: 20, sm: 16, lg: 28 }}
          // mb={{ base: 10, md: 20, sm: 16, lg: 28 }}
          bg={Colors.loginAuthBackground}
          style={{
            // width: "35.13rem",
            height: "100%",
            borderRadius: "0.63rem",
            border: "0.13rem solid #FFF",
            boxShadow: "0rem 1.25rem 3.13rem 0rem #59595926",
            paddingBottom: "2.5rem",
            boxSizing: "border-box",
          }}
        >
          <Flex flexDirection={{ base: "column", md: "row" }}>
            {businessImageUrl != null && businessImageUrl != "" && (
              <Flex
                w={{ base: "80%", md: "50%" }}
                flexDirection={{ base: "center", md: "left" }}
                // display="flex"
                mt={"2rem"}
                ml={"2rem"}
                justifyContent={{ base: "center", md: "left" }}
                alignItems={{ base: "center", md: "left" }}
              >
                <Img
                  src={businessImageUrl}
                  alt="No logo"
                  style={{
                    cursor: "pointer",
                    maxHeight: "auto",
                    objectFit: "contain",
                    marginBottom: "0.63rem",
                    marginLeft: "1.25rem",
                    width: "45%",
                    // height: "auto",
                  }}
                />
              </Flex>
            )}
            <Flex
              flexDirection={{
                base: "center",
                md: businessImageUrl ? "flex-end" : "flex-start",
              }}
              // w={businessImageUrl?"50%":"auto"}
              display="flex"
              mt={{ base: "1rem", md: "2rem" }}
              justifyContent={{ base: "center", md: "right" }}
              alignItems={{ base: "center", md: "right" }}
              mr={"1.25rem"}
              ml={!businessImageUrl ? 4 : 0}
            >
              <Flex flexDirection={"column"}>
                <Text
                  fontWeight="500"
                  textAlign={{ base: "center", md: "left" }}
                  fontSize={"1.25rem"}
                >
                  ORDER NO-{" "}
                  {htmlData.local_id != null &&
                  htmlData.local_id != "" &&
                  htmlData.local_id != undefined
                    ? htmlData.local_id
                    : " N/A"}
                </Text>
                <Flex flexDirection={"row"}>
                  <Text fontWeight="500" textAlign="left" fontSize={"1.25rem"}>
                    Order Name-{" "}
                  </Text>
                  <Text fontWeight="300" textAlign="left" fontSize={"1.25rem"}>
                    {htmlData.order_user_name != null &&
                    htmlData.order_user_name != ""
                      ? htmlData.order_user_name
                      : " N/A"}
                  </Text>
                </Flex>
                <Flex flexDirection={"row"}>
                  <Text fontWeight="500" textAlign="left" fontSize={"1.25rem"}>
                  Date-{" "}
                  </Text>
                  <Text fontWeight="500" textAlign="left" fontSize={"1.25rem"}>
                  {htmlData.created_at
                    ? commonDateFormate(htmlData.created_at)
                    : " N.A."}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            flexDirection={"flex-end"}
            display="flex"
            justifyContent={"right"}
            alignItems={"right"}
            right={{
              base: "20.9rem",
              sm: "20.9rem",
              md: "20.9rem",
              lg: "20.9rem",
            }}
            mb={2}
            // mr={"1.25rem"}
          >
            <Box className="invoice-ribbon">
              <Box className="ribbon-inner">PAID</Box>
            </Box>
          </Flex>
          <Flex
            flexDirection={{
              base: "center",
              md: businessImageUrl ? "flex-end" : "flex-start",
            }}
            display="flex"
            justifyContent={{
              base: "center",
              md: businessImageUrl ? "right" : "left",
            }}
            alignItems={{
              base: "center",
              md: businessImageUrl ? "right" : "left",
            }}
            mr={"1.25rem"}
            ml={!businessImageUrl ? 4 : 0}
            mb={2}
          ></Flex>
          {Object.keys(customerData).length > 0 && (
            <>
              <Flex ml={"1.25rem"} flexDirection={"flex-start"} display="flex">
                <Text
                  fontWeight="500"
                  textAlign="center"
                  fontSize={"0.813rem"}
                  mr={"0.25rem"}
                >
                  Business Name :
                </Text>
                <Text fontWeight="300" textAlign="left" fontSize={"0.813rem"}>
                  {customerData.name}
                </Text>
              </Flex>
              <Flex ml={"1.25rem"} flexDirection={"flex-start"} display="flex">
                <Text
                  fontWeight="500"
                  textAlign="left"
                  fontSize={"0.813rem"}
                  mr={"0.25rem"}
                >
                  Email :
                </Text>
                <Text fontWeight="300" textAlign="left" fontSize={"0.813rem"}>
                  {customerData.email}
                </Text>
              </Flex>
              <Flex ml={"1.25rem"} flexDirection={"flex-start"} display="flex">
                <Text
                  fontWeight="500"
                  textAlign="left"
                  fontSize={"0.813rem"}
                  mr={"0.25rem"}
                >
                  Phone :
                </Text>
                <Text fontWeight="300" textAlign="left" fontSize={"0.813rem"}>
                  {customerData.phone}
                </Text>
              </Flex>
              <Flex ml={"1.25rem"} flexDirection={"flex-start"} display="flex">
                <Text
                  fontWeight="500"
                  textAlign="left"
                  fontSize={"0.813rem"}
                  mr={"0.25rem"}
                >
                  Address :
                </Text>
                <Text
                  width={"75%"}
                  fontWeight="300"
                  textAlign="left"
                  fontSize={"0.813rem"}
                >
                  {customerData.address1}
                  {null != customerData.address2 && "" != customerData.address2
                    ? ", " + customerData.address2
                    : ""}
                  , {customerData.city}
                  {null != customerData.state &&
                  "" != customerData.state &&
                  "N.A" != customerData.state
                    ? ", " + customerData.state
                    : ""}
                  {null != customerData.zipcode &&
                  "" != customerData.zipcode &&
                  "N.A" != customerData.zipcode
                    ? ", " + customerData.zipcode
                    : ""}
                </Text>
              </Flex>
              <Flex ml={"1.25rem"} flexDirection={"flex-start"} display="flex">
                <Text
                  fontWeight="500"
                  textAlign="left"
                  fontSize={"0.813rem"}
                  mr={"0.25rem"}
                >
                  Unpaid Account Balance :
                </Text>
                <Text fontWeight="300" textAlign="left" fontSize={"0.813rem"}>
                  ${customerData.unpaid_amount}
                </Text>
              </Flex>
            </>
          )}
          <Divider
            style={{ borderTop: "2px solid black" }}
            width="95%"
            margin="0 auto"
            alignSelf="center"
            mt={4}
            mb={4}
          />
          {htmlData.thumbUpUrl && htmlData.thumbDownUrl && htmlData.replacedCallBackUrlthumbUp && htmlData.replacedCallBackUrlthumbDown && (
            <>
              <Box
                bg='#005cbf'
                p={3}
                width="95%"
                margin="0 auto"
                borderRadius="md"
                textAlign="center"
              >
                <Text
                  color="white"
                  fontSize="1.5rem"
                  fontWeight="normal"
                  mb={4}
                >
                  How was your visit?
                </Text>
                <Flex justifyContent="center" gap={6} mb={4}>
                  <Box
                    as="a"
                    href={htmlData.replacedCallBackUrlthumbUp}
                    target="_blank"
                    p={3}
                    borderRadius="full"
                    border="2px solid white"
                    bg="#004080"
                    _hover={{ transform: "scale(1.05)" }}
                    transition="transform 0.2s"
                  >
                    <Image
                      src={htmlData.thumbUpUrl}
                      alt="Thumbs Up"
                      width="34px"
                      height="34px"
                    />
                  </Box>
                  <Box
                    as="a"
                    href={htmlData.replacedCallBackUrlthumbDown}
                    target="_blank"
                    p={3}
                    borderRadius="full"
                    border="2px solid white"
                    bg="#004080"
                    _hover={{ transform: "scale(1.05)" }}
                    transition="transform 0.2s"
                  >
                    <Image
                      src={htmlData.thumbDownUrl}
                      alt="Thumbs Down"
                      width="34px"
                      height="34px"
                    />
                  </Box>
                </Flex>
                <Text
                  color="white"
                  fontSize="0.875rem"
                  fontStyle="italic"
                  textAlign="center"
                  mb={2}
                >
                  This establishment tracks feedback and may reach out using the contact info you previously provided.
                </Text>
              </Box>
              <Divider
                style={{ borderTop: "2px solid black" }}
                width="95%"
                margin="0 auto"
                alignSelf="center"
                mt={4}
                mb={4}
              />
            </>
          )}
          <Flex display="flex" justifyContent="center" alignItems="center">
            <Text fontWeight="500" textAlign="center" fontSize={"1.25rem"}>
              {htmlData.printHeader1}
            </Text>
          </Flex>
          <Flex display="flex" justifyContent="center" alignItems="center">
            <Text fontWeight="300" textAlign="center" fontSize={"1.25rem"}>
              {htmlData.printHeader2}
            </Text>
          </Flex>
          {/* <Divider
          style={{ borderTop: "2px solid black" }}
          width="95%"
          margin="0 auto"
          alignSelf="center"
          mt={4}
          mb={4}
        /> */}
          {/* <Flex display="flex" justifyContent="center" alignItems="center">             */}
          {/* <Box w={"100%"}>
            {htmlData.all_lines > 0 && (
              <Box
                borderRadius="0.63rem"
                mt={3}
                bg={Colors.posPageDataBackground}
                boxShadow={
                  "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                }
              >
                <PosTable
                  columnNames={columnProductViewNames}
                  rawdata={modifiedViewData}
                  footerHide={true}
                  singleDeleteButton={true}
                  goSingleDelete={(id) => handleSingleDelete(id)}
                  handleCheckboxChange={handleCheckedItemsChange}
                />
              </Box>
            )}
          </Box> */}
          <Box mx={{ base: 2 }}>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th pl={2}>#</Th>
                    <Th>Product Name</Th>
                    <Th>Quantity</Th>
                    <Th>Price</Th>
                    <Th textAlign="right">Total Price</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {allLines.map((checkresult, i) => (
                    <Tr key={i}>
                      <Td pt={0} pr={0} pl={2} width={"3ch"} textAlign="left">
                        {" "}
                        {i + 1}
                      </Td>
                      <Td
                        pt={0}
                        pr={0}
                        whiteSpace="normal"
                        wordBreak="break-word"
                        width={{
                          base: "10ch",
                          sm: "8ch",
                          md: "6ch",
                          lg: "15ch",
                        }}
                      >
                        {checkresult.extra_item == 1
                          ? checkresult.extra_item_name
                          : checkresult.products[0].name}{null != htmlData.all_lines[i].unitOfMeasure && '' != htmlData.all_lines[i].unitOfMeasure ? ', '+htmlData.all_lines[i].unitOfMeasure:" "}
                        {checkresult.products[0].sold_by_weight == 1 && (
                          <Box>
                            <Text fontWeight="500" fontSize={"0.813rem"}>
                              @{checkresult.sale_price}/lb
                            </Text>{" "}
                            {htmlData.all_lines[i].tare_weight > 0 && (
                              <Text fontWeight="300" fontSize={"0.813rem"}>
                                {"(Tare: "}
                                {htmlData.all_lines[i].tare_weight}
                                {" lb)"}
                              </Text>
                            )}
                          </Box>
                        )}
                        {checkresult?.order_line_modifiers?.length > 0 && (
                          <Box>
                            <Text fontWeight="300" fontSize={"0.813rem"}>
                              {"("}
                              {checkresult?.order_line_modifiers
                                .map((mod) => mod.name)
                                .join(", ")}
                              {")"}
                            </Text>
                          </Box>
                        )}
                        {checkresult.total_discount > 0 &&
                          (null == checkresult.discount_reason ||
                            "" == checkresult.discount_reason) && (
                            <>
                              <Box>
                                <Text fontWeight="300" fontSize={"0.813rem"}>
                                  {"(Total: "}$
                                  {twofix(
                                    checkresult.quantity *
                                      checkresult.sale_price
                                  )}
                                </Text>
                              </Box>
                              <Box>
                                <Text fontWeight="300" fontSize={"0.813rem"}>
                                  {"Item Disc. -"}${checkresult.total_discount}
                                  {" Type:MANUAL)"}
                                </Text>
                              </Box>
                            </>
                          )}
                        {checkresult.total_discount > 0 &&
                          "REWARD APPLIED" == checkresult.discount_reason && (
                            <>
                              <Box>
                                <Text fontWeight="300" fontSize={"0.813rem"}>
                                  {"(Total: "}$
                                  {twofix(
                                    checkresult.quantity *
                                      checkresult.sale_price
                                  )}
                                </Text>
                              </Box>
                              <Box>
                                <Text fontWeight="300" fontSize={"0.813rem"}>
                                  {"Item Disc. -"}${checkresult.total_discount}
                                  {" Type:REWARD)"}
                                </Text>
                              </Box>
                            </>
                          )}
                        {checkresult.total_discount > 0 &&
                          "COUPON APPLIED" == checkresult.discount_reason && (
                            <>
                              <Box>
                                <Text fontWeight="300" fontSize={"0.813rem"}>
                                  {"(Total: "}$
                                  {twofix(
                                    (checkresult.quantity -
                                      checkresult.free_quantity) *
                                      checkresult.sale_price
                                  )}
                                </Text>
                              </Box>
                              <Box>
                                <Text fontWeight="300" fontSize={"0.813rem"}>
                                  {"Item Disc. -"}${checkresult.total_discount}
                                  {" Type:COUPON)"}
                                </Text>
                              </Box>
                            </>
                          )}
                        {(checkresult.free_quantity != 0 &&
                          checkresult.free_quantity != 0.0 &&
                          null != checkresult.free_quantity &&
                          "" != checkresult.free_quantity) ||
                          (null != checkresult.promotion_id &&
                            "" != checkresult.promotion_id && (
                              <Box>
                                <Text fontWeight="300" fontSize={"0.813rem"}>
                                  {"(Promo: "}
                                  {checkresult.promotion["name"]}
                                  {")"}
                                </Text>
                              </Box>
                            ))}
                      </Td>
                      <Td
                        pt={0}
                        pr={0}
                        whiteSpace="normal"
                        wordBreak="break-word"
                        width={{
                          base: "10ch",
                          sm: "8ch",
                          md: "6ch",
                          lg: "3ch",
                        }}
                      >
                        {checkresult.products[0].sold_by_weight == 0
                          ? Number(checkresult.quantity)
                          : checkresult.quantity}
                        {/* {Number(checkresult.quantity)} */}
                        {checkresult.products[0].sold_by_weight == 1
                          ? " lb"
                          : ""}
                        {htmlData.all_lines[i].tare_weight > 0 && (
                          <Text fontWeight="500" fontSize={"0.813rem"}>
                            {"Net"}
                          </Text>
                        )}
                      </Td>
                      <Td
                        pt={0}
                        pr={0}
                        whiteSpace="normal"
                        wordBreak="break-word"
                        width={{
                          base: "10ch",
                          sm: "8ch",
                          md: "6ch",
                          lg: "5ch",
                        }}
                      >
                        ${twofix(checkresult.sale_price)}
                      </Td>
                      <Td
                        pt={0}
                        whiteSpace="normal"
                        wordBreak="break-word"
                        width={{
                          base: "10ch",
                          sm: "8ch",
                          md: "6ch",
                          lg: "5ch",
                        }}
                        textAlign="right" // Align text to the right
                        fontSize={"1rem"}
                      >
                        $
                        {twofix(
                          (checkresult.quantity - checkresult.free_quantity) *
                            checkresult.sale_price -
                            allLines[i].total_discount
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          {/* </Flex> */}
          <Divider
            style={{ borderTop: "2px solid black" }}
            width="95%"
            margin="0 auto"
            alignSelf="center"
            mt={4}
            mb={4}
          />
          <Box mr={"1.25rem"}>
            <Flex
              flexDirection={"flex-end"}
              display="flex"
              justifyContent="right"
              alignItems="right"
            >
              <Text fontWeight="500" textAlign="center" fontSize={"0.813rem"}>
                Total : ${twofix(htmlData.total_order_line)}
              </Text>
            </Flex>
            <Flex
              flexDirection={"flex-end"}
              display="flex"
              justifyContent="right"
              alignItems="right"
            >
              <Text fontWeight="500" textAlign="center" fontSize={"0.813rem"}>
                Tax : ${twofix(htmlData.total_tax)}
              </Text>
            </Flex>
            {htmlData.total_fee > 0 && (<Flex
              flexDirection={"flex-end"}
              display="flex"
              justifyContent="right"
              alignItems="right"
            >
              <Text fontWeight="500" textAlign="center" fontSize={"0.813rem"}>
                Fee : ${twofix(htmlData.total_fee)}
              </Text>
            </Flex>)}
            {htmlData.total_tip > 0 && (
              <Flex
                flexDirection={"flex-end"}
                display="flex"
                justifyContent="right"
                alignItems="right"
              >
                <Text fontWeight="500" textAlign="center" fontSize={"0.813rem"}>
                  Tip : ${twofix(htmlData.total_tip)}
                </Text>
              </Flex>
            )}
            {undefined != htmlData.sub_total &&
              null != htmlData.sub_total &&
              "" != htmlData.sub_total && (
                <Flex
                  flexDirection={"flex-end"}
                  display="flex"
                  justifyContent="right"
                  alignItems="right"
                >
                  <Text
                    fontWeight="500"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    Sub Total : ${twofix(htmlData.sub_total)}
                  </Text>
                </Flex>
              )}
            {undefined != htmlData.discount_amount &&
              null != htmlData.discount_amount &&
              "" != htmlData.discount_amount &&
              0 < htmlData.discount_amount && (
                <Flex
                  flexDirection={"flex-end"}
                  display="flex"
                  justifyContent="right"
                  alignItems="right"
                >
                  <Text
                    fontWeight="500"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    Total Discount : ${twofix(htmlData.discount_amount)}
                  </Text>
                </Flex>
              )}
            {undefined != htmlData.discount_reason &&
              null != htmlData.discount_reason &&
              "" != htmlData.discount_reason && (
                <Flex
                  flexDirection={"flex-end"}
                  display="flex"
                  justifyContent="right"
                  alignItems="right"
                >
                  <Text
                    fontWeight="500"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    Discount Reason : {htmlData.discount_reason}
                  </Text>
                </Flex>
              )}
            {htmlData.dual_pricing_discount != undefined &&
              htmlData.dual_pricing_discount != null &&
              "" != htmlData.dual_pricing_discount &&
              0 < htmlData.dual_pricing_discount && (
                <Flex
                  flexDirection={"flex-end"}
                  display="flex"
                  justifyContent="right"
                  alignItems="right"
                >
                  <Text
                    fontWeight="500"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    Cash Discount : ${twofix(htmlData.dual_pricing_discount)}
                  </Text>
                </Flex>
              )}
            <Flex
              flexDirection={"flex-end"}
              display="flex"
              justifyContent="right"
              alignItems="right"
            >
              <Text fontWeight="500" textAlign="center" fontSize={"0.813rem"}>
                Final Total : ${twofix(htmlData.final_total)}
              </Text>
            </Flex>
            {htmlData.ebt_tax_saved != undefined &&
              htmlData.ebt_tax_saved != null &&
              "" != htmlData.ebt_tax_saved && (
                <Flex
                  flexDirection={"flex-end"}
                  display="flex"
                  justifyContent="right"
                  alignItems="right"
                >
                  <Text
                    fontWeight="500"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    Ebt Tax Saved : ${twofix(htmlData.ebt_tax_saved)}
                  </Text>
                </Flex>
              )}
            {htmlData.total_cashback != undefined &&
              htmlData.total_cashback != null &&
              "" != htmlData.total_cashback && (
                <Flex
                  flexDirection={"flex-end"}
                  display="flex"
                  justifyContent="right"
                  alignItems="right"
                >
                  <Text
                    fontWeight="500"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    Total Cashback : ${twofix(htmlData.total_cashback)}
                  </Text>
                </Flex>
              )}
          </Box>
          <Divider
            style={{ borderTop: "2px solid black" }}
            width="95%"
            margin="0 auto"
            alignSelf="center"
            mt={4}
            mb={4}
          />
          {/* Taxes Details Block */}
          {taxCollected?.filter(item => !item.is_fee)?.length > 0 && (
            <Box>
              <Flex display="flex" justifyContent="center" alignItems="center">
                <Text fontWeight="500" textAlign="center" fontSize={"1.25rem"}>
                  Taxes Details
                </Text>
              </Flex>
              {taxCollected
                .filter(item => !item.is_fee)
                .map((tax, i) => (
                  <Flex
                    key={i}
                    ml={"1.25rem"}
                    flexDirection={"flex-start"}
                    display="flex"
                    mx={{ base: 4 }}
                  >
                    <Text fontWeight="500" textAlign="center" fontSize={"0.813rem"}>
                      {tax.name}
                    </Text>
                    <Text fontWeight="300" textAlign="center" fontSize={"0.813rem"}>
                      {" "}
                      : ${twofix(tax.tax_collected)}
                    </Text>
                  </Flex>
                ))}
              <Divider
                style={{ borderTop: "2px solid black" }}
                width="95%"
                margin="0 auto"
                alignSelf="center"
                mt={4}
                mb={4}
              />
            </Box>
          )}
          {/* Fees Details Block */}
          {feeCollected?.filter(item => item.is_fee === 1)?.length > 0 && (
            <Box>
              <Flex display="flex" justifyContent="center" alignItems="center">
                <Text fontWeight="500" textAlign="center" fontSize={"1.25rem"}>
                  Fee Details
                </Text>
              </Flex>
              {feeCollected
                .filter(item => item.is_fee === 1)
                .map((fee, i) => (
                  <Flex
                    key={i}
                    ml={"1.25rem"}
                    flexDirection={"flex-start"}
                    display="flex"
                    mx={{ base: 4 }}
                  >
                    <Text fontWeight="500" textAlign="center" fontSize={"0.813rem"}>
                      {fee.name}
                    </Text>
                    <Text fontWeight="300" textAlign="center" fontSize={"0.813rem"}>
                      {" "}
                      : ${twofix(fee.fee_collected)}
                    </Text>
                  </Flex>
                ))}
              <Divider
                style={{ borderTop: "2px solid black" }}
                width="95%"
                margin="0 auto"
                alignSelf="center"
                mt={4}
                mb={4}
              />
            </Box>
          )}
          <Flex display="flex" justifyContent="center" alignItems="center">
            <Text fontWeight="500" textAlign="center" fontSize={"1.25rem"}>
              Payment Details
            </Text>
          </Flex>
          {undefined != htmlData.payment_details &&
            htmlData.payment_details.map((paymentdetail, i) => (
              <Box key={i} mt={2}>
                <Flex
                  ml={"1.25rem"}
                  flexDirection={"flex-start"}
                  display="flex"
                >
                  <Text
                    fontWeight="500"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    {paymentdetail.payment_type}
                  </Text>
                  <Text
                    fontWeight="300"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    {" "}
                    : ${twofix(paymentdetail.payment_amount)}
                    {paymentdetail.tips_amount != undefined &&
                    paymentdetail.tips_amount != null &&
                    "" != paymentdetail.tips_amount &&
                    paymentdetail.tips_amount > 0
                      ? ` [$${paymentdetail.amount} Amount + $${twofix(
                          paymentdetail.tips_amount
                        )} Tip]`
                      : ""}
                  </Text>
                </Flex>
                {undefined != paymentdetail.merchant_fee && null != paymentdetail.merchant_fee && '' != paymentdetail.merchant_fee &&
                  <Flex
                  ml={"1.25rem"}
                  flexDirection={"flex-start"}
                  display="flex"
                >
                  <Text
                    fontWeight="500"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    + Merchant Fee
                  </Text>
                  <Text
                    fontWeight="300"
                    textAlign="center"
                    fontSize={"0.813rem"}
                  >
                    {" "}
                    : ${twofix(paymentdetail.merchant_fee)}
                  </Text>
                </Flex>
                }
                {/* {paymentdetail.tips_amount != undefined &&
                  paymentdetail.tips_amount != null &&
                  "" != paymentdetail.tips_amount &&
                  paymentdetail.tips_amount > 0 && (
                    <Flex
                      ml={"1.25rem"}
                      flexDirection={"flex-start"}
                      display="flex"
                    >
                      <Text
                        fontWeight="500"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        Tip{" "}
                      </Text>
                      <Text
                        fontWeight="300"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        :${twofix(paymentdetail.tips_amount)}
                      </Text>
                    </Flex>
                  )} */}
                {paymentdetail.gift_card_code != undefined &&
                  paymentdetail.gift_card_code != null &&
                  paymentdetail.gift_card_code != "" && (
                    <Flex
                      ml={"1.25rem"}
                      flexDirection={"flex-start"}
                      display="flex"
                    >
                      <Text
                        fontWeight="500"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        GIFT CARD CODE :
                      </Text>
                      <Text
                        fontWeight="300"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        {paymentdetail.gift_card_code}
                      </Text>
                    </Flex>
                  )}
                {paymentdetail.ebt_balance != undefined &&
                  paymentdetail.ebt_balance != null &&
                  paymentdetail.ebt_balance != "" && (
                    <Flex
                      ml={"1.25rem"}
                      flexDirection={"flex-start"}
                      display="flex"
                    >
                      <Text
                        fontWeight="500"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        EBT SNAP BALANCE :
                      </Text>
                      <Text
                        fontWeight="300"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        ${twofix(paymentdetail.ebt_balance)}
                      </Text>
                    </Flex>
                  )}
                {paymentdetail.cashback != undefined &&
                  paymentdetail.cashback != null &&
                  "" != paymentdetail.cashback && (
                    <Flex
                      ml={"1.25rem"}
                      flexDirection={"flex-start"}
                      display="flex"
                    >
                      <Text
                        fontWeight="500"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        CASHBACK :
                      </Text>
                      <Text
                        fontWeight="300"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        ${twofix(paymentdetail.cashback)}
                      </Text>
                    </Flex>
                  )}
                {paymentdetail.aid != undefined &&
                  paymentdetail.aid != null &&
                  "" != htmlData.aid &&
                  paymentdetail.aid_display_name != undefined &&
                  paymentdetail.aid_display_name != null &&
                  "" != paymentdetail.aid_display_name && (
                    <>
                      <Flex
                        ml={"1.25rem"}
                        flexDirection={"flex-start"}
                        display="flex"
                      >
                        <Text
                          fontWeight="500"
                          textAlign="center"
                          fontSize={"0.813rem"}
                        >
                          AID
                        </Text>
                        <Text
                          fontWeight="300"
                          textAlign="center"
                          fontSize={"0.813rem"}
                        >
                          {" "}
                          : {paymentdetail.aid}
                        </Text>
                      </Flex>
                      <Flex
                        ml={"1.25rem"}
                        flexDirection={"flex-start"}
                        display="flex"
                      >
                        <Text
                          fontWeight="500"
                          textAlign="center"
                          fontSize={"0.813rem"}
                        >
                          AID Name
                        </Text>
                        <Text
                          fontWeight="300"
                          textAlign="center"
                          fontSize={"0.813rem"}
                        >
                          {" "}
                          : {paymentdetail.aid_display_name}
                        </Text>
                      </Flex>
                    </>
                  )}
                {paymentdetail.card_number != undefined &&
                  paymentdetail.card_number != null &&
                  paymentdetail.card_number != "" && (
                    <Flex
                      ml={"1.25rem"}
                      flexDirection={"flex-start"}
                      display="flex"
                    >
                      <Text
                        fontWeight="500"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        Card Number :
                      </Text>
                      <Text
                        fontWeight="300"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        {paymentdetail.card_number}
                      </Text>
                    </Flex>
                  )}
                {paymentdetail.reference_no != undefined &&
                  paymentdetail.reference_no != null &&
                  "" != paymentdetail.reference_no && (
                    <Flex
                      ml={"1.25rem"}
                      flexDirection={"flex-start"}
                      display="flex"
                    >
                      <Text
                        fontWeight="500"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        Reference No :
                      </Text>
                      <Text
                        fontWeight="300"
                        textAlign="center"
                        fontSize={"0.813rem"}
                      >
                        {paymentdetail.reference_no}
                      </Text>
                    </Flex>
                  )}
              </Box>
            ))}
            {undefined != htmlData.gift_card_audit_trail && htmlData.gift_card_audit_trail.length > 0 && 31 == queryParams.get("key3") ?
              <>
                <Divider
                  style={{ borderTop: "2px solid black" }}
                  width="95%"
                  margin="0 auto"
                  alignSelf="center"
                  mt={4}
                  mb={4}
                />
                <Flex display="flex" justifyContent="center" alignItems="center">
                  <Text fontWeight="500" textAlign="center" fontSize={"1.25rem"}>
                    Gift Card Balance
                  </Text>
                </Flex>
                {undefined != htmlData.gift_card_audit_trail &&
                  htmlData.gift_card_audit_trail?.map((giftcardDetails, i) => (
                    <Box key={i} mt={2}>
                      <Flex
                        ml={"1.25rem"}
                        flexDirection={"flex-start"}
                        display="flex"
                      >
                        <Text
                          fontWeight="500"
                          textAlign="center"
                          fontSize={"0.813rem"}
                        >
                          {giftcardDetails['gift_card']?.code}
                        </Text>
                        <Text
                          fontWeight="300"
                          textAlign="center"
                          fontSize={"0.813rem"}
                        >
                          {" "}
                          : ${twofix(giftcardDetails['gift_card']?.balance)}
                        </Text>
                      </Flex>
                    </Box>
                  ))
                }
              </>
            : ""}
          <Divider
            style={{ borderTop: "2px solid black" }}
            width="95%"
            margin="0 auto"
            alignSelf="center"
            mt={4}
            mb={4}
          />
          <Flex ml={"1.25rem"} flexDirection={"flex-start"} display="flex">
            <Text fontWeight="500" textAlign="center" fontSize={"0.813rem"}>
              Reward Customer :
            </Text>
            <Text fontWeight="300" textAlign="center" fontSize={"0.813rem"}>
              {htmlData.reward_card_phone_number}
            </Text>
          </Flex>
          {htmlData.rewards_data != undefined &&
            htmlData.rewards_data != null &&
            "" != htmlData.rewards_data && (
              <Box>
                <Flex
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    fontWeight="500"
                    textAlign="center"
                    fontSize={"1.25rem"}
                  >
                    Reward Details
                  </Text>
                </Flex>
                <Flex
                  ml={"1.25rem"}
                  flexDirection={"flex-start"}
                  display="flex"
                >
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Reward Name</Th>
                          <Th>Required Points</Th>
                          <Th>Current Points</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {htmlData.rewards_data.map((rewardData, i) => (
                          <Tr key={i}>
                            <Td
                              pt={0}
                              whiteSpace="normal"
                              wordBreak="break-word"
                              width={{
                                base: "10ch",
                                sm: "8ch",
                                md: "6ch",
                                lg: "5ch",
                              }}
                            >
                              {rewardData.name}
                            </Td>
                            <Td
                              pt={0}
                              whiteSpace="normal"
                              wordBreak="break-word"
                              width={{
                                base: "10ch",
                                sm: "8ch",
                                md: "6ch",
                                lg: "5ch",
                              }}
                            >
                              {rewardData.required_points}
                            </Td>
                            <Td
                              pt={0}
                              whiteSpace="normal"
                              wordBreak="break-word"
                              width={{
                                base: "10ch",
                                sm: "8ch",
                                md: "6ch",
                                lg: "5ch",
                              }}
                            >
                              {rewardData.current_points}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              </Box>
            )}
          <Divider
            style={{ borderTop: "2px solid black" }}
            width="95%"
            margin="0 auto"
            alignSelf="center"
            mt={4}
            mb={4}
          />
          <Flex display="flex" justifyContent="center" alignItems="center">
            <Text fontWeight="300" textAlign="center" fontSize={"0.813rem"}>
              {" "}
              {htmlData.printFooter1}
            </Text>
          </Flex>
          <Flex display="flex" justifyContent="center" alignItems="center">
            <Text fontWeight="300" textAlign="center" fontSize={"0.813rem"}>
              {" "}
              {htmlData.printFooter2}
            </Text>
          </Flex>
          {undefined != genCoupData.length && genCoupData.length > 0 && (
            <Divider
              style={{ borderTop: "2px solid black" }}
              width="95%"
              margin="0 auto"
              alignSelf="center"
              mt={4}
              mb={4}
            />
          )}
          {undefined != genCoupData && genCoupData.length > 0 && (
            <Box>
              <Flex display="flex" justifyContent="center" alignItems="center">
                <Text fontWeight="500" textAlign="center" fontSize={"1.25rem"}>
                  EXCLUSIVE OFFERS
                </Text>
              </Flex>
              {genCoupData.map((coupData, i) => (
                <Box key={i}>
                  <Flex
                    ml={"1.25rem"}
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <Text
                      fontWeight="500"
                      textAlign="center"
                      fontSize={"0.813rem"}
                    >
                      Coupon Name :
                    </Text>
                    <Text
                      fontWeight="300"
                      textAlign="center"
                      fontSize={"0.813rem"}
                    >
                      {coupData.name}
                    </Text>
                  </Flex>
                  <Flex
                    ml={"1.25rem"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      fontWeight="500"
                      textAlign="center"
                      fontSize={"0.813rem"}
                    >
                      Valid Until :
                    </Text>
                    <Text
                      fontWeight="300"
                      textAlign="center"
                      fontSize={"0.813rem"}
                    >
                      {coupData.expiration_date}
                    </Text>
                  </Flex>
                  <Flex
                    ml={"1.25rem"}
                    mt={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image
                      style={{
                        // width: "15.31rem",
                        // marginTop: "4.69rem",
                        height: "8.125rem",
                      }}
                      src={coupData.barcode}
                      alt="Octopos"
                    />
                  </Flex>
                </Box>
              ))}
            </Box>
          )}
          {htmlData.rewardData && (
            <>
              <Box
                bg='#5858E0'
                width="95%"
                margin="0 auto"
                borderRadius="md"
                mb={2}
                mt={4}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  px={4}
                  py={3}
                >
                  <Flex alignItems="center" gap={3}>
                    <Image
                      src={htmlData.starIcon}
                      alt="Star"
                      width="30px"
                      height="30px"
                    />
                    <Text
                      color="white"
                      fontSize="1rem"
                      whiteSpace="nowrap"
                    >
                      Never miss a reward
                    </Text>
                  </Flex>
                  <Box
                    as="a"
                    href={window.location.origin + Constants.LOCATION_CREATE_REWARD}
                    bg="white"
                    color="white"
                    backgroundColor="#3c3caa"
                    px={4}
                    py={2}
                    borderRadius="md"
                    textDecoration="none"
                    _hover={{
                      bg: "#3c3caa"
                    }}
                    fontSize="0.9rem"
                    fontWeight="300"
                  >
                    Sign up
                  </Box>
                </Flex>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default RouteWithLayout(PublicSms);
