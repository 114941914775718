import {
  Box,
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useMediaQuery,
} from "@chakra-ui/react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTable,
  PosTostMessage,
  PosListHeader,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
const EmployeeTipAmount = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const dataGridRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [selectedItemCreate_at, setSelectedItemCreate_at] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employes, setEmployees] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [orderId, setOrderId] = useState(0);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [sortType, setSortType] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState("id");
  const [applyClicked, setApplyClicked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isExtraLarge] = useMediaQuery("(min-width: 1710px)");
  const [isLarge] = useMediaQuery("(max-width: 1435px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "created_at",
      sort: "asc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    name: false,
  });
  const renderedEmployeeNames = [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columnProductViewNames = [
    {
      columnNames: "#",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Payment ID",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Tip($)",
      sortName: "",
      width: "",
    },
  ];
  const columnRefundTips = [
    {
      columnNames: "#",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Refund ID",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Payment Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Refunded Tip($)",
      sortName: "",
      width: "",
    },
  ];
  let counter = 1;
  const paymentInfo = [];

  tableData.forEach((employee) => {
    return Object.entries(employee.order).map(([orderId, orderDetails]) => {
      if (orderDetails?.id === selectedItemDetails) {
        const orderData = employee.order || "N.A";

        Object.values(employee.order).forEach((order) => {
          const refundAmount = order.refund || "N.A";
          if (order.payment && Array.isArray(order.payment)) {
            order.payment.forEach((payment) => {
              const orderDataId = payment.order_id || "N.A";
              const paymentId = payment.id || "N.A";
              const tipsAmount = payment.tips_amount || "N.A";
              paymentInfo.push({
                employeeId: employee.id,
                paymentId,
                tipsAmount,
                refundAmount,
                orderData,
                orderDataId,
                paymentArray: order.payment,
              });
            });
          }
        });
      }
    });
  });

  const modifiedViewData = paymentInfo
    .filter((info) => info.orderDataId === selectedItemDetails)
    .map((info, index) => ({
      id: index + 1,
      payment_id: info.paymentId || "N.A",
      tips_amount: info.tipsAmount || "N.A",
    }));

  // const modifiedViewDatas = paymentInfo
  //   .filter((info) => info.orderDataId === selectedItemDetails)
  //   .flatMap((info, index) => {
  //     return info.refundAmount.map((refund, refundIndex) => ({
  //       sr_no: counter++,
  //       refund_id: refund.id || "N.A",
  //       payment_type: refund.payment_type || "N.A",
  //       refunded_tip: refund.tip_amount || "N.A",
  //     }));
  //   });
  const modifiedViewDatas = [];
  const seenRefundIds = new Set();
  paymentInfo
    .filter((info) => info.orderDataId === selectedItemDetails)
    .forEach((info) => {
      info.refundAmount.forEach((refund) => {
        if (!seenRefundIds.has(refund.id)) {
          modifiedViewDatas.push({
            id: counter++,
            refund_id: refund.id || "N.A",
            payment_type: refund.payment_type || "N.A",
            refunded_tip: refund.tip_amount || "N.A",
          });
          seenRefundIds.add(refund.id);
        }
      });
    });

  const createTable = (employee) => {
    const employeeData = modifiedData.filter(
      (item) => item.name === employee.name
    );
    const totalItemAmount = employeeData.reduce(
      (total, row) => total + Number(row.totalTips),
      0
    );
    const totalItemTax = employeeData.reduce(
      (total, row) => total + Number(row.totalRefundedTips),
      0
    );
    const totalNetTips = employeeData.reduce(
      (total, row) => total + Number(row.totalNetTips),
      0
    );
    let counter = 1;
    const totalRow = {
      sr_no: "Total($)",
      id: "-",
      created_at: "-",
      totalTips: totalItemAmount.toFixed(2),
      totalRefundedTips: totalItemTax.toFixed(2),
      totalNetTips: totalNetTips.toFixed(2),
      order: "-",
    };
    return (
      <Box>
        {/* {" "}
        <Text
          style={{
            paddingLeft: "1.2rem",
            fontSize: "1.13rem",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
          }}
        >
          Employee Name : {employeeData?.[0]?.name}
        </Text> */}
        <Box
          mb={{ base: 4, md: 4 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          p="2"
          boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
        >
          <PosListHeader
            header={`Employee Name : ${employeeData?.[0]?.name}`}
          ></PosListHeader>
          <Box
            mt={8}
            h={employeeData.length - 1 > 7 ? "31rem" : "auto"}
            overflowY="auto"
          >
            <PosDataGridTable
              columns={columnNames}
              rows={employeeData.map((data) => ({
                ...data,
                sr_no: counter++,
              }))}
              totalcount={employeeData.length}
              hideFooter={true}
              columnVisibilityModel={olColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setOlColumnVisibilityModel(newModel)
              }
              autoHeight={employeeData.length - 1 > 7 ? false : true}
              paginationModel={paginationModel}
              paginationMode="server"
              // sortingMode="server"
              onPaginationModelChange={setPaginationModel}
              // sortModel={sortModel}
              // onSortModelChange={(newSortModel) => {
              //   sortRef.current = sortModel;
              //   setSortModel(newSortModel);
              // }}
              onSortModelChange={setSortModel}
              slots={{
                toolbar: GridToolbar,
              }}
              fileName={Constants.EMPLOYEE_TIPS_REPORT_CSV}
            />
          </Box>
          <Box key="total">
            <PosDataGridTable
              // columns={[
              //   ...columnNames.slice(0, -1),
              //   {
              //     field: "order",
              //     headerName: "View Details",
              //     sortable: false,
              //     flex: 1,
              //   },
              // ]}
              columns={columnNames.map((column) => ({
                ...column,
                renderCell: (params) => (
                  <div
                    style={params.row === totalRow ? { fontWeight: 500 } : null}
                  >
                    {params.value}
                  </div>
                ),
              }))}
              rows={[totalRow]}
              columnHeaderHeight={0}
              totalcount={1}
              hideFooter={true}
              columnVisibilityModel={olColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setOlColumnVisibilityModel(newModel)
              }
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              // slots={{
              //   columnHeaders: () => null,
              // }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const handleOrderDetailsClick = (params) => {
    myContext.handleLoading(true);
    setIsModalOpen(true);
    setSelectedItemDetails(params.row.id);
    setSelectedItemName(params.row.name);
    setSelectedItemCreate_at(params.row.created_at);
    setTimeout(() => {
      myContext.handleLoading(false);
    }, 100);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItemDetails(null);
  };
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1.5 };
  const columnNames = [
    { field: "sr_no", headerName: "#", sortable: false, width: 90 },
    { field: "id", headerName: "Order ID	", sortable: false, width: 115 },
    {
      field: "created_at",
      headerName: "Date",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "name",
      headerName: "name",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "totalTips",
      headerName: "Tip($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "totalRefundedTips",
      headerName: "Refunded Tip($)",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "totalNetTips",
      headerName: "Net Tip($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "order",
      headerName: "View Details",
      sortable: false,
      disableExport: true,
      resizable: false,
      filterable: false,
      width: 125,
      renderCell: (params) => (
        <IconButton
          style={{
            backgroundColor: "#F4F4F4",
            color: "#010048",
            transition: "background-color 0.3s, color 0.3s",
          }}
          onClick={() => {
            handleOrderDetailsClick(params);
          }}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      ),
    },
  ];

  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      selectedemplyoees: [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLoading(true);
          if(undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1){
              setSelectedLocation(loaderResponse?.data?.data?.location[0])
              formik.setFieldValue("selectedLoactions", loaderResponse?.data?.data?.location[0].name);
            }
          setLocations(loaderResponse?.data?.data?.location);
          setEmployees(loaderResponse?.data?.data?.employee);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    // getEmployeeTipAmount();
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Employee Tips Amount Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "EmployeeTipAmount.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.data.length);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);

  const handleExportReport = () => {
    if (
      formik.values.selectedLoactions.length > 0 &&
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (
        null != formik.values.fromDate &&
        "" != formik.values.fromDate){
        if(null != formik.values.toDate &&
        "" != formik.values.toDate
      ) {
    myContext.handleLoading(true);
      let data = {
        exportReport: true,
        type: "xls",
        location: JSON.stringify({
          label: selectedLocation.name,
          value: {
            id: selectedLocation.id,
          },
        }),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      if (selectedEmployee) {
        data.employee = JSON.stringify(
          selectedEmployee.map((emp) => ({
            id: emp.id,
            name: emp.name,
          }))
        );
      }
      if (formik.values.fromDate && formik.values.toDate) {
        data.dateFrom = reportDateFormate(formik.values.fromDate);
        data.dateTo = reportDateFormate(formik.values.toDate);
      }
      submit(data, {
        method: Constants.POST,
        path: Constants.EMPLOYEE_TIPS_AMOUNT_ROUTE,
      });
    // }
  } else {
    clearTableData(Constants.TO_DATE_IS_REQUIRED);
  }
} else {
  clearTableData(Constants.FORM_DATE_IS_REQUIRED);
} } else {
  clearTableData(Constants.LOCATION_IS_REQUIRED);
}
  };
  const clearTableData = (message) => {  // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getEmployeeTipAmount = () => {
    formik.setTouched({
      selectedLoactions: true,
      fromDate: true,
      toDate: true,
    });
    if(formik.values.selectedLoactions?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLoactions ||
      formik.errors.fromDate ||
      formik.errors.toDate){
      return;
    }
    if (formik.values.selectedLoactions.length > 0) {
      setButtonDisable(true);
      setLoading(true);
      setShowTable(false);
      setApplyClicked(true);
      let data = {
        getreport: true,
        location: JSON.stringify({
          label: selectedLocation.name,
          value: {
            id: selectedLocation.id,
          },
        }),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      if (selectedEmployee) {
        data.employee = JSON.stringify(
          selectedEmployee.map((emp) => ({
            id: emp.id,
            name: emp.name,
          }))
        );
      }
      if (formik.values.fromDate && formik.values.toDate) {
        data.dateFrom = reportDateFormate(formik.values.fromDate);
        data.dateTo = reportDateFormate(formik.values.toDate);
      }
      submit(data, {
        method: Constants.POST,
        path: Constants.EMPLOYEE_TIPS_AMOUNT_ROUTE,
      });
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
    }
  };

  const handleEmployeeOptionSelect = (e) => {
    setSelectedEmployee(e.value);
    formik.setFieldValue("selectedemplyoees", e.value.name);
  };

  const handleResetAllData = () => {
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setSelectedLocation([]);
    setSelectedEmployee([]);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    setApplyClicked(false);
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };

  const modifiedData = tableData
    .map((data) => {
      return Object.entries(data.order).map(([orderId, orderDetails]) => {
        return {
          name: data.name,
          id: orderDetails?.id,
          created_at: moment(orderDetails?.created_at)
            .format("ddd, MMM DD YYYY")
            .toString(),
          totalTips: orderDetails?.totalTips123.toFixed(2),
          totalRefundedTips: orderDetails?.totalRefundedTip.toFixed(2),
          totalNetTips: (
            orderDetails?.totalTips123 - orderDetails?.totalRefundedTip
          ).toFixed(2),
          order: data.order,
        };
      });
    })
    .flat();

  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card justify-content-center"
          flexDirection={isLarge ? "column" : "row"}
          spacing={4}
          width={isLarge ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDropDown
                options={locations}
                value={selectedLocation}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                resetFilterOnHide={true}
                optionLabel="name"
                placeholder="Select Location*"
                height={"2.5rem"}
                className="w-full md:w-20rem"
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDropDown
                options={employes}
                value={selectedEmployee}
                onChange={handleEmployeeOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedemplyoees");
                  formik.handleBlur(e);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                resetFilterOnHide={true}
                multiSelect={true}
                optionLabel="name"
                placeholder="Select Emplyoees"
                height={"2.5rem"}
                className="w-full md:w-20rem"
                maxSelectedLabels={2}
              />
            </Box>
          </Stack>
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date*"}
                readOnlyInput={true}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date*"}
                readOnlyInput={true}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      {isModalOpen && selectedItemDetails && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          w={"61.56rem"}
          isCentered
          size="2xl"
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Box p="2">
                <Heading
                  as="h2"
                  size="lg"
                  fontWeight="600"
                  fontSize="1.5rem"
                  lineHeight="1.875rem"
                  letterSpacing="-0.0225rem"
                  color={Colors.posTextInfo}
                >
                  <Text>
                    {selectedItemName}'s Tips report on {selectedItemCreate_at}
                  </Text>
                </Heading>
              </Box>
            </ModalHeader>
            <ModalCloseButton top="0.7rem" right="0.7rem" />
            <Text
              ml={{ base: "1.5rem", md: "1.5rem" }}
              sx={{
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
            >
              Tips Details
            </Text>
            <ModalBody>
              <Box
                style={{
                  overflowX: "auto",
                  maxHeight: "10.63rem",
                  maxWidth: "61.56rem",
                }}
              >
                <PosTable
                  columnNames={columnProductViewNames}
                  rawdata={modifiedViewData}
                  totalcount={totalcount}
                  noAction={true}
                  footerHide={true}
                  sortColumnName={sortColumn}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  order={sortType}
                />
              </Box>
              {modifiedViewDatas.some((data) => data.refund_id !== "N.A") && (
                <Box>
                  <Text
                    style={{
                      fontSize: "1.13rem",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      letterSpacing: "-0.02rem",
                    }}
                    mt={{ base: "1rem", md: "2rem" }}
                  >
                    Refunded Tips Details
                  </Text>
                  <Box
                    style={{
                      overflowX: "auto",
                      maxHeight: "10.63rem",
                      maxWidth: "61.56rem",
                    }}
                  >
                    <PosTable
                      columnNames={columnRefundTips}
                      rawdata={modifiedViewDatas}
                      totalcount={totalcount}
                      noAction={true}
                      footerHide={true}
                      sortColumnName={sortColumn}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      order={sortType}
                    />
                  </Box>
                </Box>
              )}
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Total Employee Tips by Day"]}
          breadCrumTitle={"Total Employee Tips by Day"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isLarge ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isLarge ? "flex-start" : "flex-end"}
            alignItems={"center"}
            pl={isLarge ? 4 : 0}
          >
            <PosFormButton
              isDisabled={applyClicked ? false : true}
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                // formik.values.selectedLoactions?.length === 0 ||
                // formik.values.fromDate === "" ||
                // formik.values.toDate === "" ||
                // formik.errors.selectedLoactions ||
                // formik.errors.fromDate ||
                // formik.errors.toDate ||
                buttonDisable
                  ? true
                  : false
              }
              onClick={getEmployeeTipAmount}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          <Flex direction="column" gap={4}>
            {showTable ? null : loading ? (
              <PosProgress />
            ) : totalcount > 0 ? (
              modifiedData.map((employee, index) => {
                if (!renderedEmployeeNames.includes(employee.name)) {
                  renderedEmployeeNames.push(employee.name);
                  // return createTable(employee);
                  return (
                    <React.Fragment
                      key={employee.id || `${employee.name}-${index}`}
                    >
                      {createTable(employee)}
                    </React.Fragment>
                  );
                }
                return null;
              })
            ) : (
              <PosNoDataFound
                title={Constants.EMPLOYEE_TIPS_AMOUNT_BY_DAY_NO_DATA}
              />
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(EmployeeTipAmount);
