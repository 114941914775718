import { Stack, Text, background } from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  PosInput,
  PosLable,
  PosTostMessage,
  PosFormButton,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "rgb(240, 57, 122)",
      color: "black",
      fontSize: "16px",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "black",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

function CheckoutForm(props) {
  const [result, setResult] = useState({});
  const [name, setName] = useState(""); // State for card holder's name
  const [email, setEmail] = useState(""); // State for email
  const [isSubmitting, setIsSubmitting] = useState(false); // State for submission status
  const stripe = useStripe();
  const elements = useElements();
  const { addToast } = PosTostMessage();
  const cardElementRef = useRef(null);
  const handleCardElementClick = () => {
    if (cardElementRef.current) {
      cardElementRef.current.focus();
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      showLabel: props.displayHostNameAndEmailField,
    },
    validationSchema: Yup.object({
      name: Yup.string().when("showLabel", {
        is: 1,
        then: (schema) =>
          schema
            .trim()
            .required(Constants.STRIPE_PAYMENT_CARD_HOLDER_NAME_NOT_FOUND)
            .max(191, Constants.ROLE_NAME_LENGTH_GREATE_THEN_191),
        otherwise: (schema) => schema.optional(),
      }),
      email: Yup.string().when("showLabel", {
        is: 1,
        then: (schema) =>
          schema
            .trim()
            .required(Constants.STRIPE_PAYMENT_EMAIL_ADDRESS_NOT_FOUND)
            .email(Constants.USER_EMAIL_NOT_VALID)
            .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true); // Start submission
      if (!stripe || !elements) {
        return;
      }
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);
      setResult(result);
      if (result?.error) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.BILLING_MODULE,
          alertDescription: result?.error?.message,
        });
        setIsSubmitting(false); // End submission
      } else {
        if (
          props.displayHostNameAndEmailField == 1 &&
          (null == values?.name ||
            "" == values?.name ||
            "undefined" == values?.name)
        ) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.BILLING_MODULE,
            alertDescription:
              Constants.STRIPE_PAYMENT_CARD_HOLDER_NAME_NOT_FOUND,
          });
          setIsSubmitting(false); // End submission
        } else if (
          props.displayHostNameAndEmailField == 1 &&
          (null == values?.email ||
            "" == values?.email ||
            "undefined" == values?.email)
        ) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.BILLING_MODULE,
            alertDescription: Constants.STRIPE_PAYMENT_EMAIL_ADDRESS_NOT_FOUND,
          });
          setIsSubmitting(false); // End submission
        } else {
          if (props.displayHostNameAndEmailField == 1) {
            result.token.update_customer = 1;
          } else {
            result.token.update_customer = 0;
          }
          result.token.card.email = values?.email;
          result.token.card.name = values?.name;
          props.handleChange(result?.token);
          setTimeout(() => setIsSubmitting(false), 2000); // End submission after 5 seconds
        }
      }
    },
  });
  return (
    <form>
      <div>
        <Text color={"#1E1B39"} fontSize={"2rem"} fontWeight={"medium"}>
          Add New Card Details
        </Text>
        {props.displayHostNameAndEmailField == 1 ? (
          <>
            <Stack spacing={0}>
              <PosLable requiredLabel={true} name={"Card Holder Name"} />
              <PosInput
                id="name"
                alt="Input Image"
                placeholder={""}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.name}
                handleBlur={formik.handleBlur}
                posInputGroup={true}
                inputType={"text"}
                onClick={handleCardElementClick}
                inputError={formik.touched.name && formik.errors.name}
              >
                <DriveFileRenameOutlineIcon
                  sx={{
                    height: "1.21rem",
                    width: "1.21rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
              </PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.name && formik.errors.name ? (
                  <span>{formik.errors.name}</span>
                ) : null}
              </Text>
            </Stack>
            <Stack spacing={0}>
              <PosLable requiredLabel={true} name={"Email Address"} />
              <PosInput
                id="email"
                alt="Input Image"
                placeholder={""}
                onClick={handleCardElementClick}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.email}
                handleBlur={formik.handleBlur}
                posInputGroup={true}
                inputType={"email"}
                inputError={formik.touched.email && formik.errors.email}
              >
                <DriveFileRenameOutlineIcon
                  sx={{
                    height: "1.21rem",
                    width: "1.21rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
              </PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.email && formik.errors.email ? (
                  <span>{formik.errors.email}</span>
                ) : null}
              </Text>
            </Stack>
          </>
        ) : null}
        <CardElement ref={cardElementRef} options={CARD_ELEMENT_OPTIONS} />
      </div>
      <Stack>
        <PosFormButton
          isDisabled={!stripe || isSubmitting} // Disable based on Stripe availability and submission status
          buttonsubmit={"Add"}
          SubmitButton={true}
          onClick={formik.handleSubmit}
        />
      </Stack>
    </form>
  );
}

export default function InjectedCheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (token) => {
    props.handleChange(token);
  };

  return (
    <CheckoutForm
      stripe={stripe}
      elements={elements}
      handleChange={handleChange}
      displayHostNameAndEmailField={props.showInput}
    />
  );
}
