import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
  useRadioGroup,
} from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosDialogAlert,
  PosDropDown,
  PosIconButton,
  PosInput,
  PosLable,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTab,
  PosTostMessage,
  SingleOrderView,
  ProductView,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  createWebhook,
  getAllLocation,
  getAllWebHooks,
  getAllWebLogs,
} from "./WebHookService";

const CardRadio = ({ children, isChecked, ...props }) => {
  return (
    <Box as="label">
      <input type="radio" {...props} style={{ display: "none" }} />
      <Box
        cursor="pointer"
        borderWidth="2px"
        borderRadius="0.625rem"
        // h={"7.25rem"}
        // w={"22.125rem"}
        boxShadow="md"
        // bg={isChecked ? "teal.600" : "white"}
        color={isChecked ? "white" : "black"}
        borderColor={
          isChecked ? Colors.primeTaxInfo : "rgba(109, 145, 254, 0.08)"
        }
        _hover={{
          bg: isChecked ? "messenger" : "gray.100",
        }}
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "#5881FE",
        }}
        px={5}
        py={3}
      >
        {children}
      </Box>
    </Box>
  );
};

const WebHookList = (props) => {
  const stateData = useLocation();
  const [showTable, setShowTable] = useState(true);
  const submit = useSubmit();
  const cancelRef = React.useRef();
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const effectRun = useRef(true);
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [curruntTab, setCurruntTab] = useState(0);
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const tabNames = ["WEBHOOKS", "WEBHOOK LOGS"];
  const [webHookData, setWebHooksData] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [productId, setProductId] = useState("");
  const [totalcount, setTotalCount] = useState(0);
  const [webLogsData, setWebLogsData] = useState([]);
  const [totalWebLogscount, setWebLogsTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deactiveItem, setDeactiveItem] = useState({});
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonRefreshHide, setButtonRefreshHide] = useState(true);
  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allTouchedField, setAllTouchedField] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.5 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const btnRef = React.useRef();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const typeOptions = [
    {
      title: "Product Update",
      type: "PRO",
    },
    {
      title: "Order Update",
      type: "ORD",
    },
  ];
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const sortRef = useRef(sortModel);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const sortRefDeactive = useRef(sortModelDeactive);
  const handleTabChange = (index) => {
    if (index == 0) {
      deactiveSearchHideRef.current = false;
    } else {
      searchInputHideRef.current = false;
    }
    setCurruntTab(index);
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setPaginationModelDeactive({
      page: 0,
      pageSize: 25,
    });
    setSortModelDeactive([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setSearchQuery("");
    effectRun.current = true;
    setLoading(true);
  };

  const handleSearchList = () => {
    paginationModel.page = 0;
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchAllWebLogs();
    }
  };
  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
  };
  const fetchData = async () => {
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    setShowTable(false);
    setLoading(true);
    try {
      getAllWebHooks(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setWebHooksData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setButtonDisable(false);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setWebHooksData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setWebHooksData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setWebHooksData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchAllWebLogs = async () => {
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getAllWebLogs(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setWebLogsData(response.data.data.data);
            setWebLogsTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setWebLogsData([]);
            setWebLogsTotalCount(0);
            setLoading(false);
            setButtonRefreshHide(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setWebLogsData([]);
          setWebLogsTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setWebLogsData([]);
      setWebLogsTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchAllWebLogs();
      }
      effectRun.current = false;
    }
  }, [
    paginationModel,
    sortModel,
    loading,
    searchQuery,
    paginationModelDeactive,
    sortModelDeactive,
  ]);
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
    { field: "name", headerName: "Name", sortable: true, ...actionColumnMD },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "auth_type",
      headerName: "Auth Type",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "auth_value",
      headerName: "Auth Value",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "url",
      headerName: "Url",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "delete",
      headerName: "Delete",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 110,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <DeleteIcon
            boxSize={5}
            color={Colors.posCancleButtonRed}
            onClick={() => handleModelOpen(rowData)}
          />
        );
      },
    },
  ];
  const handleModelOpen = (item) => {
    onOpen();
    setDeactiveItem(item);
  };
  const handleDeactive = (item) => {
    if(webHookData.length == 1 && paginationModel.page != 0){
      paginationModel.page = paginationModel.page - 1;
    }
    myContext.handleLoading(true);
    let data = {
      id: deactiveItem.id,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.INTEGRATION_WEBHOOK_PATH,
    });
    onClose();
  };
  const AUTHTYPES = [
    {
      label: "API Key",
      value: "api",
    },
    {
      label: "Token",
      value: "token",
    },
  ];
  const weblogsNames = [
    { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: false,
      ...actionColumnMD,
    },
    {
      field: "data_id",
      headerName: "Product/Order Id",
      sortable: false,
      ...actionColumnLG,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          if (rowData.type == "Order Update") {
            myContext.handleLoading(true);
            handleDisplayOrderData(rowData.drawerId);
          } else if (rowData.type == "Product Update") {
            myContext.handleLoading(true);
            handleDisplayProductData(rowData.drawerId);
          }
        };
        return (
          <Text
            onClick={handleView}
            cursor="pointer"
            color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration="underline"
            fontWeight={600}
          >
            {rowData.data_id}
          </Text>
        );
      },
    },
    {
      field: "url",
      headerName: "Url",
      sortable: false,
      ...actionColumnSM,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      ...actionColumnSM,
    },
  ];
  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderId(id);
      onDrawerOpen();
    },
    [orderId]
  );
  const handleDisplayProductData = useCallback(
    (item) => {
      myContext.handleLoading(true);
      setProductId(item);
      onViewOpen();
    },
    [productId]
  );
  const modifiedData = webHookData.map((data) => ({
    id: data.id,
    name: data.name,
    type:
      data.type === "PRO"
        ? "Product Update"
        : data.type === "ORD"
        ? "Order Update"
        : data.type,
    location: data.location.name,
    auth_type:
      data.auth_type === "api"
        ? "API Key        "
        : data.auth_type === "token"
        ? "Token"
        : "N.A.",
    auth_value:
      data.auth_type === "api"
        ? data.api_key + ": " + data.api_value
        : data.auth_type === "token"
        ? data.auth_value
        : "N.A.",
    url: data.url,
  }));
  const modifiedWebLogsData = webLogsData.map((data) => ({
    id: data.id,
    drawerId: data.data_id,
    type:
      data.type === "PRO"
        ? "Product Update"
        : data.type === "ORD"
        ? "Order Update"
        : data.type,
    location: data.webhook.location.name,
    data_id:
      data.type === "PRO"
        ? `Product-${data.data_id}`
        : data.type === "ORD"
        ? `Order-${data.data_id}`
        : data.data_id,
    url: data.url,
    status: data.status,
  }));

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data.message.trim() ===
          Constants.WEBHOOK_DELETED_SUCCESS
        ) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.WEBHOOK_MODULE,
            alertDescription: Constants.WEBHOOK_DELETED_SUCCESS,
          });
          myContext.handleLoading(false);
        }
      }
    }
  }, [actionResponse]);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      url: "",
      auth_type: "",
      api_key: "",
      api_value: "",
      token_value: "",
      location_id: "",
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.WEBHOOK_NAME_REQUIRED)
        .max(191, Constants.WEBHOOK_NAME_LENGTH_GREATE_THEN_191),
      type: Yup.string().required(Constants.WEBHOOK_TYPE_REQUIRED),
      location_id: Yup.string().required(Constants.WEBHOOK_LOCATION_REQUIRED),
      url: Yup.string()
        .required(Constants.WEBHOOK_URL_REQUIRED)
        .matches(
          Constants.WEBHOOK_URL_PATTERN,
          Constants.WEBHOOK_URL_NOT_VALID
        ),
      token_value: Yup.string().when("auth_type", {
        is: "token",
        then: (schema) => schema.required(Constants.TOKEN_VALUE_REQUIRED),
      }),
      api_value: Yup.string().when("auth_type", {
        is: "api",
        then: (schema) => schema.required(Constants.API_KEY_VALUE_REQUIRED),
      }),
      api_key: Yup.string().when("auth_type", {
        is: "api",
        then: (schema) => schema.required(Constants.API_NAME_VALUE_REQUIRED),
      }),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      setAllTouchedField(false);
      let payload = {
        ...values,
        location_id: selectedLocation,
      };
      createWebhook(payload)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            // setCurruntTab(0);
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: "Webhook Created",
              alertDescription: response.data.message,
            });
            setSortModel([
              {
                field: "id",
                sort: "desc",
              },
            ]);
            setPaginationModel({
              page: 0,
              pageSize: 25,
            });
           
            setVisible(false);
            formik.resetForm();
            formik.setFieldValue("type", "");
            formik.setFieldValue("location_id", "");
            formik.setFieldTouched("location_id", false);
            setSelectedLocation([]);
            myContext.handleLoading(false);
            setButtonDisable(false);
            effectRun.current = true;
            // fetchData();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.name.length <= 0 ||
        formik.errors.name ||
        formik.errors.api_key ||
        formik.errors.api_value ||
        formik.errors.token_value ||
        formik.errors.url ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  const handleTypeChange = (value) => {
    formik.setFieldValue("type", value);
  };
  const { getRootProps: getTypeRootProps, getRadioProps: getTypeRadioProps } =
    useRadioGroup({
      name: "type",
      defaultValue: "",
      onChange: handleTypeChange,
    });
  const typeGroup = getTypeRootProps();
  const onSelectLocation = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("location_id", e.value.name);
      // formik.setFieldValue("location_id", {
      //   id: e.value.id,
      //   name: e.value.name,
      // });
    }
  };
  const onSelectAuthType = (e) => {
    // if (undefined !== e.value) {
    formik.setFieldValue("auth_type", e.value);
    formik.setFieldValue("api_key", "");
    formik.setFieldTouched("api_key", "");
    formik.setFieldValue("api_value", "");
    formik.setFieldTouched("api_value", "");
    formik.setFieldValue("token_value", "");
    formik.setFieldTouched("token_value", "");
    setAllTouchedField(false);
    // }
  };
  const getLocation = async () => {
    try {
      getAllLocation()
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            setLocation(response.data.data.locations);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response?.data;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const [isMobileDialog] = useMediaQuery("(max-width: 992px)");
  const onHideDialog = () => {
    formik.resetForm();
    setSelectedLocation([]);
    setVisible(false);
    setAllTouchedField(false);
  };
  const onOrderDrawerClose = () => {
    onDrawerClose();
    setOrderId("");
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      {curruntTab === 1 && (
        <SingleOrderView
          visible={isDrawerOpen}
          onHide={onOrderDrawerClose}
          position="right"
          showCloseIcon={true}
          orderId={orderId}
          breadCrumNames={[]}
          handleClick={(i) => {
            // if (i == 0) {
            //   props.navigate(Constants.REPORT_PATH);
            // }
          }}
        />
      )}
      <ProductView
        isViewOpen={isViewOpen}
        placement="right"
        onViewClose={onViewClose}
        onViewOpen={onViewOpen}
        finalFocusRef={btnRef}
        id={productId}
        hideBreadCrumb = {true}
      />
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        title={"Delete Webhooks"}
        discription={
          "Are you sure you want to delete “" + deactiveItem.name + "”?"
        }
        onClick={handleDeactive}
        buttonNames={["Cancel", "Delete"]}
      />
      <Dialog
        header="Create A Webhook"
        visible={visible}
        style={{ width: isMobileDialog ? "80%" : "50%" }}
        // onHide={() => setVisible(false)}
        onHide={onHideDialog}
      >
        <Stack spacing={0}>
          <PosLable
            label={true}
            fontWeight={500}
            requiredLabel={true}
            color={Colors.posTextInfo}
            name={"Webhook name"}
          />
          <PosInput
            id="name"
            width={"100%"}
            placeholder={"Enter a Webhook Name"}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.name}
            handleBlur={(e) => {
              formik.setFieldValue("name", formik.values.name.trim());
              formik.handleBlur(e);
            }}
            onKeyDown={handleKeyDown}
            posInputGroup={true}
            background={Colors.posviewbgcolor}
            inputType={"text"}
            inputError={formik.touched.name && formik.errors.name}
          >
            <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
          </PosInput>
          <Text color={Colors.errorColor}>
            {formik.touched.name && formik.errors.name ? (
              <span>{formik.errors.name}</span>
            ) : null}
          </Text>
        </Stack>
        <PosLable
          label={true}
          fontWeight={500}
          requiredLabel={true}
          color={Colors.posTextInfo}
          name={"Webhook Type"}
        />
        <HStack spacing={"1rem"} {...typeGroup}>
          {typeOptions.map((option, index) => {
            const radio = getTypeRadioProps({
              value: option.type,
            });
            return (
              <CardRadio
                key={index}
                {...radio}
                isChecked={formik.values.type === option.type}
              >
                <Flex flexDirection="row" alignItems="center">
                  <Text
                    fontWeight="bold"
                    color={Colors.posTextInfo}
                    textAlign="center"
                  >
                    {option.title}
                  </Text>
                </Flex>
              </CardRadio>
            );
          })}
        </HStack>
        <Text color={Colors.errorColor} mt={1}>
          {formik.touched.type && formik.errors.type ? (
            <span>{formik.errors.type}</span>
          ) : null}
        </Text>
        <PosLable
          label={true}
          fontWeight={500}
          requiredLabel={true}
          color={Colors.posTextInfo}
          name={"Webhook Url"}
        />
        <PosInput
          id="url"
          placeholder={"Enter a Webhook Url"}
          handleInputChange={formik.handleChange}
          inputValue={formik.values.url}
          handleBlur={(e) => {
            formik.setFieldValue("url", formik.values.url.trim());
            formik.handleBlur(e);
          }}
          onKeyDown={handleKeyDown}
          posInput={true}
          background={Colors.posviewbgcolor}
          inputType={"text"}
          inputError={formik.touched.url && formik.errors.url}
        ></PosInput>
        <Text color={Colors.errorColor}>
          {formik.touched.url && formik.errors.url ? (
            <span>{formik.errors.url}</span>
          ) : null}
        </Text>
        <PosLable
          label={true}
          fontWeight={500}
          requiredLabel={true}
          color={Colors.posTextInfo}
          name={"Location"}
        />

        <PosDropDown
          id="location_id"
          options={location}
          value={selectedLocation}
          onChange={onSelectLocation}
          onBlur={(e) => {
            formik.setFieldTouched("location_id", true);
            formik.handleBlur(e);
          }}
          //   multiSelect={true}
          optionLabel="name"
          placeholder="--Select Location--"
          width={"100%"}
          //   height={"2.5rem"}
          className="w-full "
          maxSelectedLabels={2}
        />
        <Text color={Colors.errorColor}>
          {formik.touched.location_id && formik.errors.location_id ? (
            <span>{formik.errors.location_id}</span>
          ) : null}
        </Text>
        <PosLable
          label={true}
          fontWeight={500}
          // requiredLabel={true}
          color={Colors.posTextInfo}
          name={"Authentication Type"}
        />
        <Text color={Colors.primeTaxInfo}>
          (Only choose if you have the corresponding Webhook's authentication
          credentials.)
        </Text>
        {/* <PosDropDown
          id="auth_type"
          options={AUTHTYPES}
          value={formik.values.auth_type}
          onChange={onSelectAuthType}
          onBlur={(e) => {
            formik.setFieldTouched("auth_type", true);
            formik.handleBlur(e);
          }}
          //   multiSelect={true}
          optionLabel="label"
          placeholder="--Select Authentication Type--"
          width={"100%"}
          //   height={"2.5rem"}
          className="w-full "
          maxSelectedLabels={2}
        /> */}
        <Dropdown
          id="auth_type"
          filter
          value={formik.values.auth_type}
          onChange={onSelectAuthType}
          options={AUTHTYPES}
          optionLabel="label"
          onBlur={(e) => {
            formik.setFieldTouched("auth_type", true);
            formik.handleBlur(e);
          }}
          showClear
          // placeholder="--Select Authentication Type--"
          placeholder={"--Select Authentication Type--"}
          style={{
            width: "100%",
            backgroundColor: "rgba(88, 129, 254, 0.05)",
            borderRadius: "0.625rem",
            fontSize: "0.94rem",
            fontWeight: 300,
            color: "#010048",
            height: props.height,
            borderRadius: "10px",
            border: "1px solid rgba(183, 183, 183, 0.12)",
          }}
          panelStyle={{
            backgroundColor: "#F4F4F4",
            width: "20.625rem",
            borderRadius: "0.625rem",
            border: "1px solid rgba(183, 183, 183, 0.12)",
          }}
          resetFilterOnHide={true}
          filterInputAutoFocus={false}
          filterPlaceholder={"Search"}
          className="w-full"
        />
        {formik.values.auth_type === "api" && (
          <>
            <HStack>
              <PosLable
                label={true}
                fontWeight={500}
                requiredLabel={true}
                color={Colors.posTextInfo}
                name={"API Key Name"}
              />
              <Text mt={8} color={Colors.primeTaxInfo}>
                (ex: api_key.)
              </Text>
            </HStack>
            <PosInput
              id="api_key"
              placeholder={"Enter a API Key Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.api_key}
              handleBlur={(e) => {
                formik.setFieldValue("api_key", formik.values.api_key.trim());
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInput={true}
              background={Colors.posviewbgcolor}
              inputType={"text"}
              inputError={formik.touched.api_key && formik.errors.api_key}
            ></PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.api_key && formik.errors.api_key ? (
                <span>{formik.errors.api_key}</span>
              ) : null}
            </Text>
            <HStack>
              <PosLable
                label={true}
                fontWeight={500}
                requiredLabel={true}
                color={Colors.posTextInfo}
                name={"API Key Value"}
              />
              <Text mt={8} color={Colors.primeTaxInfo}>
                (ex: ABCDabcd1234XXXXXXXXXX)
              </Text>
            </HStack>
            <PosInput
              id="api_value"
              placeholder={"Enter a API Key Value"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.api_value}
              handleBlur={(e) => {
                formik.setFieldValue(
                  "api_value",
                  formik.values.api_value.trim()
                );
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInput={true}
              background={Colors.posviewbgcolor}
              inputType={"text"}
              inputError={formik.touched.api_value && formik.errors.api_value}
            ></PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.api_value && formik.errors.api_value ? (
                <span>{formik.errors.api_value}</span>
              ) : null}
            </Text>
          </>
        )}
        {formik.values.auth_type === "token" && (
          <>
            <HStack>
              <PosLable
                label={true}
                fontWeight={500}
                requiredLabel={true}
                color={Colors.posTextInfo}
                name={"Token"}
              />
              <Text mt={8} color={Colors.primeTaxInfo}>
                (ex: ABCDabcd1234XXXXXXXXXX)
              </Text>
            </HStack>
            <PosInput
              id="token_value"
              placeholder={"Enter a Token Value"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.token_value}
              handleBlur={(e) => {
                formik.setFieldValue(
                  "token_value",
                  formik.values.token_value.trim()
                );
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInput={true}
              background={Colors.posviewbgcolor}
              inputType={"text"}
              inputError={
                formik.touched.token_value && formik.errors.token_value
              }
            ></PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.token_value && formik.errors.token_value ? (
                <span>{formik.errors.token_value}</span>
              ) : null}
            </Text>
          </>
        )}
        <Button
          mt={4}
          // onClick={formik.handleSubmit}
          onClick={() => {
            if (!formik.isValid) {
              if (!allTouchedField) {
                formik.handleSubmit();
                setAllTouchedField(true);
              }
            } else {
              formik.handleSubmit();
            }
          }}
          isDisabled={buttonDisable}
          // isDisabled={
          //   formik.errors.location_id ||
          //   !formik.isValid ||
          //   !formik.dirty ||
          //   buttonDisable
          //     ? true
          //     : false
          // }
        >
          Submit
        </Button>
      </Dialog>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader header={"WebHooks"}  discription={"Manage and create product and order webhooks for the OctoPos system"}/>
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              formik.setFieldTouched("name",false);
              setVisible(true);
              getLocation();
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        // mt={8}
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  if (e.target.value == null || "" == e.target.value) {
                    fetchData();
                  }
                }}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosIconButton
                refreshIcon={true}
                name="Refresh"
                onClick={() => {
                  fetchAllWebLogs();
                }}
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <Box>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      effectRun.current = true;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.WH_EXPORT_FILENAME}
                  />
                </Box>
              ) : (
                <PosNoDataFound title={Constants.WEBHOOK_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalWebLogscount > 0 && curruntTab === 1 ? (
                <PosDataGridTable
                  columns={weblogsNames}
                  rows={modifiedWebLogsData}
                  totalcount={totalWebLogscount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  onPaginationModelChange={(newPaginationModelDeactive) => {
                    effectRun.current = true;
                    setPaginationModelDeactive(newPaginationModelDeactive);
                  }}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.WL_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound title={Constants.WL_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(WebHookList);
