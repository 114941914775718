import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { downloadPDF } from "./SalesSummaryForAllLocationService";
import { SimpleReportLayout } from "../../../../../layouts/index";
const SalesSummaryForAllLocations = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [applyClicked, setApplyClicked] = useState(false);
  const [totalSaleSummaryForAllLocation, setTotalSaleSummaryForAllLocation] =
    useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const pageRef = useRef(false);
  const actionColumn = isMobile ? { width: 150 } : { flex: 1.2 };
  const actionColumnXS = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1.5 };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "location",
      sort: "asc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const columnNames = [
    { field: "id", headerName: "Sr. No.", sortable: false, width: 90 },
    {
      field: "location",
      headerName: "Location",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "gross_sale",
      headerName: "Gross Sale($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "total_discount",
      headerName: "Discount($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "total_dual_pricing_discount",
      headerName: "Cash Discount($)",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "total_refund",
      headerName: "Refund($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "net_sales",
      headerName: "Net Sales($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "total_tax",
      headerName: "Tax($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "total_tip",
      headerName: "Tips($)",
      sortable: true,
      ...actionColumnXS,
    },
  ];
  const totalColumnName = [
    {
      field: "totalGrossSale",
      headerName: "Total Gross Sale($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "totalDiscount",
      headerName: "Total Discount($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
    {
      field: "totalDualPricingDiscount",
      headerName: "Total Cash Discount($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "totalRefund",
      headerName: "Total Refund($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
    {
      field: "totalNetSales",
      headerName: "Total Net Sales($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "totalTax",
      headerName: "Total Tax($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnXS,
    },
    {
      field: "totalTips",
      headerName: "Total Tips($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnXS,
    },
  ];
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      // selectedLoactions: [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    //   if (effectRun.current === true) {
    //     if (
    //       undefined != loaderResponse &&
    //       null != loaderResponse &&
    //       {} != loaderResponse
    //     ) {
    //       if (
    //         undefined != loaderResponse?.error &&
    //         null != loaderResponse?.error &&
    //         loaderResponse?.error
    //       ) {
    //         let actionData = loaderResponse;
    //         error({ actionData });
    //         myContext.handleLoading(false);
    //       } else if (
    //         null !== loaderResponse.data[Constants.FLAGE] &&
    //         true === loaderResponse.data[Constants.FLAGE]
    //       ) {
    //         setLocations(loaderResponse?.data?.data?.location);
    //         myContext.handleLoading(false);
    //       }
    //     }
    //     return () => {
    //       effectRun.current = false;
    //     };
    //   }
    if (pageRef.current) {
      getSalesSummaryForAllLocationData();
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Sales summary For All Locations Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.SALES_SUMMARY_ALL_LOC_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.salesSummaryForAllLocations);
          setTotalCount(1);

          let data = [
            {
              id: 123,
              totalGrossSale:
                actionResponse.data.data.totalGrossSale.toFixed(2),
              totalDiscount: actionResponse.data.data.totalDiscount.toFixed(2),
              totalDualPricingDiscount:
                actionResponse.data.data.totalDualPricingDiscount.toFixed(2),
              totalRefund: actionResponse.data.data.totalRefund.toFixed(2),
              totalNetSales: actionResponse.data.data.totalNetSales.toFixed(2),
              totalTax: actionResponse.data.data.totalTax.toFixed(2),
              totalTips: actionResponse.data.data.totalTips.toFixed(2),
            },
          ];
          setTotalSaleSummaryForAllLocation(data);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse, loaderResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    if (null != formik.values.fromDate && "" != formik.values.fromDate) {
      if (null != formik.values.toDate && "" != formik.values.toDate) {
        myContext.handleLoading(true);
        let data = {
          getreport: false,
          //   location: JSON.stringify(formik.values.selectedLoactions),
          dateFrom: reportDateFormate(formik.values.fromDate),
          dateTo: reportDateFormate(formik.values.toDate),
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          type: "xls",
        };
        submit(data, {
          method: Constants.POST,
          path: Constants.TOTAL_DAILY_SALE,
        });
      } else {
        clearTableData(Constants.TO_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.FORM_DATE_IS_REQUIRED);
    }
  };

  const handlePdfReport = () => {
    if (null != formik.values.fromDate && "" != formik.values.fromDate) {
      if (null != formik.values.toDate && "" != formik.values.toDate) {
        myContext.handleLoading(true);
        let data = {
          getreport: true,
          //   location: JSON.stringify(formik.values.selectedLoactions),
          dateFrom: reportDateFormate(formik.values.fromDate),
          dateTo: reportDateFormate(formik.values.toDate),
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          query: JSON.stringify({
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
          }),
        };
        try {
          downloadPDF(data)
            .then((response) => {
              if (
                undefined !== response.data.flag &&
                null !== response.data.flag &&
                response.data.flag == true
              ) {
                var url = Constants.REACT_APP_API_URL + response.data.data;
                // var url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
                window.open(url, "_blank");
                setLoading(false);
                myContext.handleLoading(false);
              } else {
                setLoading(false);
                let actionData = response;
                error({ actionData });
                myContext.handleLoading(false);
              }
            })
            .catch((err) => {
              setLoading(false);
              let actionData = err;
              error({ actionData });
              myContext.handleLoading(false);
            });
        } catch (error) {
          setLoading(false);
          myContext.handleLoading(false);
        }
      } else {
        clearTableData(Constants.TO_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.FORM_DATE_IS_REQUIRED);
    }
  };

  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getSalesSummaryForAllLocationData = () => {
    formik.setTouched({
      fromDate: true,
      toDate: true,
    });
    if(formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.fromDate ||
      formik.errors.toDate){
      return;
    }
    // if (formik.values.selectedLoactions.length > 0) {
    if (null != formik.values.fromDate && "" != formik.values.fromDate) {
      if (null != formik.values.toDate && "" != formik.values.toDate) {
        setButtonDisable(true);
        setLoading(true);
        setShowTable(false);
        setApplyClicked(true);
        let data = {
          getreport: true,
          //   location: JSON.stringify(formik.values.selectedLoactions),
          dateFrom: reportDateFormate(formik.values.fromDate),
          dateTo: reportDateFormate(formik.values.toDate),
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          query: JSON.stringify({
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
          }),
        };
        submit(data, {
          method: Constants.POST,
          path: Constants.TOTAL_DAILY_SALE,
        });
      } else {
        clearTableData(Constants.TO_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.FORM_DATE_IS_REQUIRED);
    }
    // } else {
    //   clearTableData(Constants.LOCATION_IS_REQUIRED);
    // }
  };

  // const handleOptionSelect = (e) => {
  //   formik.setFieldValue("selectedLoactions", e.value);
  // };
  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([
      {
        field: "location",
        sort: "asc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setApplyClicked(false);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        // let s moment(e.value).set({ hour: 23, minute: 59 }).toDate();
        // formik.setFieldValue("fromDate", start_date);
        // formik.setFieldValue("toDate", end_date);
        // setFromDateView(start_date);
        // setToDateView(end_date);
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
        {/* <Box className="card justify-content-center">
          <PosDropDown
            options={locations}
            resetFilterOnHide={true}
            value={formik.values.selectedLoactions}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
            maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box> */}
        <Stack
          className="card justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  const modifiedData = tableData.map((data) => ({
    //   order_date: moment(data.order_date.date).format("Do MMM, YYYY").toString(),
    id: data.sr_no,
    location: data.location,
    gross_sale: data.gross_sale.toFixed(2),
    total_discount: data.total_discount.toFixed(2),
    total_dual_pricing_discount: data.total_dual_pricing_discount.toFixed(2),
    total_refund: data.total_refund.toFixed(2),
    net_sales: data.net_sales.toFixed(2),
    total_tax: data.total_tax.toFixed(2),
    total_tip: data.total_tip.toFixed(2),
  }));
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Sales Summary For All Locations"]}
          breadCrumTitle={"Sales Summary For All Locations"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Export
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleExportReport}>Export Excel</MenuItem>
                <MenuItem onClick={handlePdfReport}>Export PDF</MenuItem>
              </MenuList>
            </Menu>
          )}
          {/* <PosIconButton
            name={Constants.EXPORT_BUTTON}
            onClick={handleExportReport}
            exportIcon={true}
            width={"7.5rem"}
          /> */}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
           direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
         
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isMeduim ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              buttonText={"Reset"}
              isDisabled={applyClicked ? false : true}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                //   formik.values.selectedLoactions?.length === 0 ||
                // formik.values.fromDate === "" ||
                // formik.values.toDate === "" ||
                // //   formik.errors.selectedLoactions ||
                // formik.errors.fromDate ||
                // formik.errors.toDate ||
                buttonDisable
              }
              onClick={getSalesSummaryForAllLocationData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : tableData.length > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                // noAction={true}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                hideFooter={true}
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_CSV}
              />
              <PosDataGridTable
                columns={totalColumnName}
                rows={totalSaleSummaryForAllLocation}
                totalcount={totalcount}
                rowId={"totalDailyslesData"}
                hideFooter={true}
              />
            </Flex>
          ) : (
            <PosNoDataFound
              title={"There are no Sales Summary For All Location."}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(SalesSummaryForAllLocations);
