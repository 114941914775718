import {
  Box,
  Flex,
  Image,
  Text
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  useActionData,
  useLocation,
  useParams,
} from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import { AppContext } from "../../../components/index";
import feedbackHeader from "../../../assets/images/feedback-header.png";
import feedbackSideImage from "../../../assets/images/feedback-sideimage.svg";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
import { getCustomerFeedbackDataApi } from "../AuthService";

const CustomerFeedback = (props) => {
  const [loading, setLoading] = useState(true);
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let obj = useParams();
  obj.key1 = queryParams.key1;
  obj.key2 = queryParams.key2;
  obj.key3 = queryParams.key3;
  obj.key4 = queryParams.key4;
  obj.key5 = queryParams.key5;

  useEffect(() => {
    fetchData();
  }, [actionResponse]);


  const fetchData = async () => {
    myContext.handleLoading(true);
    let data = {
      key1: queryParams.get("key1"),
      key2: queryParams.get("key2"),
      key3: queryParams.get("key3"),
      key4: queryParams.get("key4"),
      key5: queryParams.get("key5"),
      // count: 0,
    };
    setLoading(true);
    try {
      getCustomerFeedbackDataApi(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  return (
    <Flex
      display="flex"
      minHeight="100vh"
      justifyContent="center"
      alignItems="baseline"
      background={Colors.loginMainBackground}
      px={{ base: 4, md: 0 }}
    >
      <Box
        textAlign="center"
        p={{ base: 4, md: 8 }}
        borderRadius="md"
        boxShadow="lg"
        bg="white"
        maxWidth="600px"
        mt="100px"
      >
        <Image
          src={feedbackHeader} // Update with your icon path
          alt="Feedback Icon"
          mb={4}
          mx="auto"
          width="100%"
        />
        <Text fontSize="2xl" fontWeight="bold" mb={2}>
          Thank you!
        </Text>
        <Text fontSize="md" mb={4}>
        We value your feedback and appreciate your effort in helping us improve our services.
        </Text>
        <Box
          bg="#e6f7d8"
          // p={4}
          px={{ base: 2, md: 0 }}
          borderRadius="0.5rem"
          display="flex"
          alignItems="center"
          justifyContent="space-between" // Ensure space between text and image
        >
          <Box flex="1" mr={{ base: 0, md: 2 }}>
            <Text fontSize="md" fontWeight="bold">
            Tell us what you think!
            </Text>
            <Text fontSize="md">
              Feel free to share feedback by contacting us directly.
            </Text>
          </Box>
          <Image
            src={feedbackSideImage}
            alt="Feedback Icon"
            // width="100px" // Adjust width as needed
            // height="60px" // Adjust height as needed
            objectFit="contain" // Ensure the image maintains its aspect ratio
          />
        </Box>
        {/* <Divider my={6} />
        <Text fontSize="sm" color="gray.500">
          Powered by YourCompany
        </Text>
        <Text fontSize="xs" color="gray.400">
          © YourCompany, 2025. All Rights Reserved. | Terms of Service | Privacy Statement
        </Text> */}
      </Box>
    </Flex>
  );
};

export default RouteWithLayout(CustomerFeedback);
