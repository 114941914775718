import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  SingleOrderView,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  onlyDateFormate,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { SimpleReportLayout } from "../../../../../layouts/index";
import { WithRouter } from "../../../../../navigators/WithRouter";

const TotalSalesByDay = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1710px)");
  const [isLarge] = useMediaQuery("(max-width: 1435px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [totalDailySales, seltTotalDailySales] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [applyClicked, setApplyClicked] = useState(false);
  const [orderId, setOrderId] = useState("");
  const pageRef = useRef(false);
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "order_id",
      sort: "desc",
    },
  ]);
  const actionColumn = isMobile ? { width: 135 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 120 } : { flex: 0.9 };
  const actionColumnFlexDouble = isMobile ? { width: 170 } : { flex: 1.2 };
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    order_total_discount: false,
    dual_pricing_discount: false,
    total_unpaid_amount: false,
    total_tip_refunded: false,
    total_tip_amount: false,
  });
  const [totalColumnVisibilityModel, setTotalColumnVisibilityModel] =
    React.useState({
      order_discount: false,
      dual_pricing_discount: false,
      unpaid_amount: false,
      tip_amount: false,
      tip_refund: false,
    });

  const columnNames = [
    {
      field: "order_id",
      headerName: "Order Id",
      sortable: true,
      width: 120,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          myContext.handleLoading(true);
          handleDisplayOrderData(rowData.order_id);
        };
        return (
          <Text
            onClick={handleView}
            cursor="pointer"
            color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration="underline"
            fontWeight={600}
          >
            {rowData.order_id}
          </Text>
        );
      },
    },
    {
      field: "total_sale_price",
      headerName: "Sale Price($)",
      sortable: true,
      ...actionColumn,
      // width: 130,
    },
    {
      field: "total_tax_collected",
      headerName: "Tax($)",
      sortable: true,
      ...actionColumnXS,
      // width: 120,
    },
    {
      field: "order_total_discount",
      headerName: "Discounts($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "dual_pricing_discount",
      headerName: "Cash Discount($)",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "total_tip_amount",
      headerName: "Tip($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "total_payment_amount",
      headerName: "Payment($)",
      sortable: true,
      ...actionColumnXS,
      // width: 130,
    },
    {
      field: "payment_types",
      headerName: "Payment Types",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "total_unpaid_amount",
      headerName: "Unpaid Amount($)",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "total_amount_refunded",
      headerName: "Refunded Amount($)",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "total_tax_refunded",
      headerName: "Refunded Tax($)",
      sortable: true,
      ...actionColumn,
      // width: 140,
    },
    {
      field: "total_tip_refunded",
      headerName: "Refunded Tip($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "order_date",
      headerName: "Order Date",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "paid",
      headerName: "Status",
      sortable: true,
      ...actionColumn,
      // width: 130,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: true,
      ...actionColumn,
    },
  ];
  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderId(id);
      onDrawerOpen();
    },
    [orderId]
  );

  const totalColumnName = [
    {
      field: "sale_price",
      headerName: "Total Sale Price($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
    {
      field: "tax_collected",
      headerName: "Total Tax($)	",
      sortable: false,
      ...actionColumn,
      disableColumnMenu: true,
    },
    {
      field: "order_discount",
      headerName: "Total Discount($)",
      sortable: false,
      ...actionColumn,
      disableColumnMenu: true,
    },
    {
      field: "dual_pricing_discount",
      headerName: "Total Cash Discount($)",
      sortable: false,
      ...actionColumn,
      disableColumnMenu: true,
    },
    {
      field: "tip_amount",
      headerName: "Total Tip($)",
      sortable: false,
      ...actionColumn,
      disableColumnMenu: true,
    },
    {
      field: "payment_amount",
      headerName: "Total Payment($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
    {
      field: "unpaid_amount",
      headerName: "Total Unpaid Amount($)	",
      sortable: false,
      ...actionColumn,
      disableColumnMenu: true,
    },
    {
      field: "amount_refund",
      headerName: "Total Refunded Amount With Order($)",
      sortable: false,
      ...actionColumnFlexDouble,
      disableColumnMenu: true,
    },
    {
      field: "tax_refund",
      headerName: "Total Refunded Tax With Order($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "tip_refund",
      headerName: "Total Refunded Tip With Order($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "amount_refund_without_order",
      headerName: "Total Refunded Amount Without Order($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "tax_refund_without_order",
      headerName: "Total Refunded Tax Without Order($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnFlexDouble,
    },
  ];

  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const payment_type = [
    {
      label: "CARD",
      value: "CARD",
    },
    {
      label: "CASH",
      value: "CASH",
    },
    {
      label: "EBT",
      value: "EBT",
    },
    {
      label: "OTHER",
      value: "OTHER",
    },
  ];
  const formik = useFormik({
    initialValues: {
      selectedLoactions:  undefined !== loaderResponse?.data?.data?.location &&
      null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1
        ? loaderResponse?.data?.data?.location : [],
      selectedPaymentType: [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if(undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1){
              setOlColumnVisibilityModel(prev => ({...prev, location: false }));
            }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      getDailySalesData();
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Sales Report successfully generated."
        ) {
          // const generateURL =
          //   Constants.REACT_APP_API_URL + actionResponse.data.data.fileName;
          // const url = window.URL.createObjectURL(new Blob([generateURL]));
          // const link = document.createElement("a");
          // link.href = url;
          const url =
            Constants.REACT_APP_API_URL + actionResponse.data.data.fileName;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.DAILY_SALES_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.orders.data);
          setTotalCount(actionResponse.data.data.totalItems);
          let data = [
            {
              id: 123,
              sale_price: actionResponse.data.data.sale_price,
              tax_collected: actionResponse.data.data.tax_collected,
              order_discount: actionResponse.data.data.order_discount,
              dual_pricing_discount:
                actionResponse.data.data.dual_pricing_discount,
              tip_amount: actionResponse.data.data.tip_amount,
              payment_amount: actionResponse.data.data.payment_amount,
              unpaid_amount: actionResponse.data.data.unpaid_amount,
              amount_refund: actionResponse.data.data.amount_refund,
              tax_refund: actionResponse.data.data.tax_refund,
              tip_refund: actionResponse.data.data.tip_refund,
              amount_refund_without_order:
                actionResponse.data.data.amount_refund_without_order,
              tax_refund_without_order:
                actionResponse.data.data.tax_refund_without_order,
            },
          ];
          seltTotalDailySales(data);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    
    if (formik.values.selectedLoactions.length > 0) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
      myContext.handleLoading(true);
      let data = {
        getreport: false,
        location: JSON.stringify(formik.values.selectedLoactions),
        payment_type: JSON.stringify(formik.values.selectedPaymentType),
        dateFrom: reportDateFormate(formik.values.fromDate),
        dateTo: reportDateFormate(formik.values.toDate),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        type: "xls",
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.TOTAL_SALE_BY_DAY,
      });
    } else {
      clearTableData(Constants.TO_DATE_IS_REQUIRED);
    }
  } else {
    clearTableData(Constants.FORM_DATE_IS_REQUIRED);
  }
} else {
  clearTableData(Constants.LOCATION_IS_REQUIRED);
}
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getDailySalesData = () => {
    formik.setTouched({
      selectedLoactions: true,
      fromDate: true,
      toDate: true,
    });
    if(formik.values.selectedLoactions?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLoactions ||
      formik.errors.fromDate ||
      formik.errors.toDate){
      return;
    }
    // after remove date and loaction time click on pagination issue
    if (formik.values.selectedLoactions.length > 0) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          setApplyClicked(true);
          let data = {
            getreport: true,
            location: JSON.stringify(formik.values.selectedLoactions),
            payment_type: JSON.stringify(formik.values.selectedPaymentType),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            query: JSON.stringify({
              limit: paginationModel.pageSize,
              page: paginationModel.page + 1,
              order:
                sortModel.length > 0
                  ? sortModel[0].field
                  : sortRef.current[0].field,
              order_type:
                sortModel.length > 0
                  ? sortModel[0].sort
                  : sortRef.current[0].sort,
            }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.TOTAL_SALE_BY_DAY,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLoactions", e.value);
  };
  const handlePaymentOptionSelect = (e) => {
    formik.setFieldValue("selectedPaymentType", e.value);
  };
  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([
      {
        field: "order_id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (formik.values.selectedPaymentType.length > 0) {
      formik.setFieldValue("selectedPaymentType", []);
      formik.setFieldTouched("selectedPaymentType", false);
    }
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    setApplyClicked(false);
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        // let s moment(e.value).set({ hour: 23, minute: 59 }).toDate();
        // formik.setFieldValue("fromDate", start_date);
        // formik.setFieldValue("toDate", end_date);
        // setFromDateView(start_date);
        // setToDateView(end_date);
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
      <Stack
          className="card justify-content-center"
          flexDirection={isLarge ? "column" : "row"}
          spacing={4}
          width={isLarge ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
        <Box width={"100% !important"}>
          <PosDropDown
            options={locations}
            resetFilterOnHide={true}
            value={formik.values.selectedLoactions}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations*"
            width={isExtraLarge ? "17rem" : "100%"}
            height={"2.5rem"}
            className="w-full md:w-20rem"
            maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Box width={"100% !important"}>
            <PosDropDown
              options={payment_type}
              value={formik.values.selectedPaymentType}
              onChange={handlePaymentOptionSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedPaymentType");
                formik.handleBlur(e);
              }}
              resetFilterOnHide={true}
              multiSelect={true}
              optionLabel="label"
              placeholder="Select Payment Types"
              width={isExtraLarge ? "17rem" : "100%"}
              height={"2.5rem"}
              maxSelectedLabels={2}
              className="w-full md:w-20rem"
            />
          </Box>
          </Stack>
        <Stack
          width={"100% !important"}
          flexDirection={isExtraSmall ? "column" : "row"}
          justifyContent={"space-between"}
          spacing={4}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date*"}
              width={isExtraLarge ? "17rem" : "100%"}
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date*"}
              width={isExtraLarge ? "17rem" : "100%"}
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
        </Stack>
      </>
    );
  };
  const modifiedData = tableData.map((data) => ({
    order_date: onlyDateFormate(data.order_date),
    order_id: data.order_id,
    id: data.order_id,
    total_sale_price: data.total_sale_price,
    total_tax_collected: data.total_tax_collected,
    order_total_discount: data.order_total_discount,
    dual_pricing_discount: data.dual_pricing_discount,
    total_tip_amount: 
    null === data.total_tip_amount  ? "0.00" : data.total_tip_amount,
    total_payment_amount: data.total_payment_amount,
    total_unpaid_amount: data.total_unpaid_amount,
    payment_types: data.payment_types === null ? "N.A. " : data.payment_types,
    total_amount_refunded:
      null === data.total_amount_refunded ? "0.00" : data.total_amount_refunded,
    total_tax_refunded:
      null === data.total_tax_refunded ? "0.00" : data.total_tax_refunded,
    total_tip_refunded:
      null === data.total_tip_refunded ? "0.00" : data.total_tip_refunded,
    paid: data.paid,
    location: data.location,
  }));
  const onOrderDrawerClose = () => {
    onDrawerClose();
    setOrderId("");
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <SingleOrderView
        visible={isDrawerOpen}
        onHide={onOrderDrawerClose}
        position="right"
        showCloseIcon={true}
        orderId={orderId}
        breadCrumNames={[]}
        handleClick={(i) => {
          // if (i == 0) {
          //   props.navigate(Constants.REPORT_PATH);
          // }
        }}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Total Sales By Day"]}
          breadCrumTitle={"Total Sales By Day"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
              isDisabled={myContext.loading}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isLarge ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isLarge ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isLarge ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              isDisabled={applyClicked ? false : true}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                // formik.values.selectedLoactions?.length === 0 ||
                // formik.values.fromDate === "" ||
                // formik.values.toDate === "" ||
                // formik.errors.selectedLoactions ||
                // formik.errors.fromDate ||
                // formik.errors.toDate ||
                buttonDisable ||
                myContext.loading
              }
              onClick={getDailySalesData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                // noAction={true}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.TOTAL_SALE_BY_DAY_CSV}
              />
              <PosDataGridTable
                columns={totalColumnName}
                columnVisibilityModel={totalColumnVisibilityModel}
                rows={totalDailySales}
                totalcount={totalcount}
                rowId={"totalDailyslesData"}
                hideFooter={true}
              />
            </Flex>
          ) : (
            <PosNoDataFound title={"There are no Total Sales By Day."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(TotalSalesByDay);
