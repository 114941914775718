import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useParams,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
const TareContainerEdit = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state);
  const [allTouchedField, setAllTouchedField] = useState(false);
  const { id } = useParams();
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.PRODUCT_TARE_PATH, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.TARE_CONTAINER_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);
  const formik = useFormik({
    initialValues: {
      name:
        undefined != loaderResponse?.data?.data?.name &&
        null != loaderResponse?.data?.data?.name
          ? loaderResponse?.data?.data?.name
          : "",
      weight: loaderResponse?.data?.data?.weight,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.TARE_CONTAINER_NAME_REQUIRED)
        .max(191, Constants.TARE_CONTAINER_NAME_LENGTH_GREATE_THEN_191),
      weight: Yup.number()
        .required(Constants.TARE_CONTAINER_WEIGHT_REQUIRED)
        .typeError(Constants.TARE_CONTAINER_WEIGHT_NOT_VALID)
        .min(0.01, Constants.TARE_CONTAINER_WEIGHT_MIN_VALUE)
        .max(999999.99, Constants.TARE_CONTAINER_WEIGHT_MAX_VALUE),
    }),
    onSubmit: (values) => {
      setButtonDisable(true);
      myContext.handleLoading(true);
      let path = Constants.PRODUCT_TARE_PATH + id;
      actionRun.current = true;
      setAllTouchedField(false);
      submit(values, {
        method: Constants.PUT,
        path: path,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.name.length <= 0 ||
        formik.errors.name ||
        formik.errors.weight ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1">
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.PRODUCT_TARE_PATH, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Tare Containers", "Edit Tare Container"]}
            breadCrumTitle={"Edit Tare Container"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.PRODUCT_TARE_PATH, {
                  state: stateData.state,
                });
              }}
              isDisabled={buttonDisable}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              // isDisabled={
              //   formik.values.name.length <= 0 ||
              //   formik.errors.name ||
              //   formik.errors.weight ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              isDisabled={buttonDisable}
              buttonsubmit={"Submit"}
              SubmitButton={true}
              // onClick={formik.handleSubmit}
              onClick={() => {
                // submit time all validation fired and error massage display.
                if (!formik.isValid) {
                  if (!allTouchedField) {
                    formik.handleSubmit();
                    setAllTouchedField(true);
                  }
                } else {
                  formik.handleSubmit();
                }
              }}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Text
            fontSize="1.5rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Tare Container
          </Text>
          <Stack spacing={0}>
            <PosLable
              fontWeight={500}
              requiredLabel={true}
              name={"Tare Container name"}
            />
            <PosInput
              id="name"
              placeholder={"Enter Tare Container name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.name}
              handleBlur={(e) => {
                formik.setFieldValue("name", formik.values.name.trim());
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInputGroup={true}
              inputType={"text"}
              inputError={formik.touched.name && formik.errors.name}
            >
              <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <Stack spacing={0}>
            <PosLable
              fontWeight={500}
              requiredLabel={true}
              name={"Weight(lb)"}
            />
            <PosInput
              id="weight"
              placeholder={"Enter a weight(lb)"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.weight|| ""}
              handleBlur={(e) => {
                formik.handleBlur(e);
                formik.setFieldValue("weight", twofix(formik.values.weight));
                
              }}
              onKeyDown={handleKeyDown}
              posNumberInput={true}
              inputType={"text"}
              inputError={formik.touched.weight && formik.errors.weight}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.weight && formik.errors.weight ? (
                <span>{formik.errors.weight}</span>
              ) : null}
            </Text>
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(TareContainerEdit);
