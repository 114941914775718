import { Box, Flex, Image, Spacer, Stack, Text } from "@chakra-ui/react";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosLable,
  PosRadio,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const TaxCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [allTouchedField, setAllTouchedField] = useState(false);
  const effectRun = useRef(true);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.TAX_LIST, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.TAX_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
      /* else {
          let actionData = actionResponse;
          error({ actionData });
        } */
    }
  }, [actionResponse]);
  const formik = useFormik({
    initialValues: {
      name: "",
      rate: "",
      type: "amou",
      exempt_ebt: true,
      default_tax: false,
      is_fee: "Tax",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.TAX_NAME_REQUIRED)
        .max(191, Constants.TAX_NAME_LENGTH_GREATE_THEN_191),
      type: Yup.string().required(Constants.TAX_TYPE_REQUIRED),
      is_fee: Yup.string().required(Constants.TAX_IS_FEE_REQUIRED),
      rate: Yup.number()
        .required(Constants.TAX_RATE_REQUIRED)
        .typeError(Constants.TAX_RATE_NOT_VALID)
        .when("type", {
          is: "per",
          then: (schema) =>
            schema
              .min(0.01, Constants.TAX_RATE_MIN_VALUE)
              .max(100, Constants.TAX_PER_MORE_THEN_100),
          otherwise: (schema) =>
            schema
              .min(0.01, Constants.TAX_RATE_MIN_VALUE)
              .max(999999.99, Constants.TAX_RATE_MAX_VALUE),
        }),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      setAllTouchedField(false);
      submit(values, {
        method: Constants.POST,
        path: Constants.TAX_CREATE_API_ROUTE,
      });
    },
  });

  const handleKeyDown = (event) => {
    // if (
    //   event.key === "Enter" &&
    //   !(
    //     formik.values.name.length <= 0 ||
    //     formik.errors.name ||
    //     formik.errors.type ||
    //     formik.errors.rate ||
    //     buttonDisable
    //   )
    // ) {
    //   formik.handleSubmit();
    // }
    if (event.key === "Enter" && !buttonDisable) {
      if (!formik.isValid) {
        if (!allTouchedField) {
          formik.handleSubmit();
          setAllTouchedField(true);
        }
      } else {
        formik.handleSubmit();
      }
    }
  };
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1">
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              // pass param data in tax list page
              props.navigate(Constants.TAX_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Taxes/Fees", "Create Taxes/Fees"]}
            breadCrumTitle={"Create Taxes/Fees"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                // pass param data in modifier set list page
                props.navigate(Constants.TAX_LIST, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              // isDisabled={
              //   formik.values.name.length <= 0 ||
              //   formik.errors.name ||
              //   formik.errors.type ||
              //   formik.errors.rate ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              isDisabled={buttonDisable ? true : false}
              buttonsubmit={"Submit"}
              SubmitButton={true}
              // onClick={formik.handleSubmit}
              onClick={() => {
                // submit time all validation fired and error massage display.
                if (!formik.isValid) {
                  if (!allTouchedField) {
                    formik.setTouched({
                      default_tax: true,
                      exempt_ebt: true,
                      is_fee: true,
                      name: true,
                      rate: true,
                      type: true,
                    });
                    formik.handleSubmit();
                    setAllTouchedField(true);
                  }
                } else {
                  formik.handleSubmit();
                }
              }}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          p="8"
        >
          <Text
            fontSize="1.125rem"
            w="10.5rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.02rem",
            }}
          >
            Taxes/Fees Details
          </Text>
          <Stack spacing={0}>
            <PosLable requiredLabel={true} name={"Name"} />
            <PosInput
              id="name"
              placeholder={""}
              // handleInputChange={formik.handleChange}
              handleInputChange={(e) => {
                const value = e.target.value.replace(/,/g, "");
                formik.setFieldValue("name", value);
                if (e.target.value.includes(",")) {
                  addToast({
                    alertStatus: Constants.TOAST_TYPE_WARNING,
                    alertTitle: Constants.TAX_MODULE,
                    alertDescription: Constants.COMMA_NOT_ALLOWED,
                  });
                }
              }}
              inputValue={formik.values.name || ""}
              handleBlur={(e) => {
                formik.setFieldValue("name", formik.values.name.trim());
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInput={true}
              inputType={"text"}
              inputError={formik.touched.name && formik.errors.name}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <Flex flexDirection={"row"}>
            <PosLable requiredLabel={true} name="Type" />
            <Tippy
              content={Constants.TAX_TYPE_TIPPY}
              interactive={true}
              maxWidth={350}
              placement="top"
              animation="fade"
              inertia={true}
              moveTransition="transform 0.2s ease-out"
              theme="tomato"
            >
              <Image src={toolTip} alt="Tooltip" mt={"2rem"} ml={"0.3rem"} />
            </Tippy>
          </Flex>
          <PosRadio
            name={"type"}
            firstValue={"amou"}
            secoundValue={"per"}
            defaultValue="amou"
            value={formik.values.type}
            firstLable={Constants.TAX_TYPE_AMOUNT}
            secoundLable={Constants.TAX_TYPE_PERCENTAGE}
            handleSelectType={(e) => {
              formik.setFieldValue("type", e.target.value);
              if (!allTouchedField) {
              } else {
                if (formik.touched.rate) {
                  setTimeout(() => {
                    formik.setFieldValue("rate", twofix(formik.values.rate));
                  }, 1000);
                }
              }
            }}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.type && formik.errors.type ? (
              <span>{formik.errors.type}</span>
            ) : null}
          </Text>
          <Stack spacing={0} mt="1rem">
            {/* <PosLable requiredLabel={true} name={"Rate"}  /> */}
            <PosInput
              id="rate"
              placeholder={"Enter Tax Rate"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.rate || ""}
              handleBlur={(e) => {
                formik.handleBlur(e);
                formik.setFieldValue("rate", twofix(e.target.value));
                // setTimeout(() => {
                // }, 1000);
              }}
              precision={2}
              onKeyDown={handleKeyDown}
              posNumberInput={true}
              inputError={formik.touched.rate && formik.errors.rate}
              darkPlaceholder={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.rate && formik.errors.rate ? (
                <span>{formik.errors.rate}</span>
              ) : null}
            </Text>
          </Stack>
          <Flex flexDirection={"row"}>
            <PosLable label={true} name={"Exempt EBT"} />
            <Tippy
              content={Constants.TAX_EBT_EXEMPT_TIPPY}
              interactive={true}
              maxWidth={350}
              placement="top"
              animation="fade"
              inertia={true}
              moveTransition="transform 0.2s ease-out"
              theme="tomato"
            >
              <Image src={toolTip} alt="Tooltip" mt={"2rem"} ml={"0.3rem"} />
            </Tippy>
          </Flex>
          <Stack
            direction={"row"}
            mt={"0.313rem"}
            w={"2.75rem"}
            height={"1.38rem"}
            flexShrink={"0"}
          >
            <PosSwitchButton
              id="exempt_ebt"
              switchValue={formik.values.exempt_ebt}
              onChange={formik.handleChange}
            />
          </Stack>
          <Flex flexDirection={"row"}>
            <PosLable
              label={true}
              name={"Enabled by Default on Product Creation"}
            />
            <Tippy
              content={Constants.TAX_ENABLED_BY_DEFAULT_TIPPY}
              interactive={true}
              maxWidth={350}
              placement="top"
              animation="fade"
              inertia={true}
              moveTransition="transform 0.2s ease-out"
              theme="tomato"
            >
              <Image src={toolTip} alt="Tooltip" mt={"2rem"} ml={"0.3rem"} />
            </Tippy>
          </Flex>
          <Stack
            direction={"row"}
            mt={"0.313rem"}
            w={"2.75rem"}
            height={"1.38rem"}
            flexShrink={"0"}
          >
            <PosSwitchButton
              id="default_tax"
              switchValue={formik.values.default_tax}
              onChange={formik.handleChange}
            />
          </Stack>
          <Flex flexDirection={"row"}>
            <PosLable requiredLabel={true} name="Tax/Fee" />
            <Tippy
              content={Constants.TAX_FEE_TIPPY}
              interactive={true}
              maxWidth={350}
              placement="top"
              animation="fade"
              inertia={true}
              moveTransition="transform 0.2s ease-out"
              theme="tomato"
            >
              <Image src={toolTip} alt="Tooltip" mt={"2rem"} ml={"0.3rem"} />
            </Tippy> 
          </Flex>
          <PosRadio
            name={"is_fee"}
            firstValue={"Tax"}
            secoundValue={"Fee"}
            value={formik.values.is_fee}
            firstLable={Constants.TAX_TYPE_TAX}
            secoundLable={Constants.TAX_TYPE_FEE}
            handleSelectType={(e) => {
              formik.setFieldValue("is_fee", e.target.value);
            }}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.is_fee && formik.errors.is_fee ? (
              <span>{formik.errors.is_fee}</span>
            ) : null}
          </Text>
          {/* <Text
            mt={"0.88rem"}
            h={"1.44rem"}
            color={Colors.taxCreateExemptEbt}
            style={{
              flexShrink: "0",
              fontSize: "0.75rem",
              fontStyle: "normal",
              fontWeight: "300",
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            {Constants.TAX_EBT_EXEMPT_DISCRIPTION}
          </Text> */}
        </Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(TaxCreate);
