import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup, ChakraProvider, Divider,
  Flex,
  Image,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer, Stack, Text, useBreakpointValue,
  useMediaQuery, VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Checkbox } from "@mui/material";
import { Dialog } from "primereact/dialog";
import { Checkbox as PrimeReactCheckbox} from "primereact/checkbox";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { GridToolbar } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData, useLocation,
  useNavigate, useSubmit
} from "react-router-dom";
import "tippy.js/dist/tippy.css";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import invoice from "../../../assets/images/invoice.svg";
import POCreateImage from "../../../assets/images/po-create.png";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosNoDataFound, PosProgress,
  PosSearch,
  PosSwitchButton,
  PosTostMessage
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { endOfTodaysDay, startOfTodaysDay } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme, theme } from "../../../theme/index";
import {
  getExportTransferOrder, getFetchProduct, getManualProductList,
  getPdfTransferOrder,
  getProductList
} from "./TransferOrderService";
// import { AutoComplete } from "primereact/autocomplete";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Search2Icon } from "@chakra-ui/icons";

const TransferOrderCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const locationRef = useRef(false);
  const fetchRef = useRef(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [productData, setProductData] = useState([]);
  const [locationProductFLag, setLocationProductFLag] = useState("");
  const [locationAllProducts, setLocationAllProduct] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [viewSelectedProducts, setViewSelectedProducts] = React.useState([{
    id: '',
    name: '',
    categories: '',
    barcode: '',
    sale_price: '',
    in_stock_qty: '',
    quantity: '',
    unit_of_measures:[],
    measureUnit:'',
    additionalRow:true
  }]);
  const [modalOpen, setModal] = useState(false);
  const [modalOpens, setModals] = useState(false);
  const [selectedLocationSourceData, setSelectedSourceLocationData] = useState([]);
  const [selectedLocationDestinationData, setSelectedDestinationLocationData] = useState([]);
  const [selectedVendorData, setSelectedVendorData] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [locationSourceData, setLocationSourceData] = useState([]);
  const [locationDestinationData, setLocationDestinationData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [perchesOrderData, setPerchesOrderData] = useState([]);
  const [idPo, setIdPo] = useState("");
  const calendarRef = useRef(Calendar);
  const calendarRef1 = useRef(Calendar);
  const [isChecked, setIsChecked] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [checked, setChecked] = useState(false);
  const selectedProductRef = useRef([]);
  const oldselectedRef = useRef([]);
  const checkedItemsRef = useRef(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    id: false,
  });
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  const [paramData, setParamData] = useState(stateData.state); 

  const selectedProdQtyRef = useRef([]);
  const [products, setProducts] = useState([]);
  const [autoSelectedProducts, setAutoSelectedProducts] = useState('');
  const [filteredProducts, setFilteredProducts] = useState('');
  const effectRunData = useRef(true);
  const inputRefs = useRef([]);
  const autoCompleteActive = useRef(true);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading1 = open && options.length === 0;
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isMobileDialog] = useMediaQuery("(max-width: 992px)");
  const [timeoutId, setTimeoutId] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {
    isOpen: isProductModalOpen,
    onOpen: onProductModalOpen,
    onClose: onProductModalClose,
  } = useDisclosure();
  const selectedProductDataRef = useRef([]);

  const sleep = (duration) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }
  const [value, setValue] = useState(options[0]);
  const [inputValueAuto, setInputValueAuto] = useState('');
  const [isAllProductsChecked, setIsAllProductsChecked] = useState(true);
  const [selectedProductError, setSelectedProductError] = useState(null);
  const [selectProductFromDialogError, setSelectProductFromDialogError] = useState(null);

useEffect(() => {
  // let active = true;
  let isMounted = true; // flag to track component mount state
  autoCompleteActive.current = true;

  if (!loading1) {
    return undefined;
  }

  const handler = setTimeout(() => {
    if (autoCompleteActive.current && isMounted) {
      search();
    }
  }, 300); // Adjust the debounce time as needed (e.g., 300ms)

  return () => {
    autoCompleteActive.current = false;
    isMounted = false;
    clearTimeout(handler);
  };
}, [loading1,inputValueAuto]);

useEffect(() => {
  if (!open) {
    setOptions([]);
  }
}, [open]);

  const search = () => {
    const locationsIds = [selectedLocationSourceData.id, selectedLocationDestinationData.id];
    let data = {
      locations: locationsIds,
      filter: inputValueAuto,
      show_all: isAllProductsChecked == true ? true : false,
      meilisearch: true,
      query: {
        limit: 1000,
        page: 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: inputValueAuto,
        allProdFlag: locationProductFLag === 1 ? 1 : 0,
      },
    };
    setLoading(true);
    try {
      getManualProductList(data)
        .then((response) => {
          if (response.data.flag === true) {
            setProducts(response.data.data.data.data);
            setProductData(response.data.data.data.data);
            setOptions(response.data.data.data.data);
            // setTimeout(() => {
            //   let _filteredProducts;
            //   if (!event.query.trim().length) {
            //     _filteredProducts = [...response.data.data.data];
            //   } else {
            //     _filteredProducts = response.data.data.data.filter((product) => {
            //       return (
            //         (product.name.toLowerCase().includes(event.query.toLowerCase()) ||
            //         product.barcode.toLowerCase().startsWith(event.query.toLowerCase()))
            //       );
            //     });
            //   }
            //   setFilteredProducts(_filteredProducts);
            // }, 350);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setProducts([]);
            setTotalCount(0);
            setLoading(false);
            setOptions([]);
            setOpen(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setProducts([]);
          setTotalCount(0);
          setOptions([]);
          setOpen(false);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setProductData([]);
      setTotalCount(0);
      setLoading(false);
      setOptions([]);
      setOpen(false);
      myContext.handleLoading(false);
    }
};
//   const search = (event) => {
//     let data = {
//       vendors: selectedVendorData.id,
//       locations: selectedLocationData.id,
//       query: {
//         limit: paginationModel.pageSize,
//         page: paginationModel.page + 1,
//         order:
//           sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
//         order_type:
//           sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
//         filter: searchQuery,
//         allProdFlag: locationProductFLag === 1 ? 1 : 0,
//       },
//     };
//     setLoading(true);
//     try {
//       getManualProductList(data)
//         .then((response) => {
//           if (response.data.flag === true) {
//             setProducts(response.data.data.data);
//             setProductData(response.data.data.data);
//             setTimeout(() => {
//               let _filteredProducts;
//               if (!event.query.trim().length) {
//                 _filteredProducts = [...response.data.data.data];
//               } else {
//                 _filteredProducts = response.data.data.data.filter((product) => {
//                   return (
//                     (product.name.toLowerCase().includes(event.query.toLowerCase()) ||
//                     product.barcode.toLowerCase().startsWith(event.query.toLowerCase()))
//                   );
//                 });
//               }
//               setFilteredProducts(_filteredProducts);
//             }, 350);
//             setShowTable(false);
//             myContext.handleLoading(false);
//           } else {
//             setProducts([]);
//             setTotalCount(0);
//             setLoading(false);
//             setShowTable(false);
//             let actionData = response;
//             error({ actionData });
//             myContext.handleLoading(false);
//           }
//         })
//         .catch((err) => {
//           setProducts([]);
//           setTotalCount(0);
//           setLoading(false);
//           setShowTable(false);
//           let actionData = err;
//           error({ actionData });
//           myContext.handleLoading(false);
//         });
//     } catch (error) {
//       setProductData([]);
//       setTotalCount(0);
//       setLoading(false);
//       myContext.handleLoading(false);
//     }
// };

const autoCompleteSelectedProducts= (pro) => {
  if(undefined != pro && null != pro && '' != pro){
    if(!checkedItems.includes(pro.id)){
      var proAutoData = {
        id: pro.id,
        name: pro.name,
        barcode: pro.barcode,
        sku: pro.sku || "N.A.",
        in_stock_qty: pro.in_stock_qty,
        sale_price: pro.sale_price,
        // location: pro.location.name,
        threshold: pro.threshold || "N.A.",
        unit_case:pro.no_of_eaches || "",
        autoCompleteProduct:true,
        // categories: pro.categories,
        pro_id:pro.product_id,
        unit_of_measures:pro.product_unit_measures,
        measureUnit:pro.selected_unit_of_measure,
        unit_quantity:''
      }
      const umData = pro.product_unit_measures.find(
        (um) => um.id === pro.selected_unit_of_measure
      );
      if(undefined != umData && null != umData && '' != umData){                
        proAutoData.unit_quantity = parseInt(proAutoData.quantity) * parseFloat(umData.base_qty).toFixed(2);
      }
      handleCheckboxChange({row:proAutoData});
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
    else{
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TRANSFER_ORDER_MODULE,
        alertDescription:'Product already exists.',
      });
      setAutoSelectedProducts("");
      setInputValueAuto("");
    }
    // setAutoSelectedProducts('');
  }
}

  const unselectProducts = () => {
    if (!isChecked) {
      // setIsChecked(false);
    } else {
      const selectedProductIds = viewSelectedProducts.map(
        (product) => product.id
      );
      setCheckedItems(selectedProductIds);
      setPerchesOrderData(selectedProdQtyRef.current);
    }
    // fetchData();
  };

  const handleSwitchChange = (e) => {
    const checked = e.target.checked;
    setSelectedCategories([]);
    setSearchQuery("");
    fetchRef.current = true;
    setIsChecked(checked);
    if (checked) {
    } else {
      unselectProducts();
    }
  };
  const handleAllProductSwitchChange = (e) => {
    setSelectedCategories([]);
    const checked = e.target.checked;
    setSearchQuery("");
    fetchRef.current = true;
    setIsAllProductsChecked(checked);
    const selectedProductIds = viewSelectedProducts.map(
      (product) => product.id
    );
    setCheckedItems(selectedProductIds);
    setPerchesOrderData(selectedProdQtyRef.current);
    if(isChecked){
      setIsChecked(false);
    }
  }; 

  const onSelectSourceLocation = (e) => {
    if (undefined !== e.value) {
      locationRef.current = true;
      if((undefined != selectedLocationDestinationData.id && null != selectedLocationDestinationData.id && '' != selectedLocationDestinationData.id) && e.value.id == selectedLocationDestinationData.id){
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TRANSFER_ORDER_MODULE,
          alertDescription: Constants.TRANSFER_ORDER_SOURCE_DESTINATION_CAN_NOT_BE_SAME,
        });
      }
      else{
        setSelectedSourceLocationData(e.value);
        setPerchesOrderData([]);
        setViewSelectedProducts([{
          id: '',
          name: '',
          // categories: '',
          barcode: '',
          sale_price: '',
          in_stock_qty: '',
          quantity: '',
          unit_of_measures:[],
          measureUnit:'',
          additionalRow:true
        }]);
        setCheckedItems([]);
        selectedProductRef.current = []
        setSelectAll(false);
        setIsChecked(false);
        formik.setFieldValue("source_location", e.value);
        setFilteredProducts('');
        setProducts([]);
      }
    }
  };
  const onSelectDestinationLocation = (e) => {
    if (undefined !== e.value) {
      if(e.value.id == selectedLocationSourceData.id){
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TRANSFER_ORDER_MODULE,
          alertDescription: Constants.TRANSFER_ORDER_SOURCE_DESTINATION_CAN_NOT_BE_SAME,
        });
      }
      else{
        locationRef.current = true;
        setSelectedDestinationLocationData(e.value);
        setPerchesOrderData([]);
        setViewSelectedProducts([{
          id: '',
          name: '',
          // categories: '',
          barcode: '',
          sale_price: '',
          in_stock_qty: '',
          quantity: '',
          unit_of_measures:[],
          measureUnit:'',
          additionalRow:true
        }]);
        setCheckedItems([]);
        selectedProductRef.current = []
        setSelectAll(false);
        setIsChecked(false);
        formik.setFieldValue("destination_location", e.value);
        setFilteredProducts('');
        setProducts([]);
      }
      // setAutoSelectedProducts('');
    }
  };
  

  const handleClear = () => {
    // clear input value and get all data
    fetchRef.current = true;
    setSearchQuery("");
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      locationRef.current = true;
      setSelectedVendorData(e.value);
      setPerchesOrderData([]);
      setViewSelectedProducts([{
        id: '',
        name: '',
        // categories: '',
        barcode: '',
        sale_price: '',
        in_stock_qty: '',
        quantity: '',
        unit_of_measures:[],
        measureUnit:'',
        additionalRow:true
      }]);
      setCheckedItems([]);
      selectedProductRef.current = []
      setSelectAll(false);
      setIsChecked(false);
      formik.setFieldValue("vendors", e.value);
      setFilteredProducts('');
      setProducts([]);
      // setAutoSelectedProducts('');
    }
  };

  const handleCloseModal = () => {
    setModal(false);
    setSelectAll(false);
    const selectedProductIds = viewSelectedProducts.map(
      (product) => product.id
    );
    setCheckedItems(selectedProductIds);
    setPerchesOrderData(selectedProdQtyRef.current);
    selectedProductRef.current = oldselectedRef.current
    setSearchQuery("");
    setIsChecked(false);
  };

  function handleCheckboxChange(params) {
    const rowId = params.row.id;    
    let sdataIndex = selectedProductData.findIndex((x) => x.id == rowId);
    if (sdataIndex > -1) {
      let array = [
        ...selectedProductData.slice(0, sdataIndex),
        ...selectedProductData.slice(sdataIndex + 1),
      ];
      setSelectedProductData(array);
    } else {
      let prodData = [...selectedProductData, params.row];
      setSelectedProductData(prodData);
    }
    const productIndex = selectedProductRef.current.findIndex(
      (x) => x.id == rowId
    );
    let dataIndex = productData.findIndex((x) => x.id == rowId);
    if (checkedItems.includes(rowId)) {
      let index = checkedItems.findIndex((x) => x === rowId);
      let prodIndex = perchesOrderData.findIndex((x) => x.id === rowId);
      if (index > -1 && prodIndex > -1) {
        let data = [
          ...checkedItems.slice(0, index),
          ...checkedItems.slice(index + 1),
        ];
        let array = [
          ...perchesOrderData.slice(0, prodIndex),
          ...perchesOrderData.slice(prodIndex + 1),
        ];
        setCheckedItems(data);
        setPerchesOrderData(array);
      }
    } else {
      let index = selectedProdQtyRef.current.findIndex((x) => x.id == rowId);
      // let prodId = productData.findIndex((x) => x.id == rowId);
      let obj = {
        id: rowId,
        // sale_price_edit: twofix(parseFloat(params.row.sale_price_edit)),
        quantity: "",
        quantityErrors:
          index > -1 ? selectedProdQtyRef.current[index].salePriceErrors : "",
          unit_of_measures:index > -1 ? selectedProdQtyRef.current[index]?.product_unit_measures
          : null != productData[dataIndex]?.product_unit_measures
          ? productData[dataIndex]?.product_unit_measures : [],
        measureUnit:index > -1 ? selectedProdQtyRef.current[index]?.selected_unit_of_measure
          : null != productData[dataIndex]?.selected_unit_of_measure
          ? productData[dataIndex]?.selected_unit_of_measure : "",
        unit_quantity:index > -1 ? selectedProdQtyRef.current[index]?.unit_quantity
          : null != productData[dataIndex]?.unit_quantity
          ? productData[dataIndex]?.unit_quantity : "",
      };      
      let array = [...perchesOrderData, obj];
      setPerchesOrderData(array);
      let data = [...checkedItems, rowId];
      setCheckedItems(data);
    }
    if (params.row.autoCompleteProduct) {
      checkedItemsRef.current = true;
      effectRunData.current = true;
    }
  }

  useEffect(() => {
    if(checkedItemsRef.current) {
      handleSelectedViewProduct();
      checkedItemsRef.current = false;
    }
  },[checkedItems]);

  const handleSelectedViewProduct = () => {
    if(checkedItems.length <= 0 ||
      (checkedItems.length === 1 && checkedItems[0] === "") ||
      (isChecked && selectedProductData.length === 0))
    {
      setSelectProductFromDialogError({
        message: Constants.PRODUCT_REQUIRED,
      });
      return;
    }else{
      setSelectProductFromDialogError(null);
    }
    setModal(false);
    setVisible(false);
    selectedProductRef.current = selectedProductData;
    let removeItems = viewSelectedProducts.filter((product) =>
    undefined !== product?.id && checkedItems.includes(product?.id)
    );
    let selectedProducts = [...removeItems];
    checkedItems.map((id) => {
      let index = selectedProducts.findIndex((x) => undefined !== x?.id && x?.id === id);
      if (index <= -1) {
        let item = selectedProductRef.current.filter(
          (product) => undefined !== product?.id && product?.id === id
        );
        if(undefined !== item[0] && null !== item[0]){
          // selectedProducts.push(item[0]);
          let searchViewExist = selectedProducts.findIndex((x) => x.additionalRow == true);
          if(searchViewExist >= 0){
            selectedProducts.splice(selectedProducts.length-1, 0, item[0]);
          }
          else{
            selectedProducts.splice(selectedProducts.length, 0, item[0]);
          }       
        }
      }
    });

    setViewSelectedProducts(selectedProducts);
    setSearchQuery("");
    setIsChecked(false);
    effectRunData.current = true;
    focusInputByIndex();
    onProductModalClose();
  };

  const handleSingleDelete = (id) => {
    if (checkedItems.includes(id)) {
      let dataIndex = selectedProductData.findIndex((x) => x.id == id);
      if (dataIndex > -1) {
        let array = [
          ...selectedProductData.slice(0, dataIndex),
          ...selectedProductData.slice(dataIndex + 1),
        ];
        setSelectedProductData(array);
        selectedProductRef.current = array;
      }
      const updatedCheckedItems = checkedItems.filter(
        (itemId) => itemId !== id
      );
      setCheckedItems(updatedCheckedItems);
      const updatedSelectedProducts = viewSelectedProducts.filter(
        (product) => product.id !== id
      );
      setViewSelectedProducts(updatedSelectedProducts);
      const updatedPerchesOrderData = perchesOrderData.filter(
        (item) => item.id !== id
      );
      setPerchesOrderData(updatedPerchesOrderData);
      selectedProductRef.current = selectedProductRef.current.filter(
        (product) => product.id !== id
      );
      // Check if all products are deleted (only the search row remains)
      if (updatedSelectedProducts.length <= 1 && updatedSelectedProducts[0]?.additionalRow) {
        setSelectedProductError({
          message: Constants.PRODUCT_ADD_REQUIRED,
        });
      }
      // fetchData();
      // setModal(false);
      // setSearchQuery("");
    }
  };
  const modalSize = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "4xl",
    xl: "6xl",
  });
  const modalSizes = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "md",
    xl: "lg",
  });

  const handleExportReport = async () => {
    let data = {
      id: idPo,
    };
    setLoading(true);
    try {
      getExportTransferOrder({ id: data.id })
        .then((response) => {
          if (response.data.flag === true) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.TRANSFER_ORDER_MODULE,
              alertDescription: response.data.message,
            });
            let url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setTotalCount(0);
            setLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setTotalCount(0);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const handlePdfReport = async () => {
    let data = {
      id: idPo,
    };
    setLoading(true);
    try {
      getPdfTransferOrder({ id: data.id })
        .then((response) => {
          if (response.data.flag === true) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.TRANSFER_ORDER_MODULE,
              alertDescription: response.data.message,
            });
            let url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setTotalCount(0);
            setLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setTotalCount(0);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocationSourceData(loaderResponse.data.data.location);
          setLocationDestinationData(loaderResponse.data.data.location);
          if (buttonClicked) {
            fetchData();
            setModal(true);
            setButtonClicked(false);
          }
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        setIdPo(actionResponse.data.data.purchase_order_id);
        props.navigate(Constants.TRANSFER_ORDER_PATH, {
          state: paramData,
        });
        // setModals(true);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.TRANSFER_ORDER_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse, buttonClicked]);

  const handleResetAllData = () => {
    setLoading(true);
    formik.resetForm();
    setModals(false);
    setPerchesOrderData([]);
    setProductData([]);
    setViewSelectedProducts([{
      id: '',
      name: '',
      // categories: '',
      barcode: '',
      sale_price: '',
      in_stock_qty: '',
      quantity: '',
      unit_of_measures:[],
      measureUnit:'',
      additionalRow:true
    }]);
    setSelectedVendorData([]);
    setSelectedSourceLocationData([]);
    window.location.reload();
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      source_location: selectedLocationSourceData.id,
      destination_location: selectedLocationDestinationData.id,
    });
  }, [selectedLocationSourceData.id, selectedLocationDestinationData.id]);
  const modifiedData = productData.map((data) => ({
    id: data.id,
    name: data.name,
    barcode: data.barcode,
    sku: data.sku || "N.A.",
    in_stock_qty: data.in_stock_qty,
    sale_price: data.sale_price,
    threshold: data.threshold || "N.A.",
    // location: data.location.name,
    // unit_case:data.no_of_eaches || "",
    category_name:  data?.category_name?.replace(/,/g, ", ") || "",
    pro_id:data.product_id,
    unit_of_measures:data.product_unit_measures,
    measureUnit:data.selected_unit_of_measure,
    unit_quantity:''
  }));
  const [selectAll, setSelectAll] = useState(false);
  const focusInputByIndex = () => {
    if (inputRefs.current[modifiedViewDatas.length-1]) {
      inputRefs.current[modifiedViewDatas.length-1].focus();
    }
  };
  // const handleSelectAll = (event) => {
  //   if (event.target.checked) {
  //     let ids = checkedItems.map((row) => {
  //       if(modifiedData.filter((x) => x.id == row.id).length === 0){
  //         return row
  //       }
  //     })
  //     let allProdIds =  modifiedData.map((row) => row.id)
  //     let checkedIds = [...ids,...allProdIds]
  //     setCheckedItems(checkedIds);
  //     let prod = productData.map((row) => {
  //       let index = selectedProductRef.current.findIndex(x => x.id === row.id)
  //       if(index <= -1){
  //         return row
  //       }
  //     })
  //     selectedProductRef.current=[...selectedProductRef.current,...prod];
  //     let array = []
  //     checkedIds.map((rowId) => {
  //       let dataIndex = locationAllProducts.findIndex((x) => x.id == rowId);
  //       let index = selectedProdQtyRef.current.findIndex((x) => x.id == rowId);
  //       let obj = {
  //         id: rowId,
  //         quantity: index > -1 ? selectedProdQtyRef.current[index].quantity : "",
  //         cost:
  //           index > -1
  //             ? selectedProdQtyRef.current[index].cost
  //             : undefined != locationAllProducts[dataIndex]?.cost &&
  //               null != locationAllProducts[dataIndex]?.cost
  //             ? locationAllProducts[dataIndex]?.cost
  //             : "",
  //         quantityUnit:
  //           index > -1 ? selectedProdQtyRef.current[index].quantityUnit : "CAS",
  //         costErrors:
  //           index > -1 ? selectedProdQtyRef.current[index].costErrors : "",
  //         quantityErrors:
  //           index > -1 ? selectedProdQtyRef.current[index].quantityErrors : "",
  //       };
  //       array.push(obj)
  //     })
  //     setPerchesOrderData(array);
  //     setSelectAll(true);
  //   } else {
  //     selectedProductRef.current = [];
  //     setCheckedItems([]);
  //     setPerchesOrderData([]);
  //     setSelectAll(false);
  //   }
  // };
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  var selectAllChecked = modifiedData.filter(x => checkedItems.includes(x.id))
  const selectAllProduct = (isChecked) => {
    const pageData = modifiedData;
    const selectedIds = pageData.map((data) => data.id);
    let updatedCheckedItems, updatedSelectedProductData, updatedPerchaseData;
    if (isChecked) {
      // If the checkbox is checked, select all items on the current page
      updatedCheckedItems = [
        ...checkedItems,
        ...selectedIds.filter((id) => !checkedItems.includes(id)),
      ];
      updatedSelectedProductData = [
        ...selectedProductData,
        ...pageData.filter(
          (data) => !selectedProductData.some((item) => item.id === data.id)
        ),
      ];
      const newPageData = pageData
        .filter(
          (pageItem) =>
            !perchesOrderData.some((prodItem) => prodItem.id === pageItem.id)
        )
        .map((data) => ({
          id: data.id,
          // cost_per_unit: "", 
          quantity: "",
          quantityErrors: "",
          unit_of_measures:data.unit_of_measures,
          unit_quantity:data.unit_quantity,
          measureUnit:data.measureUnit
          // quantityUnit:"CAS",
          // total_unit:"",
          // unit_case:"",
          // cost:""
        }));
      updatedPerchaseData = [...perchesOrderData, ...newPageData];
    } else {
      // If the checkbox is unchecked, unselect all items on the current page
      updatedCheckedItems = checkedItems.filter(
        (id) => !selectedIds.includes(id)
      );
      updatedSelectedProductData = selectedProductData.filter(
        (item) => !selectedIds.includes(item.id)
      );
      updatedPerchaseData = perchesOrderData.filter(
        (item) => !selectedIds.includes(item.id)
      );
    }
    setCheckedItems(updatedCheckedItems);
    setSelectedProductData(updatedSelectedProductData);
    setPerchesOrderData(updatedPerchaseData);
  };
  const onCheckboxSelectionChange = (e) => {
    const isChecked = e.target.checked; // This will be true if the checkbox is being checked, and false if it's being unchecked.
    setAllChecked(isChecked);
    selectAllProduct(isChecked);
  };
  const openDialog = (index) => {
    var CheckedProducts = [];
    if (viewSelectedProducts.length > 0) {
      viewSelectedProducts.map((dt) => {
        // CheckedBarcodes.push(dt.id);
        CheckedProducts.push(dt.id);
      });
    }
    setCheckedItems(CheckedProducts);
    selectedProdQtyRef.current = perchesOrderData;
    const updateData=perchesOrderData.map((item) => ({
      ...item
    }));
    setPerchesOrderData(updateData)
    oldselectedRef.current = selectedProductRef.current;
    setIsDialogOpen(true);
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
    fetchRef.current = true;
    setSortModel([
      {
        field: "name",
        sort: "asc",
      },
    ]);
    setIsAllProductsChecked(true);
    setSelectedCategories([]);
    onProductModalOpen();
  };
  const modalClose = () => {
    setVisible(false);
    setSelectedProductData(selectedProductRef.current);
    const selectedProductIds = viewSelectedProducts.map(
      (product) => product.id
    );
    setCheckedItems(selectedProductIds);
    selectedProductRef.current = oldselectedRef.current;
    setPerchesOrderData(selectedProdQtyRef.current);

    setSearchQuery("");
    onProductModalClose();
    setSelectedCategories([]);
    setIsChecked(false);
    setSelectProductFromDialogError(null);
  };
  const onHideDialog = () => {
    formik.resetForm();
    setSelectedCategories([]);
    setVisible(false);
    setSelectProductFromDialogError(null);
  };
  const clickIfapplyCat = (cat) => {
    setSelectedCategories(cat);
    // if (
    //   cat.length == 0 &&
    //   selectedVendors.length == 0 &&
    //   selectedLocations?.length == 0
    // ) {
    // setPage(0);
    paginationModel.page = 0;
    apply();
    // }
  };
  const apply = () => {
    fetchRef.current = true;
    setLoading(true);
  };
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    // setPage(0);
    paginationModel.page = 0;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };
  
  const productColumnNames = [
    {
      field: "selection",
      // headerName: "",
      hideable: false,
      // headerName: (
        // <Checkbox
        //   checked={selectAll || selectAllChecked.length === modifiedData.length}
        //   onChange={handleSelectAll}
        //   indeterminate={
        //     checkedItems.length > 0 && checkedItems.length < modifiedData.length
        //   }
        // />
      renderHeader: () => (
        <Checkbox onChange={onCheckboxSelectionChange} checked={allChecked}/>
      ),
      sortable: false,
      resizable: false,
      ...actionColumnXS,
      renderCell: (params) => (
        <IconButton aria-label="select" sx={{ color: "#5881FE" }}>
          <Checkbox
            checked={checkedItems.includes(params.row.id)}
            onChange={() => {
              handleCheckboxChange(params);
            }}
          />
        </IconButton>
      ),
    },
    { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
    {
      field: "name",
      headerName: "Product Name",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "barcode",
      headerName: "Primary Barcode",
      sortable: false,
      ...actionColumnXL,
    },
    {
      field: "sku",
      headerName: "SKU",
      sortable: false,
      ...actionColumnSM,
    },
    {
      field: "in_stock_qty",
      headerName: "In Stock",
      sortable: false,
      ...actionColumnMD,
    },
    {
      field: "sale_price",
      headerName: "Sale Price",
      sortable: false,
      ...actionColumnLG,
    },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   sortable: false,
    //   ...actionColumnMD,
    // },
    {
      field: "threshold",
      headerName: "Threshold",
      sortable: false,
      ...actionColumnLG,
    },
    {
      field: "category_name",
      headerName: "Categories",
      sortable: false,
      resizable: false,
      ...actionColumnLG,
    },
  ];

  const columnProductViewNames = [
    {
      columnNames: "Product Name",
      sortName: "",
      width: "",
      textAlign: "start",
    },
    {
      columnNames: "Primary Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price",
      sortName: "",
      width: "",
    },
    {
      columnNames: "On Hand",
      sortName: "",
      width: "",
    },
    // {
    //   columnNames: "Cost/Case($)",
    //   sortName: "",
    //   width: "",
    // },
    {
      columnNames: "Quantity",
      sortName: "",
      width: "",
    },
    // {
    //   columnNames: "Units/Case",
    //   sortName: "",
    //   width: "",
    // },
    // {
    //   columnNames: "Quantity Unit",
    //   sortName: "",
    //   width: "",
    // },
    // {
    //   columnNames: "Total Units",
    //   sortName: "",
    //   width: "",
    // },
    // {
    //   columnNames: "Cost/Unit",
    //   sortName: "",
    //   width: "",
    // },
    {
      columnNames: "U/M",
      sortName: "",
      width: "3rem",
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];
const modifiedViewDatas =  viewSelectedProducts.map((item) => ({
        id: item.id,
        name: item.name,
        // categories: item.hasOwnProperty('products') && undefined !== item?.products
        // ? item?.products?.categories?.map((cat) => cat.name).join(", ")
        // : item.hasOwnProperty('categories') && undefined !== item?.categories && '' !== item?.categories && null !== item?.categories 
        // ? item.categories?.map((cat) => cat.name).join(", "): item.hasOwnProperty('category_name') && undefined !== item?.category_name && '' !== item?.category_name && null !== item?.category_name ? item?.category_name : "",
        barcode: item.barcode,
        // item_code: item.getvendors?.[0]?.pivot?.item_code || "N.A.",
        sale_price: item.sale_price,
        in_stock_qty: item.in_stock_qty || "N.A.",
        quantity: parseInt(item.quantity),
        // cost: item.cost,
        // quantityUnit: item.quantityUnit,
        // unit_case:undefined !== item?.no_of_eaches ? item.no_of_eaches : null,
        // total_unit:(undefined !== item?.quantity && 0<item?.quantity )&&  (undefined !== item?.no_of_eaches && 0 < item?.no_of_eaches)  ? (item.quantity * item.no_of_eaches) : 'N.A.',
      }))

  useEffect(() => {
    if (fetchRef.current === true) {
      setAllChecked(false);
      if (!isChecked) {
        fetchData();
      } 
      // else {
      //   fetchDatathreshold();
      // }
      fetchRef.current = false;
    }
  }, [searchQuery, paginationModel, sortModel, loading, isChecked,modalOpen, isAllProductsChecked]);

  useEffect(() => {
    let searchExist = modifiedViewDatas.findIndex((x) => x.additionalRow == true);
    let searchViewExist = viewSelectedProducts.findIndex((x) => x.additionalRow == true);
    if (modifiedViewDatas.length > 0 && effectRunData.current && (searchExist == -1 && searchViewExist == -1)) {
    let array = [];
      var addInput = {
        id: '',
        name: '',
        // categories: '',
        barcode: '',
        // item_code: '',
        sale_price: '',
        in_stock_qty: '',
        quantity: '',
        unit_of_measures:[],
        measureUnit:'',
        // cost: '',
        // quantityUnit: '',
        // unit_case:'',
        // total_unit:'',
        additionalRow:true
      };
      array = [...viewSelectedProducts,addInput];
      setViewSelectedProducts(array);
      effectRunData.current = false;
    }
  }, [viewSelectedProducts]);

  const handleSearchList = () => {
    paginationModel.page = 0;
    if (isChecked) {
      // fetchDatathreshold();
    } else {
      fetchData();
    }
  };

  const handleColumn = (coulmn) => {
    if (coulmn.sortName === columnProductViewNames) {
      if (sortType === "asc") {
        // handleSortColumn(coulmn, "desc");
      } else {
        // handleSortColumn(coulmn, "asc");
      }
    } else {
      // handleSortColumn(coulmn, "asc");
    }
  };

  const fetchData = async () => {
    const locationsIds = [selectedLocationSourceData.id, selectedLocationDestinationData.id];
    let data = {
      locations: locationsIds,
      categories: selectedCategories,
      show_all: isAllProductsChecked == true ? true : false,
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
        allProdFlag: locationProductFLag === 1 ? 1 : 0,
      },
    };
    setLoading(true);
    try {
      getManualProductList(data)
        .then((response) => {
          if (response.data.flag === true) {
            setProductData(response.data.data.data.data);
            setLocationProductFLag(response.data.data.allLocationProductFLag);
            setTotalCount(response.data.data.totalItems);
            const cat = response.data.data?.category;
            const categoriesDropDownData = cat?.map((cat) => ({
              label: cat.name,
              value: { name: cat.name, id: cat.id },
            }));
            setCategories(categoriesDropDownData);
            setLoading(false);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setProductData([]);
            setTotalCount(0);
            setLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setProductData([]);
          setTotalCount(0);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setProductData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  // const fetchDatathreshold = async () => {
  //   let data = {
  //     vendors: selectedVendorData.id,
  //     locations: selectedLocationSourceData.id,
  //     query: {
  //       limit: paginationModel.pageSize,
  //       page: paginationModel.page + 1,
  //       order:
  //         sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
  //       order_type:
  //         sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
  //       filter: searchQuery,
  //     },
  //   };
  //   if (locationProductFLag == 1) {
  //     data.allProdFlag = 1;
  //   } else {
  //     data.allProdFlag = 0;
  //   }
  //   setLoading(true);
  //   try {
  //     getProductList(data)
  //       .then((response) => {
  //         if (response.data.flag === true) {
  //           let array = [];
  //           let selected_Product = [];
  //           const productDetails = response.data.data.data;
  //           setProductData(productDetails);
  //           productDetails.map((product) => {
  //             let index = viewSelectedProducts.findIndex(
  //               (x) => x.id === product.id
  //             );
  //             if (
  //               !(
  //                 parseFloat(product.in_stock_qty) >=
  //                 parseFloat(product.threshold)
  //               ) ||
  //               index > -1
  //             ) {
  //               selected_Product.push(product.id);
  //               selectedProductRef.current = [...selectedProductRef.current,product]
  //             }
  //           });
  //           setCheckedItems(selected_Product);
  //           selected_Product.map((item) => {
  //             // let obj = {
  //             //   id: item,
  //             //   quantity: "",
  //             //   cost: "",
  //             //   quantityUnit: "CAS",
  //             // };
  //             let index = selectedProdQtyRef.current.findIndex(
  //               (x) => x.id == item
  //             );
  //             let eaches = selectedProductRef.current.find(
  //               (x) => x.id == item
  //             );
  //             let obj = {
  //               id: item,
  //               quantity:
  //                 index > -1 ? parseInt(selectedProdQtyRef.current[index].quantity) : "",
  //               cost: index > -1 ? selectedProdQtyRef.current[index].cost : "",
  //               unit_case: index > -1 ? selectedProdQtyRef.current[index].unit_case : undefined != eaches.no_of_eaches ? eaches.no_of_eaches : "",
  //               quantityUnit:
  //                 index > -1
  //                   ? selectedProdQtyRef.current[index].quantityUnit
  //                   : "CAS",
  //               total_unit: index > -1 ? parseFloat(selectedProdQtyRef.current[index].quantity *  selectedProdQtyRef.current[index].unit_case).toFixed(2) : "N.A.",
  //               cost_per_unit: index > -1 ? parseFloat(selectedProdQtyRef.current[index].cost /  selectedProdQtyRef.current[index].unit_case).toFixed(2) : "N.A.",    
  //             };
  //             // array.push(obj);
  //             array.splice(array.length, 0, obj);
  //           });
  //           if (array.length > 0) {
  //             setPerchesOrderData(array);
  //           }
  //           setTotalCount(response.data.data.totalItems);
  //           setLoading(false);
  //           setShowTable(false);
  //           myContext.handleLoading(false);
  //         } else {
  //           setProductData([]);
  //           setPerchesOrderData([]);
  //           setTotalCount(0);
  //           setLoading(false);
  //           setShowTable(false);
  //           let actionData = response;
  //           error({ actionData });
  //           myContext.handleLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setProductData([]);
  //         setPerchesOrderData([]);
  //         setTotalCount(0);
  //         setLoading(false);
  //         setShowTable(false);
  //         let actionData = err;
  //         error({ actionData });
  //         myContext.handleLoading(false);
  //       });
  //   } catch (error) {
  //     setProductData([]);
  //     setTotalCount(0);
  //     setLoading(false);
  //     myContext.handleLoading(false);
  //   }
  // };

  function reportDateFormate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = moment(dateString);
    const formattedDate = date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    return formattedDate;
  }

  const formik = useFormik({
    initialValues: {
      source_location: [],
      destination_location: [],
      reference_number: "",
      remark:"",
      quantity: "",
      // payment_due_date: "",
      // received_date: "",
      // received: false,
      // paid: false,
    },
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize:true,
    validationSchema: Yup.object({
      remark: Yup.string()
        .trim()
        .max(191, Constants.REMARKS_LENGTH_GREATE_THEN_191),
      source_location: Yup.string().required(Constants.TRANSFER_ORDER_SELECT_SOURCE_LOCATION),
      destination_location: Yup.string().required(
        Constants.TRANSFER_ORDER_SELECT_DESTINATION_LOCATION
      ),
      reference_number: Yup.string().trim().required(
        Constants.TRANSFER_ORDER_REFRENCE_NUMBER_REQUIRED
      ),
    }),
    onSubmit: async (values) => {
      setVisible(false);
      myContext.handleLoading(true);
      var removeAdditionalField = viewSelectedProducts.filter((pr) => {
        return (
          (undefined == pr.additionalRow || pr.additionalRow != true)
        );
      });
      const payload = {
        source_location: selectedLocationSourceData.id,
        destination_location: selectedLocationDestinationData.id,
        reference_number: values.reference_number.trim(),
        remark: values.remark.trim(),
        products: JSON.stringify(
          removeAdditionalField.map((product) => {
            const matchedProduct = perchesOrderData.find(
              (p) => p.id === product.id
            );
            const measureUnit = matchedProduct?.measureUnit ?? product.selected_unit_of_measure;
            return matchedProduct
              ? {
                  ...product,
                  quantity: parseInt(matchedProduct.quantity),
                  measureUnit
                }
              : product;
          })
        ),
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        // path: Constants.PURCHASE_ORDER_EDIT_API_ROUTE,
      });
    },
  });

  const handleInputChange = (event, id, field_name) => {
    event.preventDefault();
    const { value } = event.target;
    let index = perchesOrderData.findIndex((x) => x.id === id);
    if (index > -1) {
      let obj = { ...perchesOrderData[index] };
      obj[field_name] = value;
      switch (field_name) {
        case "quantity":
          obj.quantityErrors =
            value.length === 0
              ? Constants.PURCHASING_ORDER_SELECT_QUANTITY
              : parseInt(value) < 1
              ? Constants.PURCHASING_ORDER_QUANTITY_MIN_VALUE
              : parseInt(value) > 1000000
              ? Constants.PURCHASING_ORDER_QUANTITY_MAX_VALUE
              : "";
          break;
        // case "cost":
        //   const parsedCost = parseFloat(value);
        //   obj.costErrors =
        //     value.length === 0
        //       ? ""
        //       : parseFloat(value).toFixed(2) < 0.0
        //       ? Constants.PURCHASING_ORDER_MIN_VALUE
        //       : isNaN(parsedCost) || !/^\d*\.?\d*$/.test(value)
        //       ? Constants.CUSTOMER_PLEASE_ENTER_A_VALID_CREDIT_LIMIT
        //       : parseFloat(value).toFixed(2) > 1000000
        //       ? Constants.PURCHASING_ORDER_MAX_VALUES
        //       : "";
        //   break;
        // case "unit_case":
        //   obj.unitCaseErrors =
        //     value.length === 0
        //       ? ""
        //       : parseFloat(value).toFixed(2) < 0.01
        //       ? Constants.PURCHASING_UNIT_CASE_MIN_VALUE
        //       : parseFloat(value).toFixed(2) > 1000000
        //       ? Constants.PURCHASING_UNIT_CASE_MAX_VALUE
        //       : "";
        //   break;
        default:
          break;
      }
      let array = [
        ...perchesOrderData.slice(0, index),
        obj,
        ...perchesOrderData.slice(index + 1),
      ];
      // array.map((obj,i)=>{
      //   if(((undefined == obj.unitCaseErrors || '' == obj.unitCaseErrors) && (undefined == obj.quantityErrors || '' == obj.quantityErrors)) && (obj.unit_case > 0 && obj.quantity > 0)){
      //     var total = parseFloat(obj.unit_case).toFixed(2) * parseInt(obj.quantity);
      //     obj.total_unit = parseFloat(total).toFixed(2);
      //   }
      //   else{
      //     obj.total_unit = 'N.A.';
      //   }
      //   if(((undefined == obj.unitCaseErrors || '' == obj.unitCaseErrors) && (undefined == obj.quantityErrors || '' == obj.quantityErrors)) && (obj.cost > 0 && obj.unit_case > 0)){
      //     var costUnit = parseFloat(obj.cost).toFixed(2) / parseFloat(obj.unit_case).toFixed(2);
      //     obj.cost_per_unit = parseFloat(costUnit).toFixed(2);
      //   }
      //   else{
      //     obj.cost_per_unit = 'N.A.';
      //   }
      // });
      setPerchesOrderData(array);
    }
  };

  // const handleKeyDown = (event) => {
  //   if (
  //     event.key === "Enter" &&
  //     !(formik.values.vendors.length <= 0 ||
  //     formik.errors.vendors ||
  //     formik.errors.locations ||
  //     formik.errors.quantity ||
  //     formik.errors.cost ||
  //     formik.errors.unit_case ||
  //     buttonDisable
  //       ? true
  //       : false)
  //   ) {
  //     formik.handleSubmit();
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !buttonDisable) {
        handleFormSubmission();
    }
  };

  const smoothScrollToElement = (element) => {
    const offset = 200; // Adjust this value to control the final position
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;
  
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  
    // Add a small delay before focusing to ensure scroll is complete
    setTimeout(() => {
      element.focus();
    }, 450);
  };

  const handleFormSubmission = () => {
    // Mark all fields as touched to trigger validation
    // formik.setTouched({
    //   source_location: true,
    //   destination_location: true,
    //   reference_number: true,
    //   remark: true
    // }, true);
  
    // // Run validation manually
    // if (viewSelectedProducts?.length - 1 <= 0) {
    //   setSelectedProductError({
    //     message: Constants.PRODUCT_ADD_REQUIRED,
    //   });
    //   return;
    // }
    // // Mark all sale price fields as touched to trigger validation
    // const updatedProdData = perchesOrderData?.map(item => {
    //   return {
    //     ...item,
    //     quantityErrors: 
    //       item.quantity === undefined || item.quantity === ""
    //       ? Constants.PURCHASING_ORDER_SELECT_QUANTITY
    //       : parseInt(item.quantity) < 1
    //       ? Constants.PURCHASING_ORDER_QUANTITY_MIN_VALUE
    //       : parseInt(item.quantity) > 1000000
    //       ? Constants.PURCHASING_ORDER_QUANTITY_MAX_VALUE
    //       : ""
    //   };
    // });
    // setPerchesOrderData(updatedProdData);
    
    // // Check if there are any validation errors
    // const hasErrors = updatedProdData?.some(item => item.quantityErrors);
    // if (hasErrors) {
    //   return; // Stop submission if there are errors
    // }

    // // Submit the form - this will trigger the onSubmit handler
    // formik.handleSubmit();
    formik.setTouched({
      source_location: true,
      destination_location: true,
      reference_number: true,
      remark: true
    }, true);
  
    // Check form fields in order of appearance
    if (!formik.values.source_location) {
      // Wait for next render cycle
      setTimeout(() => {
        const sourceElement = document.querySelector('.p-dropdown[id="source_location"]');
        if (sourceElement && typeof smoothScrollToElement === 'function') {
          try {
            smoothScrollToElement(sourceElement);
          } catch (error) {
            // Fallback to basic scroll if smooth scroll fails
            sourceElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }, 100); // Small delay to ensure element is rendered
      return;
    }
  
    if (!formik.values.destination_location) {
      // Wait for next render cycle
      setTimeout(() => {
        const sourceElement = document.querySelector('.p-dropdown[id="destination_location"]');
        if (sourceElement && typeof smoothScrollToElement === 'function') {
          try {
            smoothScrollToElement(sourceElement);
          } catch (error) {
            // Fallback to basic scroll if smooth scroll fails
            sourceElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }, 100); // Small delay to ensure element is rendered
      return;
    }
    // Check if products are selected
    if (viewSelectedProducts?.length - 1 <= 0) {
      setSelectedProductError({
        message: Constants.PRODUCT_ADD_REQUIRED
      });
      if (null == formik.values.reference_number || undefined == formik.values.reference_number || '' == formik.values.reference_number || !formik.values.reference_number?.trim()) {
        setTimeout(() => {
          const sourceElement = document.getElementById('reference_number');
          if (sourceElement && typeof smoothScrollToElement === 'function') {
            try {
              smoothScrollToElement(sourceElement);
            } catch (error) {
              // Fallback to basic scroll if smooth scroll fails
              sourceElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }
        }, 100); // Small delay to ensure element is rendered
        return;
      }
      return;
    }
    // Validate quantities
    const updatedProdData = perchesOrderData?.map(item => {
      return {
        ...item,
        quantityErrors: 
          item.quantity === undefined || item.quantity === ""
          ? Constants.PURCHASING_ORDER_SELECT_QUANTITY
          : parseInt(item.quantity) < 1
          ? Constants.PURCHASING_ORDER_QUANTITY_MIN_VALUE
          : parseInt(item.quantity) > 1000000
          ? Constants.PURCHASING_ORDER_QUANTITY_MAX_VALUE
          : ""
      };
    });
    setPerchesOrderData(updatedProdData);
    
    // Find first quantity error and scroll to it
    const firstErrorIndex = updatedProdData?.findIndex(item => item.quantityErrors);
    if (firstErrorIndex !== -1) {
      if (null == formik.values.reference_number || undefined == formik.values.reference_number || '' == formik.values.reference_number || !formik.values.reference_number?.trim()) {
        setTimeout(() => {
          const sourceElement = document.getElementById('reference_number');
          if (sourceElement && typeof smoothScrollToElement === 'function') {
            try {
              smoothScrollToElement(sourceElement);
            } catch (error) {
              // Fallback to basic scroll if smooth scroll fails
              sourceElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }
        }, 100); // Small delay to ensure element is rendered
        return;
      }
      smoothScrollToElement(document.querySelector(`#quantity-${firstErrorIndex}`)); // Add these IDs to your quantity inputs
      return;
    }
    if (null == formik.values.reference_number || undefined == formik.values.reference_number || '' == formik.values.reference_number || !formik.values.reference_number?.trim()) {
      setTimeout(() => {
        const sourceElement = document.getElementById('reference_number');
        if (sourceElement && typeof smoothScrollToElement === 'function') {
          try {
            smoothScrollToElement(sourceElement);
          } catch (error) {
            // Fallback to basic scroll if smooth scroll fails
            sourceElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }, 100); // Small delay to ensure element is rendered
      return;
    }

    // If no errors found, submit the form
    formik.handleSubmit();
  };

  const hadleFromDateSelect = (e) => {
    const selectedDate = e.value;
    formik.setFieldValue("payment_due_date", selectedDate);
    setFromDateView(selectedDate);
  };

  const toDateCalendarRef = useRef(null);
  const [toDateFocus, setToDateFocus] = useState(false);

  const fetchProductData = async () => {
    const data = {
      vendors: selectedVendorData.id,
      locations: selectedLocationSourceData.id,
    };
    setLoading(true);
    try {
      getFetchProduct(data)
        .then((response) => {
          if (response.data.flag === true) {
            setLocationAllProduct(response.data.data.product);
            setLoading(false);
          } else {
            setLocationAllProduct([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLocationAllProduct([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLocationAllProduct([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedVendorData?.id &&
      selectedLocationSourceData?.id &&
      locationRef.current
    ) {
      fetchProductData();
      locationRef.current = false;
    }
    // effectRun.current = true; // Ensures fetchProductData runs only after the initial render
  }, [selectedVendorData, selectedLocationSourceData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
  }, [fromDateFocus, toDateFocus]);

  const onCloseOpenModal = () => {
    props.navigate(Constants.TRANSFER_ORDER_PATH);
  };

  const sortRef = useRef(sortModel);
  return (
    <Box flex="1" pb="3.31rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignitems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            // pass param data in tax list page
            props.navigate(Constants.TRANSFER_ORDER_PATH, {
              state: stateData.state,
            });
          }}
          breadCrumNames={["Transfer Orders", "Create Transfer Order"]}
          breadCrumTitle={"Create Transfer Order"}
        />
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in purchase order list page
              props.navigate(Constants.TRANSFER_ORDER_PATH, {
                state: stateData.state,
              });
            }}
            buttonText={"Cancel"}
            CancelButton={true}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            // isDisabled={
            //   !formik.values.source_location ||
            //   !formik.values.destination_location ||
            //   !formik.values.reference_number ||
            //   formik.errors.source_location ||
            //   // formik.values.locations.length <= 0 ||
            //   formik.errors.source_location ||
            //   formik.errors.destination_location ||
            //   formik.errors.reference_number ||
            //   (viewSelectedProducts.length-1) <= 0 ||
            //   // !perchesOrderData ||
            //   // perchesOrderData.length === 0 ||
            //   perchesOrderData.some(
            //     (item) =>
            //       !item.quantity ||
            //       parseInt(item.quantity) <= 0 ||
            //       item.quantityErrors
            //   ) ||
            //   // perchesOrderData.some(
            //   //   (item) =>
            //   //     item.cost === undefined ||
            //   //     parseFloat(item.cost) < 0 ||
            //   //     item.costErrors
            //   // ) ||
            //   // perchesOrderData.some((item) => item.unitCaseErrors) ||
            //   buttonDisable
            //     ? true
            //     : false
            // }
            isDisabled={buttonDisable}
            buttonsubmit="Submit"
            SubmitButton={true}
            // onClick={formik.handleSubmit}
            onClick={() => {
              handleFormSubmission();
            }}
          />
        </Stack>
      </Flex>
      <>
        {modalOpens && (
          <Modal
            isOpen={modalOpens}
            onClose={onCloseOpenModal}
            isCentered
            size={modalSize}
            // size="4xl"
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent w={{ base: "90%", sm: "70%" }}>
              <ModalHeader mb="0.5rem"></ModalHeader>
              <ModalCloseButton
                top="1.5rem"
                right="1.5rem"
                onClick={onCloseOpenModal}
              />
              <ModalBody>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  textAlign="center"
                  position="relative"
                >
                  <Img
                    src={POCreateImage}
                    alt="Transfer Order"
                    boxSize={{ base: "20rem", md: "30rem", lg: "31rem" }}
                  />
                  <Box
                    color={Colors.posTextInfo}
                    pb="2rem"
                    fontSize="1.13rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.01rem"
                  >
                    Transfer Order successfully created
                  </Box>
                  {/* <Flex gap="2" flexWrap="wrap" justifyContent="center">
                    <PosFormButton
                      buttonsubmit={"Download PDF"}
                      width={{ base: "100%", sm: "9.25rem" }}
                      onClick={handlePdfReport}
                    />
                    <PosFormButton
                      buttonsubmit={"Download Excel"}
                      width={{ base: "100%", sm: "9.25rem" }}
                      onClick={handleExportReport}
                    />
                    <PosFormButton
                      buttonsubmit={"Create A New PO"}
                      width={{ base: "100%", sm: "9.25rem" }}
                      onClick={handleResetAllData}
                    />
                    <PosFormButton
                      buttonsubmit={"View All PO"}
                      width={{ base: "100%", sm: "9.25rem" }}
                      onClick={() => {
                        setDisableButton(true);
                        props.navigate(Constants.TRANSFER_ORDER_PATH);
                      }}
                      isDisabled={disableButton}
                    />
                  </Flex> */}
                </Box>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </>
      <Box
        mt={{ base: 2, lg: "2rem" }}
        ml={{ base: 4, lg: "3.44rem" }}
        mr={{ base: 4, lg: "2.75rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
      >
        <Flex
          fontSize="1.23rem"
          color={Colors.posTextInfo}
          ml={{ base: "0", md: "1.1rem" }}
          pt={{ base: "2rem", md: "2rem", lg: "3rem" }}
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.13rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
          }}
        >
          <Box
            backgroundColor={Colors.modifierTable}
            ml={{ base: "1rem" }}
            mt={{ base: "1rem" }}
            style={{
              width: "2.13rem",
              height: "2.13rem",
              flexShrink: 0,
              marginRight: "0.63rem",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Img
              src={invoice}
              alt="Transfer Order Details"
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </Box>
          <Text mt={{ base: "1rem" }}>Transfer Order Details</Text>
        </Flex>
        <Divider mt={"0.56rem"} />
        <Flex
          direction={{ base: "column", md: "column", lg: "row" }}
          p={{ base: "0.3rem", md: "6", lg: "6" }}
          pl={{ base: "5.7" }}
        >
          <Box
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            justifyContent={{ base: "flex-start", md: "center" }}
          >
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                display="flex"
                pr={{ base: "1rem" }}
                mb={{ base: "0.5rem", md: "0" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Source Location
                <Text as="span" color={Colors.errorColor} ml="0.2rem">
                  *
                </Text>
                <Tippy
                  content="Select the Source Location for which you want to transfer products from."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    ml={{ base: "0.4rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Box>
              <Box>
              <PosDropDown
                  id="source_location"
                  value={selectedLocationSourceData}
                  onChange={onSelectSourceLocation}
                  onBlur={(e) => {
                    formik.setFieldTouched("source_location", true);
                    formik.handleBlur(e);
                  }}
                  options={locationSourceData}
                  lableAvailable={true}
                  optionLabel="name"
                  className="w-full md:w-20rem"
                  placeholder="--Select a Source Location--"
                />
                <Text color={Colors.errorColor} textAlign="end">
                  {formik.touched.source_location && formik.errors.source_location ? (
                    <span>{formik.errors.source_location}</span>
                  ) : null}
                </Text>
              </Box>
            </Flex>
            {/* Destination Location */}
            {undefined != selectedLocationSourceData.id && null != selectedLocationSourceData.id && '' != selectedLocationSourceData.id > 0 && (
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                display="flex"
                pr={{ base: "1rem" }}
                mb={{ base: "0.5rem", md: "0" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Destination Location
                <Text as="span" color={Colors.errorColor} ml="0.2rem">
                  *
                </Text>
              </Box>
              <Box>
                <PosDropDown
                  id="destination_location"
                  value={selectedLocationDestinationData}
                  onChange={onSelectDestinationLocation}
                  onBlur={(e) => {
                    formik.setFieldTouched("destination_location", true);
                    formik.handleBlur(e);
                  }}
                  options={locationDestinationData}
                  lableAvailable={true}
                  optionLabel="name"
                  className="w-full md:w-20rem"
                  placeholder="--Select a Destination Location--"
                />
                <Text color={Colors.errorColor} textAlign="end">
                  {formik.touched.destination_location && formik.errors.destination_location ? (
                    <span>{formik.errors.destination_location}</span>
                  ) : null}
                </Text>
              </Box>
            </Flex>)}
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                flexShrink={0}
                display="flex"
                pr={{ base: "1rem" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Reference Number
                <Text as="span" color={Colors.errorColor} ml="0.2rem">
                  *
                </Text>
                <Tippy
                  content="This Reference number helps in accurate tracking of the Transfer order to the Incoive."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    ml={{ base: "0.4rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Box>
              <Box>
              <PosInput
                id="reference_number"
                mt={{ base: "1rem" }}
                placeholder={"Enter a Reference Number"}
                color={Colors.posCommon}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.reference_number || ""}
                handleBlur={(e) => {
                  formik.setFieldValue("reference_number", formik.values.reference_number.trim());
                  formik.handleBlur(e);
                }}
                // onKeyDown={handleKeyDown}
                posInput={true}
                width={{ base: "17.38rem", md: "17.38rem", lg: "100%" }}
                inputType={"text"}
              />
              <Text color={Colors.errorColor} textAlign="end">
                {formik.touched.reference_number && formik.errors.reference_number ? (
                  <span>{formik.errors.reference_number}</span>
                ) : null}
              </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            justifyContent="flex-start"
            ml={{
              base: "0",
              sm: "0",
              md: "0",
              lg: "1.75rem",
              xl: "7.75rem",
            }}
          >
            <Flex p={2.5}>
            <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                flexShrink={0}
                display="flex"
                pr={{ base: "1rem" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Remark
              </Box>
              <PosInput
                id="remark"
                mt={{ base: "1rem" }}
                placeholder={"Enter a Remark"}
                color={Colors.posCommon}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.remark}
                handleBlur={(e) => {
                  formik.setFieldValue("remark", formik.values.remark.trim());
                  formik.handleBlur(e);
                }}
                // onKeyDown={handleKeyDown}
                posInput={true}
                width={{ base: "17.38rem", md: "17.38rem", lg: "100%" }}
                inputType={"text"}
              />
            </Flex>
          </Box>
        </Flex>
        <Divider />
        <Flex
          direction={{ base: "column", md: "column", lg: "row" }}
          alignitems={{ base: "start" }}
          pl={{ base: "1.81rem" }}
        >
          {/* <Box
            color={Colors.primeposTextInfo}
            fontSize="0.94rem"
            fontStyle="normal"
            fontWeight={300}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          >
            <Flex gap={2} mt={{ base: "1rem" }} mr={{ base: "1.7rem" }}>
              <Box>
              <AutoComplete
                id="selectedProducts"
                field="name"
                value={autoSelectedProducts}
                suggestions={filteredProducts}
                completeMethod={search}
                placeholder="Enter Barcode"
                onChange={(e) => {
                  setAutoSelectedProducts(e.value);

                }}
                disabled={
                  !formik.values.vendors ||
                  !formik.values.locations ||
                  formik.values.vendors.length <= 0 ||
                  formik.errors.vendors || locationAllProducts.length == 0 ||
                  // formik.values.locations.length <= 0 ||
                  formik.errors.locations ||
                  buttonDisable
                    ? true
                    : false
                }
                showEmptyMessage={true}
                emptyMessage="There is no product."
                onSelect={(e) => {
                  if(undefined != e.value && null != e.value && '' != e.value){
                    autoCompleteSelectedProducts(e.value);
                  }
                }}
                dropdown
              />
              </Box>
            </Flex>
          </Box> */}
          <Spacer />
          <Box
            mb={{ base: "1rem" }}
            mr={{ md: "3.56rem" }}
            mt={{ base: "1rem" }}
          >
            <PosFormButton
              buttonsubmit={"Add Multiple Products"}
              width={"15.25rem"}
              // onClick={() => {
              //   fetchRef.current = true
              //   setModal(true);
              //   selectedProdQtyRef.current = perchesOrderData;
              //   oldselectedRef.current = selectedProductRef.current
              //   // setAddProductClicked(true);
              //   // fetchData();
              // }}
              onClick={() => {
                if(undefined == formik.values.source_location || null == formik.values.source_location || '' == formik.values.source_location || formik.values.source_location?.length <= 0 || undefined == formik.values.destination_location || null == formik.values.destination_location || '' == formik.values.destination_location || formik.values.destination_location?.length <= 0){
                    formik.setTouched({
                      source_location: true,
                      destination_location: true,
                      reference_number: true,
                      remark: true
                    }, true);
                    return;
                }else{
                  setVisible(true);
                  openDialog();
                }
              }}
              // isDisabled={
              //   !formik.values.source_location ||
              //   !formik.values.destination_location ||
              //   !formik.values.reference_number ||
              //   formik.values.source_location.length <= 0 ||
              //   formik.errors.source_location ||
              //   // formik.values.locations.length <= 0 ||
              //   formik.errors.source_location ||
              //   formik.errors.destination_location ||
              //   formik.errors.reference_number ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              isDisabled={buttonDisable}
            />
          </Box>
          <>
            {/* {modalOpen && ( */}
              {/* <ThemeProvider theme={Mtheme}> */}
                <Dialog
                  header={<div style={{ padding: 0, margin: 0 }}> </div>}
                  visible={visible}
                  onHide={onHideDialog}
                  style={{ width: isMobileDialog ? "80%" : "70%" }}
                  closable={false}
                  closeOnEscape={false}
                >
                  <ChakraProvider theme={theme}>
                  <Box
                    position="sticky"
                    top="0"
                    zIndex="1000"
                    backgroundColor="white"
                    pb={2}
                  >
                  <Flex
                      minWidth="max-content"
                      gap="2"
                      mt={4}
                      mb={4}
                      alignItems="center"
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <Flex alignSelf="flex-start" fontSize="1.5rem" fontWeight={600} color="rgb(1, 0, 72)">
                        Select Products
                      </Flex>
                      <Spacer />
                      <Flex flexDirection="column" alignItems="flex-end">
                      <ButtonGroup alignSelf="flex-end">
                        <PosFormButton
                          onClick={modalClose}
                          buttonText="Cancel"
                          CancelButton={true}
                          isDisabled={buttonDisable}
                        />
                        <PosFormButton
                          // isDisabled={
                          //   checkedItems.length <= 0 ||
                          //   formik.errors.product ||
                          //   modifiedData.length === 0 ||
                          //   (isChecked && perchesOrderData.length === 0) ||
                          //   buttonDisable
                          // }
                          // isDisabled={
                          //   checkedItems.length <= 0 ||
                          //   (checkedItems.length === 1 && checkedItems[0] === "") ||
                          //   (isChecked && perchesOrderData.length === 0) ||
                          //   buttonDisable
                          // }
                          isDisabled={buttonDisable}
                          buttonsubmit="Add Products"
                          SubmitButton={true}
                          onClick={handleSelectedViewProduct}
                        />
                      </ButtonGroup>
                      {/* {selectProductFromDialogError && (checkedItems?.length <= 0 ||
                      (checkedItems?.length == 1 && checkedItems[0] == "") ||
                      (isChecked && selectedProductData?.length === 0)) && (
                      <Text color={Colors.errorColor} alignSelf={"flex-end"}>
                        {selectProductFromDialogError.message}
                      </Text>
                    )} */}
                  </Flex>
                    </Flex>
                    <Flex alignItems="center">
                    </Flex>
                    <Flex
                      // minWidth="max-content"
                      gap="2"
                      mt={4}
                      mb={4}
                      // alignItems="center"
                      flexDirection={{ base: "column", sm: "column", md: "row" }}
                    >
                      {/* {modifiedData.length > 0 && ( */}
                        {/* <> */}
                      <PosSearch
                      value={searchQuery}
                      // onChange={(e) => {
                      //   let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
                      //   setSearchQuery(value);
                      // }}
                      onChange={onChange}
                      onSearch={handleSearchList}
                      handleClear={handleClear}
                    />
                    {/* <Spacer /> */}
                    <PosDropDown
                      options={categories}
                      value={selectedCategories}
                      onChange={(e) => {
                        clickIfapplyCat(e.value);
                      }}
                      // onChange={(e) => setSelectedCategories(e.value)}
                      multiSelect={true}
                      optionLabel="label"
                      placeholder="Categories"
                      lableAvailable={true}
                      width="100%"
                      height="2.5rem"
                      className="w-full md:w-20rem"
                      maxSelectedLabels={2}
                    />
                    <Spacer />
                    {/* </> */}
                      {/* // )} */}
                      {/* <Tippy
                        content="Looks for items below the threshold and adds them to the purchase list."
                        interactive={true}
                        maxWidth={390}
                        placement="top"
                        animation="fade"
                        inertia={true}
                        moveTransition="transform 0.2s ease-out"
                        theme="tomato"
                      >
                        <Image
                          src={toolTip}
                          alt="Tooltip"
                          mr={{ base: "0.4rem", md: "0.3rem" }}
                        />
                      </Tippy>
                      <Text mt={2}>
                      Low Threshold Products:
                      </Text>
                      <ChakraProvider theme={theme}>
                        <Box
                          mt={2}
                          ml={{ base: "0.5rem", md: "0.5rem" }}
                          pr={{ base: "0.5rem", md: "0.5rem" }}
                        >
                          <PosSwitchButton
                            id="isChecked"
                            onChange={handleSwitchChange}
                            switchValue={isChecked}
                            disabled={isAllProductsChecked}
                            // onClick={() => {
                            //   setThresholdProductClicked(true);
                            // }}
                          />
                        </Box>
                      </ChakraProvider>
                      <Text mt={2}>
                        Vendor's Products:
                      </Text>
                      <ChakraProvider theme={theme}>
                        <Box
                          mt={2}
                          mb={4}
                          ml={{ base: "0.5rem", md: "0.5rem" }}
                          pr={{ base: "0.5rem", md: "0.5rem" }}
                        >
                          <PosSwitchButton
                            id="isAllProductsChecked"
                            onChange={handleAllProductSwitchChange}
                            switchValue={isAllProductsChecked}
                            disabled={isChecked}
                          />
                        </Box>
                      </ChakraProvider> */}
                    </Flex>
                    {/* <ChakraProvider theme={theme}> */}
                      {/* <Box mt={3.5}>
                        <PosSearch
                          // mb={3.5}
                          value={searchQuery}
                          onChange={(e) => {
                            let value =
                              searchQuery.length > 0
                                ? e.target.value
                                : e.target.value.trim();
                            setSearchQuery(value);
                          }}
                          onSearch={handleSearchList}
                          handleClear={handleClear}
                        />
                        <PosDropDown
                        options={categories}
                        value={selectedCategories}
                        onChange={(e) => {
                          clickIfapplyCat(e.value);
                        }}
                        // onChange={(e) => setSelectedCategories(e.value)}
                        multiSelect={true}
                        optionLabel="label"
                        placeholder="Categories"
                        lableAvailable={true}
                        width="100%"
                        height="2.5rem"
                        className="w-full md:w-20rem"
                        maxSelectedLabels={2}
                      />
                      </Box> */}
                    {/* </ChakraProvider> */}
                  {/* </DialogTitle> */}
                  {/* <DialogContent> */}
                    {showTable ? null : (
                      <>
                        <Box mb={{ base: "0.88rem", md: "0.88rem" }}>
                          {/* {productData.length >= 1 ? (
                            !isChecked &&
                            checkedItems.length >= 0 &&
                            locationProductFLag == 1 && (modifiedData.length > 0 && !isAllProductsChecked)  ? (
                            <>
                              <Flex>
                              <Text
                                mt={{ base: 0, md: "0.30rem" }}
                                style={{
                                  fontSize: "1.13rem",
                                  color: "#008000",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                              >
                                We are displaying all the products for this
                                location.
                              </Text>
                              </Flex>
                              <Flex
                              color={Colors.primeposTextInfo}
                              style={{
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: "300",
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                marginRight: "1rem",
                              }}
                              pt={"0.88rem"}
                              mr={{ base: "18rem", md: "0.63rem" }}
                            >
                            </Flex>
                            </>
                            ) : (
                              (modifiedData.length === 0 && isAllProductsChecked) ? <PosNoDataFound />
                              : (totalcount > 0 && locationAllProducts == 0 && (<Text
                                mt={{ base: 0, md: "0.88rem" }}
                                style={{
                                  fontSize: "1.13rem",
                                  color: "#008000",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                              >
                                We are displaying all the vendor's products.
                              </Text>))
                            )
                          ) : null} */}
                        </Box>
                        {loading ? (
                          <PosProgress />
                        ) : isChecked ? (
                          modifiedData.length === 0 &&
                          locationProductFLag == 1 ? (
                            <PosNoDataFound title={"No more products found."}  />
                          ) : (
                            <Box
                              sx={{
                                overflowX: "auto",
                                maxHeight: "30.91rem",
                                maxWidth: "100%",
                                "& .Product Name": {
                                  backgroundColor: Colors.loginFooter,
                                },
                              }}
                            >
                              {(modifiedData.length === 0 && isAllProductsChecked)? (
                                <PosNoDataFound
                                title={"No more products found."} 
                                />
                              ) : (
                                <>
                                {selectProductFromDialogError && (checkedItems?.length <= 0 ||
                                  (checkedItems?.length == 1 && checkedItems[0] == "") ||
                                  (isChecked && selectedProductData?.length === 0)) && (
                                  <Text mb={2} color={Colors.errorColor} alignSelf={"flex-end"}>
                                    {selectProductFromDialogError.message}
                                  </Text>
                                )}
                                <PosDataGridTable
                                  columns={productColumnNames}
                                  rows={modifiedData}
                                  totalcount={totalcount}
                                  columnVisibilityModel={
                                    olColumnVisibilityModel
                                  }
                                  onColumnVisibilityModelChange={(newModel) =>
                                    setOlColumnVisibilityModel(newModel)
                                  }
                                  paginationModel={paginationModel}
                                  paginationMode="server"
                                  sortingMode="server"
                                  onPaginationModelChange={(
                                    newPaginationModel
                                  ) => {
                                    fetchRef.current = true;
                                    setPaginationModel(newPaginationModel);
                                  }}
                                  slots={{
                                    toolbar: GridToolbar,
                                  }}
                                  sortModel={sortModel}
                                  onSortModelChange={(newSortModel) => {
                                    sortRef.current = sortModel;
                                    fetchRef.current = true;
                                    setSortModel(newSortModel);
                                  }}
                                  fileName={"POLowthresholdProducts"}
                                />
                                </>
                              )}
                            </Box>
                          )
                        ) : (modifiedData.length === 0 && isAllProductsChecked) ? (
                          <PosNoDataFound title={"No more products found."}  />
                        ) : (
                          <Box
                            sx={{
                              overflowX: "auto",
                              maxHeight: "30.91rem",
                              maxWidth: "100%",
                              "& .Product Name": {
                                backgroundColor: Colors.loginFooter,
                              },
                            }}
                          >
                            {selectProductFromDialogError && (checkedItems?.length <= 0 ||
                              (checkedItems?.length == 1 && checkedItems[0] == "") ||
                              (isChecked && selectedProductData?.length === 0)) && (
                              <Text mb={2} color={Colors.errorColor} alignSelf={"flex-end"}>
                                {selectProductFromDialogError.message}
                              </Text>
                            )}
                            <PosDataGridTable
                              columns={productColumnNames}
                              rows={modifiedData}
                              totalcount={totalcount}
                              columnVisibilityModel={olColumnVisibilityModel}
                              onColumnVisibilityModelChange={(newModel) =>
                                setOlColumnVisibilityModel(newModel)
                              }
                              slots={{
                                toolbar: GridToolbar,
                              }}
                              paginationModel={paginationModel}
                              paginationMode="server"
                              sortingMode="server"
                              onPaginationModelChange={(newPaginationModel) => {
                                fetchRef.current = true;
                                setPaginationModel(newPaginationModel);
                              }}
                              sortModel={sortModel}
                              onSortModelChange={(newSortModel) => {
                                sortRef.current = sortModel;
                                fetchRef.current = true;
                                setSortModel(newSortModel);
                              }}
                              fileName={"POProducts"}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  </ChakraProvider>
                  {/* <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: Colors.posTextInfo,
                    }}
                  > */}
                    {/* <Flex alignItems="center">
                      <Text
                        pt={"2.31rem"}
                        pr={{ base: "2rem", md: "10rem" }}
                        style={{ flex: 1 }}
                      >
                        Select Products
                      </Text>
                      <Spacer />
                      <Flex
                        color={Colors.primeposTextInfo}
                        style={{
                          fontSize: "0.94rem",
                          fontStyle: "normal",
                          fontWeight: "300",
                          lineHeight: "normal",
                          letterSpacing: "-0.01rem",
                          marginRight: "1rem",
                        }}
                        pt={"2.31rem"}
                        mr={{ base: "18rem", md: "0.63rem" }}
                      >
                        <Tippy
                          content="Looks for items below the threshold and adds them to the purchase list."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mr={{ base: "0.4rem", md: "0.3rem" }}
                          />
                        </Tippy>
                        Low Threshold Products:
                        <ChakraProvider theme={theme}>
                          <Box
                            ml={{ base: "0.5rem", md: "0.5rem" }}
                            pr={{ base: "0.5rem", md: "0.5rem" }}
                          >
                            <PosSwitchButton
                              id="isChecked"
                              onChange={handleSwitchChange}
                              switchValue={isChecked}
                            />
                          </Box>
                        </ChakraProvider>
                      </Flex>
                    </Flex> */}
                    {/* <ChakraProvider theme={theme}>
                      <Box mt={3.5}>
                        <PosSearch
                          // mb={3.5}
                          value={searchQuery}
                          onChange={(e) => {
                            let value =
                              searchQuery.length > 0
                                ? e.target.value
                                : e.target.value.trim();
                            setSearchQuery(value);
                          }}
                          onSearch={handleSearchList}
                          handleClear={handleClear}
                        />
                      </Box>
                    </ChakraProvider> */}
                  {/* </DialogTitle> */}
                  {/* <DialogContent> */}
                    {/* {showTable ? null : ( */}
                      {/* <> */}
                        {/* <Box mb={{ base: "0.88rem", md: "0.88rem" }}> */}
                          {/* <PosSearch
                            value={searchQuery}
                            onChange={(e) => {
                              let value =
                                searchQuery.length > 0
                                  ? e.target.value
                                  : e.target.value.trim();
                              setSearchQuery(value);
                            }}
                            onSearch={handleSearchList}
                            handleClear={handleClear}
                          /> */}
                          {/* {productData.length >= 1 ? (
                            !isChecked &&
                            checkedItems.length >= 0 &&
                            locationProductFLag == 1 ? (
                              <Text
                                mt={{ base: 0, md: "0.88rem" }}
                                style={{
                                  fontSize: "1.13rem",
                                  color: "#008000",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                              >
                                Product is not associated with selected vendor.
                                We are displaying all the products for this
                                location.
                              </Text>
                            ) : (
                              modifiedData.length === 0 && <PosNoDataFound />
                            )
                          ) : null}
                        </Box>
                        {loading ? (
                          <PosProgress />
                        ) : isChecked ? (
                          locationProductFLag == 1 ? (
                            <PosNoDataFound title={"No more products found."} />
                          ) : (
                            <Box
                              sx={{
                                overflowX: "auto",
                                maxHeight: "30.91rem",
                                maxWidth: "70.75rem",
                                "& .Product Name": {
                                  backgroundColor: Colors.loginFooter,
                                },
                              }}
                            >
                              {modifiedData.length === 0 ? (
                                <PosNoDataFound
                                  title={"No more products found."}
                                />
                              ) : (
                                <PosDataGridTable
                                  columns={productColumnNames}
                                  rows={modifiedData}
                                  totalcount={totalcount}
                                  columnVisibilityModel={
                                    olColumnVisibilityModel
                                  }
                                  onColumnVisibilityModelChange={(newModel) =>
                                    setOlColumnVisibilityModel(newModel)
                                  }
                                  paginationModel={paginationModel}
                                  paginationMode="server"
                                  sortingMode="server"
                                  onPaginationModelChange={(
                                    newPaginationModel
                                  ) => {
                                    fetchRef.current = true;
                                    setPaginationModel(newPaginationModel);
                                  }}
                                  slots={{
                                    toolbar: GridToolbar,
                                  }}
                                  sortModel={sortModel}
                                  onSortModelChange={(newSortModel) => {
                                    sortRef.current = sortModel;
                                    fetchRef.current = true;
                                    setSortModel(newSortModel);
                                  }}
                                  fileName={"POLowthresholdProducts"}
                                />
                              )}
                            </Box>
                          )
                        ) : modifiedData.length === 0 ? (
                          <PosNoDataFound title={"No more products found."} />
                        ) : (
                          <Box
                            sx={{
                              overflowX: "auto",
                              maxHeight: "30.91rem",
                              maxWidth: "70.75rem",
                              "& .Product Name": {
                                backgroundColor: Colors.loginFooter,
                              },
                            }}
                          >
                            <PosDataGridTable
                              columns={productColumnNames}
                              rows={modifiedData}
                              totalcount={totalcount}
                              columnVisibilityModel={olColumnVisibilityModel}
                              onColumnVisibilityModelChange={(newModel) =>
                                setOlColumnVisibilityModel(newModel)
                              }
                              slots={{
                                toolbar: GridToolbar,
                              }}
                              paginationModel={paginationModel}
                              paginationMode="server"
                              sortingMode="server"
                              onPaginationModelChange={(newPaginationModel) => {
                                fetchRef.current = true;
                                setPaginationModel(newPaginationModel);
                              }}
                              sortModel={sortModel}
                              onSortModelChange={(newSortModel) => {
                                sortRef.current = sortModel;
                                fetchRef.current = true;
                                setSortModel(newSortModel);
                              }}
                              fileName={"POProducts"}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </DialogContent> */}
                  {/* <DialogActions>
                    <ChakraProvider theme={theme}>
                      <Flex minWidth="max-content" gap="2" mt={4} mb={4}>
                        <Spacer />
                        <ButtonGroup>
                          <PosFormButton
                            onClick={handleCloseModal}
                            buttonText={"Cancel"}
                            CancelButton={true}
                            isDisabled={buttonDisable}
                          />
                          <PosFormButton
                            isDisabled={
                              checkedItems.length <= 0 ||
                              formik.errors.product ||
                              (isChecked && perchesOrderData.length === 0) ||
                              buttonDisable
                            }
                            buttonsubmit={"Add Product"}
                            SubmitButton={true}
                            onClick={handleSelectedViewProduct}
                          />
                        </ButtonGroup>
                      </Flex>
                    </ChakraProvider>
                  </DialogActions> */}
                </Dialog>
              {/* </ThemeProvider> */}
            {/* )} */}
          </>
        </Flex>
        {selectedProductError && viewSelectedProducts?.length - 1 <= 0 && selectedProductData?.length === 0 && (
          <Text ml={"1rem"} color={Colors.errorColor} alignSelf={"flex-end"}>
            {selectedProductError.message}
          </Text>
        )}
        {!formik.values.apply_on_all_prods &&
          viewSelectedProducts &&
          viewSelectedProducts.length > 0 && (
            <Box
              borderRadius="0.63rem"
              mt={3}
              bg={Colors.posPageDataBackground}
              boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflowX: "auto",
              }}
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead
                    alignitems="start"
                    sx={{ backgroundColor: Colors.modifierTable }}
                  >
                    <TableRow>
                      {columnProductViewNames.map((column, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontWeight: 500,
                            fontSize: "0.94rem",
                            lineHeight: "1.18rem",
                            letterSpacing: "-0.01rem",
                            fontStyle: "normal",
                            textAlign: column.textAlign || "center",
                            maxHeight: "42rem",
                            color:"#010048",
                          }}
                        >
                          <Flex flexDirection={"row"}>
                          {column.columnNames}
                          {column.columnNames=='Cost/Case($)' || column.columnNames=='Units/Case' ? (<Tippy
                            content={column.columnNames=='Cost/Case($)' ? 'This will your cost per case. For example, $50 for Kellogs Cornflakes 300Gm X 20':'Please enter the quantity of individual units per case. For example, for Kellogs Cornflakes 300Gm X 20, you should enter 20.'}
                            interactive={true}
                            maxWidth={300}
                            placement="top"
                            animation="fade"
                            inertia={true}
                            moveTransition="transform 0.2s ease-out"
                            theme="tomato"
                          >
                            <Image
                              src={toolTip}
                              alt="Tooltip"
                              ml={{ base: "0.3rem", md: "0.3rem" }}
                            />
                          </Tippy>): null}
                          </Flex>
                          {column.sortName.length > 0 ? (
                            <TableSortLabel
                              active={
                                column.sortName === columnProductViewNames
                              }
                              direction={
                                column.sortName === columnProductViewNames
                                  ? sortType
                                  : "asc"
                              }
                              onClick={() => handleColumn(column)}
                            />
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modifiedViewDatas.map((row,i) => {
                      let index = perchesOrderData.findIndex(
                        (x) => x.id === row.id
                      );
                      return (
                          <TableRow
                          key={i}
                          // sx={{
                          //   "&:last-child td, &:last-child th": { border: 0 },
                          // }}
                        >
                          <TableCell>                 
                          {index === -1 ? (
                            <Flex flexDirection={"row"}>
                            <Search2Icon mt={3} mr={3} />
                            <ThemeProvider theme={Mtheme}>
                            <Autocomplete
                              size="small"
                              filterOptions={(x) => x}
                              id="autoselect"
                              disabled={
                                !formik.values.source_location ||
                                !formik.values.destination_location ||
                                !formik.values.reference_number ||
                                formik.values.source_location.length <= 0 ||
                                formik.errors.source_location ||
                                // formik.values.locations.length <= 0 ||
                                formik.errors.source_location ||
                                formik.errors.destination_location ||
                                formik.errors.reference_number ||
                                buttonDisable
                                  ? true
                                  : false
                              }
                              sx={{ width: 300 }}
                              open={open}
                              onOpen={() => {
                                setOpen(true);
                              }}
                              onClose={() => {
                                setOpen(false);
                              }}
                              value={autoSelectedProducts}
                              onChange={(event, newValue) => {
                                // setAutoSelectedProducts(newValue);
                                autoCompleteSelectedProducts(newValue);
                                // setInputValueAuto(newValue);
                              }}
                              inputValue={inputValueAuto}
                              onInputChange={(event, newInputValue) => {
                                setOptions([]);
                                setInputValueAuto(newInputValue);
                              }}
                              // isOptionEqualToValue={(option, value) => option.barcode === value?.barcode}
                              getOptionLabel={(option) => option?.name || ""}
                              options={options}
                              loading={loading1}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            </ThemeProvider>
                          </Flex>
                          ): (
                            <VStack align="start">
                              <Box
                                style={{
                                  fontSize: "0.94rem",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                  color:"#010048"
                                }}
                              >
                                {row.name}
                              </Box>
                              {/* <Flex>
                                <Box
                                  color={Colors.taxCreateExemptEbt}
                                  style={{
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  Item Code:
                                </Box>
                                <Box ml={2}>{row.item_code || "N.A"}</Box>
                              </Flex> */}
                              {/* <Flex>
                                <Box
                                  color={Colors.taxCreateExemptEbt}
                                  style={{
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  Primary Barcode:
                                </Box>
                                <Box ml={2}>{row.barcode}</Box>
                              </Flex> */}
                            </VStack>)}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {row.barcode || "N.A"}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {null != row.sale_price && '' != row.sale_price ? "$"+row.sale_price : "N.A."}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {row.in_stock_qty}
                          </TableCell>
                          {/* <TableCell align="center">
                            <Input
                              id="cost"
                              ref={(el) => (inputRefs.current[i] = el)}
                              variant={"posBasicInput"}
                              htmlSize={4}
                              width="5rem"
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) =>
                                handleInputChange(e, row.id, "cost")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "cost");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].cost
                                  : row.cost) || ""
                              }
                              type="number"
                              isInvalid={
                                perchesOrderData[index]?.costErrors?.length > 0
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {perchesOrderData[index]?.costErrors?.length >
                              0 ? (
                                <span>
                                  {perchesOrderData[index]?.costErrors}
                                </span>
                              ) : null}
                            </Text>
                          </TableCell> */}
                          <TableCell>
                            <Input
                              id={`quantity-${i}`}
                              disabled={modifiedViewDatas.length-1 == i}
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "quantity")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = (originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue;
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "quantity");
                              }}
                              onKeyDown={handleKeyDown}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].quantity
                                  : row.quantity) || ""
                              }
                              onKeyPress={(e) => {
                              if (e.key === "-" || e.key === "." || e.key === "e") { // Prevent non-integer characters
                                  e.preventDefault();
                                }
                              }}
                              type="number"
                              isInvalid={
                                perchesOrderData[index]?.quantityErrors
                                  ?.length > 0
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {perchesOrderData[index]?.quantityErrors?.length >
                              0 ? (
                                <span>
                                  {perchesOrderData[index]?.quantityErrors}
                                </span>
                              ) : null}
                            </Text>
                          </TableCell>
                          {/* <TableCell align="center">
                            <Input
                              id="unit_case"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "unit_case")
                              }
                              disabled={modifiedViewDatas.length-1 == i}
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "unit_case");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].unit_case
                                  : row.unit_case) || ""
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="number"
                              isInvalid={
                                perchesOrderData[index]?.unitCaseErrors?.length > 0
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {perchesOrderData[index]?.unitCaseErrors?.length >
                              0 ? (
                                <span>
                                  {perchesOrderData[index]?.unitCaseErrors}
                                </span>
                              ) : null}
                            </Text>
                          </TableCell> */}
                          {/* <TableCell align="center">
                            <Input
                              disabled={true}
                              id="total_units"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "total_units")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "total_units");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].total_unit
                                  : 'N.A.') || "N.A."
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                            />
                          </TableCell> */}
                          {/* <TableCell align="center">
                            <Input
                              disabled={true}
                              id="cost_per_unit"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "cost_per_unit")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "cost_per_unit");
                              }}
                              value={
                                (index > -1
                                  ? (perchesOrderData[index].cost_per_unit)
                                  : 'N.A.') || "N.A."
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                            />
                          </TableCell> */}
                          {/* <TableCell align="center">
                            <Box position="relative" display="inline-block">
                              <Select
                                style={{
                                  width: "5.2rem",
                                  padding: "0.63rem",
                                  backgroundColor: "white",
                                  border: "0.6px solid #01004833",
                                  borderRadius: "0.44rem",
                                  textAlign: "start",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.63rem",
                                  flexShrink: 0,
                                  color:"#010048",
                                  fontSize: "0.94rem",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                                appearance="none"
                                value={
                                  index > -1
                                    ? perchesOrderData[index].quantityUnit
                                    : row.quantityUnit
                                }
                                onChange={(e) =>
                                  handleInputChange(e, row.id, "quantityUnit")
                                }
                                onBlur={(e) =>
                                  handleInputChange(e, row.id, "quantityUnit")
                                }
                              >
                                <option value="CAS">Case</option>
                                <option value="PLT">Pallet</option>
                                <option value="EAC">Each</option>
                              </Select>
                            </Box>
                          </TableCell> */}
                          {perchesOrderData[index] && undefined != perchesOrderData[index].unit_of_measures && perchesOrderData[index].unit_of_measures.length>0 ? (
                            <TableCell align="left">
                              <Box position="relative" display="inline-block">
                                <Select
                                  style={{
                                    width: "8rem",
                                    padding: "0.63rem",
                                    backgroundColor: "white",
                                    border: "0.6px solid #01004833",
                                    borderRadius: "0.44rem",
                                    textAlign: "start",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.63rem",
                                    flexShrink: 0,
                                    color: "#010048",
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                  disabled={formik.values.status == 'RECE'}
                                  appearance="none"
                                  value={
                                    index > -1 ? perchesOrderData[index].measureUnit : row.measureUnit
                                  }
                                  onChange={(e) => handleInputChange(e, row.id, "measureUnit")}
                                  onBlur={(e) => handleInputChange(e, row.id, "measureUnit")}
                                >
                                  {perchesOrderData[index].unit_of_measures.map((data) => (
                                    <option key={data.id} value={data.id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </Select>
                              </Box>
                            </TableCell>
                          ):(
                            <TableCell align="left">
                              <Box position="relative" display="inline-block">
                              <Input
                              disabled={true}
                              id="measureUnit"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "measureUnit")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              value={"N.A."}
                              type="text"
                            />
                            </Box>
                            </TableCell>
                          )}
                          <TableCell>
                            {index == -1 ? (
                              "-"
                            ) : 
                            (<DeleteIcon
                              color={Colors.posCancleButtonMuiRed}
                              style={{
                                width: "1.13rem",
                                height: "1.13rem",
                                flexShrink: 0,
                                cursor:"pointer"
                              }}
                              onClick={() => handleSingleDelete(row.id)}
                            />)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        <Box pb={8}></Box>
      </Box>
    </Box>
  );
};
export default WithRouter(TransferOrderCreate);
