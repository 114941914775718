import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { ThemeProvider } from "@mui/material/styles";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  CustomAutocomplete,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { Mtheme } from "../../../../../theme/index";
import { getRewardCustomerList } from "./CustomerPurchaseHistoryService";

const CUSTOMER_PURCHASE_HISTORY = (props) => {
  // Hooks
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();

  // States
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [category, setCategory] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [applyClicked, setApplyClicked] = useState(false);
  const [inputValueAuto, setInputValueAuto] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([]);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // id: false,
  });
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  // Refs
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const pageRef = useRef(false);
  const autoCompleteActive = useRef(true);
  const selectAllRef = useRef(false);
  const customerRef = useRef([]);
  const checkRef = useRef(false);
  const autoCompleteRef = useRef(null);

  // Media Query
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1600px)");
  const [isLarge] = useMediaQuery("(min-width: 1455px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1296px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isSmall] = useMediaQuery("(max-width: 1032px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");

  // Column
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 150 } : { flex: 0.7 };
  const actionColumnFlexDouble = isMobile ? { width: 200 } : { flex: 1.2 };
  const isLoadingOptions = open && options.length === 0;
  let counter = 1;

  const columnNames = [
    // { field: "sr_no", headerName: "Sr No.", sortable: false, width: 90 },
    // {
    //   field: "id",
    //   headerName: "Product Id",
    //   sortable: true,
    //   ...actionColumnXS,
    // },
    {
      field: "customer_name",
      headerName: "Reward Customer",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "Product_barcode",
      headerName: "Product Barcode",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "product_name",
      headerName: "Product Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "category_name",
      headerName: "Category Name",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "total_quantity",
      headerName: "Quantity",
      sortable: true,
      ...actionColumnXS,
    },
  ];

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLocation",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          setCategory(loaderResponse?.data?.data?.category);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Customer Purchase History Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.REWARD_CUSTOMER_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.customerPurchase);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);

  useEffect(() => {
    let isMounted = true;
    autoCompleteActive.current = true;
    // if (!isLoadingOptions) {
    //   return undefined;
    // }
    if (selectAllRef.current) {
      return; // Prevents extra API call when selecting all customers
    }
    const handler = setTimeout(() => {
      if (autoCompleteActive.current && isMounted) {
        searchCustomers();
      }
    }, 300);
    return () => {
      autoCompleteActive.current = false;
      isMounted = false;
      clearTimeout(handler);
    };
  }, [inputValueAuto]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      searchCustomers();
    }
  }, [open]);

  const formik = useFormik({
    initialValues: {
      selectedLocation: "",
      selectedCustomers: [],
      selectedcategory:
        undefined !== loaderResponse?.data?.data?.category &&
        null !== loaderResponse?.data?.data?.category
          ? loaderResponse?.data?.data?.category
          : [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLocation: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      selectedCustomers: Yup.array().min(
        1,
        Constants.REWARD_CUSTOMER_IS_REQUIRED
      ),
      selectedcategory: Yup.array().min(1, Constants.CATEGORIES_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current = false;
      getCustomerPurchaseData();
    }
  }, [paginationModel, sortModel, formik?.values?.selectedCustomers]);

  const searchCustomers = () => {
    let data = {
      filter: inputValueAuto,
      ...(!selectAllRef.current && { limit: 100 }),
    };
    try {
      getRewardCustomerList(data)
        .then((response) => {
          if (response.data.flag === true) {
            setCustomers(response.data.data.customer);
            setOptions(response.data.data.customer);
            if (selectAllRef.current) {
              formik.setFieldValue(
                "selectedCustomers",
                response.data.data.customer
              );
              setInputValueAuto(
                response.data.data.customer
                  .map((customer) => customer.name)
                  .join(", ")
              );
            }
          } else {
            setCustomers([]);
          }
        })
        .catch((err) => {
          setCustomers([]);
          setOptions(0);
        });
    } catch (error) {
      setCustomers([]);
    }
  };

  const handleExportReport = () => {
    formik.setTouched(
      {
        selectedLocation: true,
        selectedCustomers: true,
        selectedcategory: true,
        fromDate: true,
        toDate: true,
      },
      true
    );

    if(formik.values.selectedLocation?.length === 0 ||
      formik.values.selectedCustomers?.length === 0 ||
      formik.values.selectedcategory?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLocation ||
      formik.errors.selectedcategory ||
      formik.errors.fromDate ||
      formik.errors.toDate){
        return;
    }
    if (formik.values.selectedLocation.length > 0) {
      if (formik.values.selectedCustomers.length > 0) {
        if (formik.values.selectedcategory.length > 0) {
          if (null != formik.values.fromDate && "" != formik.values.fromDate) {
            if (null != formik.values.toDate && "" != formik.values.toDate) {
              myContext.handleLoading(true);
              let data = {
                getreport: false,
                type: "xls",
                location: JSON.stringify({
                  label: selectedLocation.name,
                  value: {
                    id: selectedLocation.id,
                    name: selectedLocation.name,
                  },
                }),
                // customer: JSON.stringify(formik.values.selectedCustomers),
                customer: JSON.stringify(
                  checkRef.current
                    ? customerRef.current
                    : formik.values.selectedCustomers
                ),
                category: JSON.stringify(formik.values.selectedcategory),
                query: JSON.stringify({
                  limit: paginationModel.pageSize,
                  page: paginationModel.page + 1,
                  order:
                    sortModel.length > 0
                      ? sortModel[0].field
                      : sortRef.current[0].field,
                  order_type:
                    sortModel.length > 0
                      ? sortModel[0].sort
                      : sortRef.current[0].sort,
                }),
              };
              if (
                formik.values.fromDate != null &&
                formik.values.fromDate != ""
              ) {
                data.dateFrom = reportDateFormate(formik.values.fromDate);
              }
              if (formik.values.toDate != null && formik.values.toDate != "") {
                data.dateTo = reportDateFormate(formik.values.toDate);
              }
              submit(data, {
                method: Constants.POST,
                path: Constants.CUSTOMER_PURCHASE_HISTORY_ROUTE,
              });
            // } else {
            //   clearTableData(Constants.TO_DATE_IS_REQUIRED);
            }
          // } else {
          //   clearTableData(Constants.FORM_DATE_IS_REQUIRED);
          }
        // } else {
        //   clearTableData(Constants.CATEGORY_IS_REQUIRED);
        }
      // } else {
      //   clearTableData(Constants.CUSTOMER_IS_REQUIRED);
      }
    // } else {
    //   clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };

  const getCustomerPurchaseData = () => {

    formik.setTouched(
      {
        selectedLocation: true,
        selectedCustomers: true,
        selectedcategory: true,
        fromDate: true,
        toDate: true,
      },
      true
    );
    if(formik.values.selectedLocation?.length === 0 ||
      formik.values.selectedCustomers?.length === 0 ||
      formik.values.selectedcategory?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLocation ||
      formik.errors.selectedcategory ||
      formik.errors.fromDate ||
      formik.errors.toDate){
        return;
    }
    if (formik.values.selectedLocation.length > 0) {
      if (formik.values.selectedCustomers.length > 0) {
        if (formik.values.selectedcategory.length > 0) {
          if (null != formik.values.fromDate && "" != formik.values.fromDate) {
            if (null != formik.values.toDate && "" != formik.values.toDate) {
              setButtonDisable(true);
              setLoading(true);
              setShowTable(false);
              setApplyClicked(true);
              let data = {
                getreport: true,
                location: JSON.stringify({
                  label: selectedLocation.name,
                  value: {
                    id: selectedLocation.id,
                    name: selectedLocation.name,
                  },
                }),
                // customer: JSON.stringify(formik.values.selectedCustomers),
                customer: JSON.stringify(
                  checkRef.current
                    ? customerRef.current
                    : formik.values.selectedCustomers
                ),
                category: JSON.stringify(formik.values.selectedcategory),
                zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                query: JSON.stringify({
                  limit: paginationModel.pageSize,
                  page: paginationModel.page + 1,
                  order:
                    sortModel.length > 0
                      ? sortModel[0].field
                      : sortRef.current[0].field,
                  order_type:
                    sortModel.length > 0
                      ? sortModel[0].sort
                      : sortRef.current[0].sort,
                }),
              };
              if (
                formik.values.fromDate != null &&
                formik.values.fromDate != ""
              ) {
                data.dateFrom = reportDateFormate(formik.values.fromDate);
              }
              if (formik.values.toDate != null && formik.values.toDate != "") {
                data.dateTo = reportDateFormate(formik.values.toDate);
              }
              submit(data, {
                method: Constants.POST,
                path: Constants.CUSTOMER_PURCHASE_HISTORY_ROUTE,
              });
            // } else {
            //   clearTableData(Constants.TO_DATE_IS_REQUIRED);
            }
          // } else {
          //   clearTableData(Constants.FORM_DATE_IS_REQUIRED);
          }
        // } else {
        //   clearTableData(Constants.CATEGORY_IS_REQUIRED);
        }
      // } else {
      //   clearTableData(Constants.CUSTOMER_IS_REQUIRED);
      }
    // } else {
    //   clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLocation", e.value.name);
    }
  };

  const handleCategoryOptionSelect = (e) => {
    formik.setFieldValue("selectedcategory", e.value);
  };

  const handleKeyDown = (event) => {
    // Prevent default backspace behavior when the input is empty
    if (event.key === "Backspace" && event.target.value === "") {
      event.stopPropagation();
    }
  };

  const handleSetOptions = (newOptions) => {
    setOptions(newOptions);
  };

  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    selectAllRef.current = false;
    checkRef.current = false;
    formik.setFieldValue("selectedCustomers", []);
    // setOptions([]);
    setInputValueAuto("");
    setShowTable(true);
    setTotalCount(0);
    setCustomers([]);
    customerRef.current = [];
    setApplyClicked(false);
    setSelectedLocation([]);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };

  const modifiedData = tableData.map((data, i) => ({
    // sr_no: counter++,
    // id: data.id,
    id: i,
    customer_name: data.customer_name || "N.A.",
    Product_barcode: data.barcode || "N.A.",
    product_name: data.product_name,
    category_name: data.categories,
    // category_name: data.categories.length > 0 ? data.categories[0].name : "",
    total_quantity: data.total_quantity,
    total_quantity: parseFloat(data.total_quantity).toString(),
  }));

  const handleCheckboxChange = (isSelected, option) => {
    handleChange(isSelected, option);
  };

  const handleChange = (isSelected, option) => {
    let updatedSelection;
    if (isSelected) {
      const isAlreadySelected = (formik.values.selectedCustomers || []).some(
        (value) => value.id === option.id
      );
      if (!isAlreadySelected) {
        updatedSelection = [
          ...formik.values.selectedCustomers,
          { id: option.id, name: option.name },
        ];
      } else {
        updatedSelection = formik.values.selectedCustomers.filter(
          (value) => value.id !== option.id
        );
      }
    } else {
      updatedSelection = formik.values.selectedCustomers.filter(
        (value) => value.id !== option.id
      );
    }
    let isAllDataValid =
      formik.values.selectedLocation.length > 0 &&
      // formik.values.selectedCustomers.length > 0 &&
      formik.values.selectedcategory.length > 0 &&
      null != formik.values.fromDate &&
      "" != formik.values.toDate;
    // if (isAllDataValid) {
    //   pageRef.current = true;
    //   formik.setFieldValue("selectedCustomers", updatedSelection);
    // } else {
    formik.setFieldValue("selectedCustomers", updatedSelection);
    // }
  };

  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card justify-content-center"
          flexDirection={isSmall ? "column" : "row"}
          spacing={{ base: 3, sm: 3, md: 3, lg: 2, xl: 3, "2xl": 4 }}
          width={isSmall ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Box width={isSmall ? "100% !important" : "33% !important"}>
            <PosDropDown
              options={locations}
              value={selectedLocation}
              resetFilterOnHide={true}
              onChange={handleOptionSelect}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onBlur={(e) => {
                formik.setFieldTouched("selectedLocation");
                formik.handleBlur(e);
              }}
              optionLabel="name"
              placeholder="Select Locations*"
              height={"2.5rem"}
              className="w-full md:w-20rem"
              // maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedLocation &&
              formik.errors.selectedLocation ? (
                <span>{formik.errors.selectedLocation}</span>
              ) : null}
            </Text>
          </Box>
          <Stack
            width={isSmall ? "100% !important" : "67% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={{ base: 3, sm: 3, md: 3, lg: 2, xl: 3, "2xl": 4 }}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date*"}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                    ? "17rem"
                    : isMeduim1
                    ? "13.32rem"
                    : "100%"
                }
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date*"}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                    ? "17rem"
                    : isMeduim1
                    ? "13.32rem"
                    : "100%"
                }
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
        <Stack
          className="card justify-content-center"
          flexDirection={isSmall ? "column" : "row"}
          spacing={{ base: 3, sm: 3, md: 3, lg: 2, xl: 2, "2xl": 4 }}
          width={isSmall ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Box width={isSmall ? "100% !important" : "33% !important"}>
            <PosDropDown
              options={category}
              value={formik.values.selectedcategory}
              onChange={handleCategoryOptionSelect}
              resetFilterOnHide={true}
              onBlur={(e) => {
                formik.setFieldTouched("selectedcategory");
                formik.handleBlur(e);
              }}
              multiSelect={true}
              optionLabel="name"
              placeholder="Select Categories*"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              height={"2.5rem"}
              className="w-full md:w-20rem"
              maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedcategory &&
              formik.errors.selectedcategory ? (
                <span>{formik.errors.selectedcategory}</span>
              ) : null}
            </Text>
          </Box>
          <Stack
            width={isSmall ? "100% !important" : "67% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={{ base: 3, sm: 3, md: 3, lg: 2, xl: 1, "2xl": 4 }}
          >
            <Box width={"100% !important"}>
              <ThemeProvider theme={Mtheme}>
                <CustomAutocomplete
                  ref={autoCompleteRef}
                  open={open}
                  onClose={(event, reason) => {
                    if (
                      reason == "toggleInput" ||
                      (event && reason == "blur")
                    ) {
                      setOpen(false);
                      setInputValueAuto("");
                    }
                  }}
                  setOpen={setOpen}
                  loading={isLoadingOptions}
                  onBlur={(e) => {
                    formik.setFieldTouched("selectedCustomers", true);
                    formik.handleBlur(e);
                  }}
                  isExtraLarge={isExtraLarge}
                  isLarge={isLarge}
                  isMeduim1={isMeduim1}
                  disabled={
                    selectAllRef.current ||
                    !formik.values.selectedLocation ||
                    formik.values.selectedLocation.length <= 0 ||
                    formik.errors.selectedLocation ||
                    buttonDisable
                      ? true
                      : false
                  }
                  handleCheckboxChange={handleCheckboxChange}
                  setInputValueAuto={setInputValueAuto}
                  customers={customers}
                  checkRef={checkRef}
                  selectAllRef={selectAllRef}
                  customerRef={customerRef}
                  handleKeyDown={handleKeyDown}
                  formik={formik}
                  buttonDisable={buttonDisable}
                  setOptions={handleSetOptions}
                />
              </ThemeProvider>
              <Text color={Colors.errorColor}>
                {formik.touched.selectedCustomers &&
                formik.errors.selectedCustomers ? (
                  <span>{formik.errors.selectedCustomers}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"} flexDirection="column">
              <Flex width={"100% !important"}>
                <Checkbox
                  checked={selectAllRef.current}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    selectAllRef.current = isChecked;
                    if (isChecked) {
                      searchCustomers();
                      formik.setFieldValue("selectedCustomers", customers);
                      setInputValueAuto(
                        customers.map((customer) => customer.name).join(", ")
                      );
                      checkRef.current = false;
                      customerRef.current = [];
                      setOptions([]);
                    } else {
                      formik.setFieldValue("selectedCustomers", []);
                      setOptions([]);
                      setInputValueAuto("");
                    }
                  }}
                  width={
                    isExtraLarge
                      ? "20rem"
                      : isLarge
                      ? "17rem"
                      : isMeduim1
                      ? "13.32rem"
                      : "100%"
                  }
                />
                <Text
                  color={Colors.posTextInfo}
                  fontSize={"0.94rem"}
                  fontWeight={300}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {"Select All Reward Customers"}
                </Text>
                <Text color={Colors.errorColor}>
                  {formik.touched.selectedCustomers &&
                  formik.errors.selectedCustomers ? (
                    <span></span>
                  ) : null}
                </Text>
              </Flex>
            </Box>
          </Stack>
        </Stack>
        {!selectAllRef.current && (
          <ThemeProvider theme={Mtheme}>
            {formik.values.selectedCustomers.length > 0 && (
              <Wrap spacing={2}>
                {Array.from(
                  new Set(
                    formik.values.selectedCustomers.map((option) => option.id)
                  )
                )
                  .map((id) =>
                    formik.values.selectedCustomers.find(
                      (option) => option.id === id
                    )
                  )
                  .map((option) => (
                    <Chip
                      key={option.id}
                      label={option.name}
                      onDelete={() => {
                        const newValue = formik.values.selectedCustomers.filter(
                          (val) => val.id !== option.id
                        );
                        customerRef.current = newValue;
                        let isAllDataValid =
                          formik.values.selectedLocation.length > 0 &&
                          formik.values.selectedCustomers.length > 0 &&
                          formik.values.selectedcategory.length > 0 &&
                          null != formik.values.fromDate &&
                          "" != formik.values.toDate;
                        // if (isAllDataValid) {
                        //   pageRef.current = true;
                        //   formik.setFieldValue("selectedCustomers", newValue);
                        // } else {
                        formik.setFieldValue("selectedCustomers", newValue);
                        // }
                      }}
                    />
                  ))}
              </Wrap>
            )}
          </ThemeProvider>
        )}
      </>
    );
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Customer Purchase History"]}
          breadCrumTitle={"Customer Purchase History"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack gap={4} m={4}>
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isLarge ? 0 : 4}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              isDisabled={applyClicked ? false : true}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                // formik.values.selectedLocation?.length === 0 ||
                // formik.values.selectedCustomers?.length === 0 ||
                // formik.values.selectedcategory?.length === 0 ||
                // formik.values.fromDate === "" ||
                // formik.values.toDate === "" ||
                // formik.errors.selectedLocation ||
                // formik.errors.selectedcategory ||
                // formik.errors.fromDate ||
                // formik.errors.toDate ||
                buttonDisable
              }
              onClick={getCustomerPurchaseData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.CUSTOMER_PURCHASE_HISTORY_REPORT_CSV}
              />
            </Flex>
          ) : (
            <PosNoDataFound
              title={Constants.CUSTOMER_PURCHASE_HISTORY_NO_DATA}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default WithRouter(CUSTOMER_PURCHASE_HISTORY);
