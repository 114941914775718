import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { SimpleReportLayout } from "../../../../../layouts/index";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { downloadPDF } from "./SalesSummaryForSingleLocationService";
const SalesSummaryForSingleLocations = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [salesData, setSalesData] = useState([]);
  const [profitabilityData, setProfitabilityData] = useState([]);
  const [categorySalesData, setCategorySalesData] = useState([]);
  const [tillData, setTillData] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const [preDefineCouponData, setPreDefineCouponData] = useState([]);
  const [giftcardData, setGiftcardData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [locations, setLocations] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [applyClicked, setApplyClicked] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),

      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => { },
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLoactions",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    getSalesSummaryForSingleLocationData();
  }, []);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Sales summary For Single Location Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.SALES_SUMMARY_SINGLE_LOC_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setSalesData(actionResponse.data.data.sales);
          setProfitabilityData(actionResponse.data.data.profitabilityData);
          // setTillData(actionResponse.data.data.till);
          setCategorySalesData(actionResponse.data.data.categoryList);
          setPaymentsData(actionResponse.data.data.payment_methods);
          setPreDefineCouponData(actionResponse.data.data.sales.all_predefined_discounts_dtls);
          // setGiftcardData(actionResponse.data.data.giftCardSales);
          if (
            actionResponse.data.data.till != null &&
            actionResponse.data.data.till != undefined &&
            actionResponse.data.data.till.length != 0
          ) {
            let tillDetails = [];
            actionResponse.data.data.till.forEach((item1) => {
              // Station header
              let post_station_name = [
                { heading: `Station Name :  ${item1.posName}` },
              ];
              tillDetails.push(post_station_name);
              // Detailed till information for each station
              item1.tillData.forEach((item) => {
                let gridTillData = [
                  { name: "Opened On : ", text: `${item.opened_on}` },
                  {
                    name: "Pay-Out : ",
                    text: `${item.payout_amount == null
                      ? "$0.00"
                      : "$" + item.payout_amount
                      }`,
                  },
                  { name: "Closed On : ", text: `${item.closed_on}` },
                  {
                    name: "Bank Drop Amount : ",
                    text: `${item.bank_drop_amount == null
                      ? "$0.00"
                      : "$" + item.bank_drop_amount
                      }`,
                  },
                  {
                    name: "Opening Amount : ",
                    text: `${"$" + item.starting_amount}`,
                  },
                  {
                    name: "Cashback : ",
                    text: `${"$" + item.cashback}`,
                  },

                  // {
                  //   name: "Credit Account Cash Payment : ",
                  //   text: `${"$" + item.cash_credit_account_payment}`,
                  // },
                  {
                    name: "Cash Sales : ",
                    text: `${item.cash_till_payment == null
                      ? "$0.00"
                      : "$" + item.cash_till_payment
                      }`,
                  },
                  {
                    name: "Credit Account Payments : ",
                    text: `${"$" + item.pay_invoices_cash}`,
                  },
                  {
                    name: "Cash Refunds : ",
                    text: `${item.cash_till_refund == null
                      ? "$0.00"
                      : "$" + item.cash_till_refund
                      }`,
                  },
                  {
                    name: "Closing Amount : ",
                    text: `${item.starting_amount != null &&
                      item.closing_amount == null
                      ? "Register was opened but not closed."
                      : "$" + item.closing_amount
                      }`,
                  },
                  {
                    name: "Pay-In : ",
                    text: `${item.payin_amount == null
                      ? "$0.00"
                      : "$" + item.payin_amount
                      }`,
                  },
                  {
                    name: "Difference :",
                    text: `${item.difference == null ? "$0.00" : "$" + item.difference
                      }`,
                  },

                ];
                tillDetails.push(gridTillData);
              });
            });
            setTillData(tillDetails);
          }
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          myContext.handleLoading(true);
          let data = {
            getreport: false,
            location: JSON.stringify(selectedLocation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            type: "xls",
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.SALES_SUMMARY_FOR_SINGLE_LOCATIONS_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const handlePdfReport = () => {
    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          myContext.handleLoading(true);
          let data = {
            getreport: true,
            location: selectedLocation,
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          };
          try {
            downloadPDF(data)
              .then((response) => {
                if (
                  undefined !== response.data.flag &&
                  null !== response.data.flag &&
                  response.data.flag == true
                ) {
                  var url = Constants.REACT_APP_API_URL + response.data.data;
                  // var url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
                  window.open(url, "_blank");
                  setLoading(false);
                  myContext.handleLoading(false);
                } else {
                  setLoading(false);
                  let actionData = response;
                  error({ actionData });
                  myContext.handleLoading(false);
                }
              })
              .catch((err) => {
                setLoading(false);
                let actionData = err;
                error({ actionData });
                myContext.handleLoading(false);
              });
          } catch (error) {
            setLoading(false);
            myContext.handleLoading(false);
          }
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTillData([]);
    setSalesData([]);
    setProfitabilityData([]);
    setShowTable(false);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getSalesSummaryForSingleLocationData = () => {
    formik.setTouched({
      selectedLoactions: true,
      fromDate: true,
      toDate: true,
    });
    if(formik.values.selectedLoactions?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLoactions ||
      formik.errors.fromDate ||
      formik.errors.toDate){
      return;
    }
    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setTillData([]);
          setSalesData([]);
          setProfitabilityData([]);
          setShowTable(false);
          setApplyClicked(true);
          let data = {
            getreport: true,
            location: JSON.stringify(selectedLocation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.SALES_SUMMARY_FOR_SINGLE_LOCATIONS_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
      //   formik.setFieldValue("selectedLoactions", e.value);
    }
  };
  const handleResetAllData = () => {
    setSelectedLocation([]);
    setSalesData([]);
    setProfitabilityData([]);
    setShowTable(true);
    setApplyClicked(false);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card justify-content-center">
          <PosDropDown
            id="selectedLoactions"
            options={locations}
            value={selectedLocation}
            resetFilterOnHide={true}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            // multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                  ? "17rem"
                  : isMeduim1
                    ? "13.32rem"
                    : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
          // maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
              formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Stack
          className="card justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                    ? "17rem"
                    : isMeduim1
                      ? "13.32rem"
                      : "100%"
              }
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                    ? "17rem"
                    : isMeduim1
                      ? "13.32rem"
                      : "100%"
              }
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  const totalPayments =
    parseFloat(paymentsData.CASH) +
    parseFloat(paymentsData.CARD) +
    parseFloat(paymentsData.GIFT_CARD_PAYMENT) +
    parseFloat(paymentsData.EBT) +
    parseFloat(paymentsData.OTHER) +
    parseFloat(paymentsData.TIP);
  let total_gross_sales =
    parseFloat(salesData?.total_gross_sales ?? 0.0) + // remove Tax
    parseFloat(salesData?.total_discount ?? 0.0) + // Total discount All product+order(manual+coupon+reward+predefined)
    parseFloat(salesData?.dual_pricing_discount ?? 0.0) +
    parseFloat(salesData?.total_refunded_amount_with_order ?? 0.0) +
    parseFloat(salesData?.total_refunded_amount_without_order ?? 0.0) +
    parseFloat(salesData?.total_refunded_tax_amount_with_order ?? 0.0) +
    parseFloat(salesData?.total_refunded_tax_amount_without_order ?? 0.0) +
    parseFloat(salesData?.total_refunded_tip_amount_with_order ?? 0.0) +
    parseFloat(salesData?.total_bottle_deposite_refund ?? 0.0);

  let total_refunds =
    parseFloat(salesData?.total_refunded_amount_with_order ?? 0.0) +
    parseFloat(salesData?.total_refunded_amount_without_order ?? 0.0) +
    parseFloat(salesData?.total_refunded_tax_amount_with_order ?? 0.0) +
    parseFloat(salesData?.total_refunded_tax_amount_without_order ?? 0.0) +
    parseFloat(salesData?.total_refunded_tip_amount_with_order ?? 0.0) +
    parseFloat(salesData?.total_bottle_deposite_refund ?? 0.0);

  let total_Net_sales =
    parseFloat(total_gross_sales) -
    parseFloat(salesData?.total_discount ?? 0.0) -
    parseFloat(salesData?.dual_pricing_discount ?? 0.0) -
    parseFloat(total_refunds);
  let refunds_from_orders =
    parseFloat(salesData?.total_refunded_tax_amount_with_order) +
    parseFloat(salesData?.total_refunded_amount_with_order);
  let refunds_without_orders =
    parseFloat(salesData?.total_refunded_tax_amount_without_order) +
    parseFloat(salesData?.total_refunded_amount_without_order);
  let cash_refunded_orders =
    parseFloat(salesData?.cash_refund_with_order) +
    parseFloat(salesData?.cash_refund_without_order) +
    parseFloat(salesData?.total_bottle_deposite_refund);

  let total_discounts =
    parseFloat(salesData?.dual_pricing_discount) +
    parseFloat(salesData?.total_other_discount) +
    parseFloat(salesData?.total_predefined_discount);
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Sales Summary For Single Location"]}
          breadCrumTitle={"Sales Summary For Single Location"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {null != salesData && "" != salesData && undefined != salesData && (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Export
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleExportReport}>Export Excel</MenuItem>
                <MenuItem onClick={handlePdfReport}>Export PDF</MenuItem>
              </MenuList>
            </Menu>
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isMeduim ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              isDisabled={applyClicked ? false : true}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                // formik.values.selectedLoactions?.length === 0 ||
                // formik.values.fromDate === "" ||
                // formik.values.toDate === "" ||
                // formik.errors.selectedLoactions ||
                // formik.errors.fromDate ||
                // formik.errors.toDate ||
                buttonDisable
              }
              onClick={getSalesSummaryForSingleLocationData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : null != salesData && "" != salesData && undefined != salesData ? (
            <Flex direction={"column"} gap={4}>
              <Box
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                p="4"
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
              >
                <PosListHeader header={"Sales Details"}></PosListHeader>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={500}
                  >
                    Gross Sales
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${total_gross_sales.toFixed(2)}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Discount
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_discount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Cash Discount
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.dual_pricing_discount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Refund
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${total_refunds.toFixed(2)}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={500}
                  >
                    Net Sales
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${total_Net_sales.toFixed(2)}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Taxes
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>${salesData.total_tax}</Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Fees
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>${salesData.total_fee}</Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Tips
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>${salesData.total_tip}</Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Gift Card Sales
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_gift_card_sales}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Total Unpaid Amount
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_unpaid_amount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Total Bank Drop
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_bank_drop}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Total Extra Item Amount
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_extra_item_amount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
              </Box>
              <Box
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                p="4"
              >
                <PosListHeader header={"ORDER ANALYTICS"}></PosListHeader>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Total Orders
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    {salesData.total_number_of_orders}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Avg. Amount Per Order
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.average_sales_per_order}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
              </Box>

              <Box
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                p="4"
              >
                <PosListHeader header={"PROFITABILITY"}></PosListHeader>
                <Text
                    color={Colors.posTextInfo}
                    fontWeight={300}
                    ml={2.5}
                  >
                    Only for items that has cost value.
                  </Text>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Total Revenue
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${profitabilityData.revenue}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Total Cost
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${profitabilityData.cost}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Net Profitability
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    {/* ${profitabilityData.profit} */}
                    {Number(profitabilityData.profit) < 0
                      ? `-$${Math.abs(
                          profitabilityData.profit
                        ).toFixed(2)}`
                      : `$${Number(
                          profitabilityData.profit
                        ).toFixed(2)}`}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Profit Margin
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    {profitabilityData.margin_percentage}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
              </Box>

              <Box
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                p="4"
              >
                <PosListHeader
                  header={"SALES BY PAYMENT METHOD"}
                ></PosListHeader>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Card
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>${paymentsData.CARD}</Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Cash
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>${paymentsData.CASH}</Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    EBT
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>${paymentsData.EBT}</Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Gift Card
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${paymentsData.GIFT_CARD_PAYMENT}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Other
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>${paymentsData.OTHER}</Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Tips
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>${paymentsData.TIP}</Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={500}
                  >
                    Net Total
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${totalPayments.toFixed(2)}
                  </Text>
                </Flex>
              </Box>
              <Box
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                p="4"
              >
                <PosListHeader header={"DISCOUNTS"}></PosListHeader>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Pre Defined Discounts
                  </Text>
                  <Spacer />
                  {preDefineCouponData.length < 1 && (<Text color={Colors.posTextInfo}>
                    $0.00
                  </Text>)}
                </Flex>
                {preDefineCouponData.length > 0 && preDefineCouponData.map((discount, index) => (
                  <>
                 <Divider mt={2} ></Divider>
                 <Flex key={index} mt={2} ml={4}>
                    <Text
                      ml={2.5}
                      color={Colors.posViewTextColor}
                      fontWeight={300}
                    >
                      {discount.name}
                    </Text>
                    <Spacer />
                    <Text color={Colors.posTextInfo}>
                      ${discount.total_predefined_discount_amount}
                    </Text>
                  </Flex>
                  </>))}
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Other Discounts
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_other_discount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Dual Pricing Cash Discounts
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.dual_pricing_discount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Total Discounts
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${total_discounts.toFixed(2)}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
              </Box>
              <Box
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                p="4"
              >
                <PosListHeader header={"REFUNDS"}></PosListHeader>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Refunds from Orders
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${refunds_from_orders.toFixed(2)}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Refunds without Orders
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${refunds_without_orders.toFixed(2)}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Bottle Deposit Refunds
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_bottle_deposite_refund}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Refunded Tips
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_refunded_tip_amount_with_order}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Total Refunds
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${total_refunds.toFixed(2)}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
              </Box>
              <Box
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                p="4"
              >
                <PosListHeader header={"CASH REPORT"}></PosListHeader>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Pay In Amounts
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_payin}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Pay Out Amounts
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_payout}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Cash Refunds
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${cash_refunded_orders.toFixed(2)}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Credit Account Sales
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.cash_credit_account_payment}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}>
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                  >
                    Credit Account Payments
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo}>
                    ${salesData.total_pay_invoices_cash}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
              </Box>
              <Box
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                p="4"
              >
                <PosListHeader header={"TILL DETAILS"}></PosListHeader>
                <Divider mt={2}></Divider>
                {tillData.length > 0 ? (
                  tillData.map((item, index) => (
                    <Box
                      key={index}
                      mt={2}
                      mb={2}
                      fontWeight={500}

                      color={Colors.posTextInfo}
                      direction="column"
                    >
                      {item[0]?.heading ? (
                        <Text>{item[0].heading}</Text>
                      ) : (
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "repeat(2, 1fr)",
                          }}
                          gap={4}
                          columnGap={14}
                          mb={2}
                        >
                          {item.map((ite, subIndex) => (
                            <GridItem key={subIndex}>
                              <Flex direction="row">
                                <Text
                                  color={Colors.posViewTextColor}
                                  fontWeight={300}
                                  w={"50%"}
                                >
                                  {ite.name}
                                </Text>
                                <Text
                                  color={Colors.posTextInfo}
                                  w={"50%"}
                                  textAlign={"end"}
                                >
                                  {ite.text}
                                </Text>
                              </Flex>
                            </GridItem>
                          ))}
                        </Grid>
                      )}
                      <Divider mt={2}></Divider>
                    </Box>
                  ))
                ) : (
                  <PosNoDataFound title={"There are no Till Details."} />
                )}
              </Box>
              {categorySalesData.length > 0 && (
                <>
                  <Box
                    mb={{ base: 4, md: 4 }}
                    borderRadius="0.63rem"
                    bg={Colors.posPageDataBackground}
                    boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                    p="4"
                  >
                    <PosListHeader
                      header={"CATEGORY WISE SALES"}
                    ></PosListHeader>

                    <Divider mt={2}></Divider>
                    {categorySalesData.map((category, index) => (
                      <Flex key={index} direction="column">
                        <Flex mt={2}>
                          <Text
                            ml={2.5}
                            color={Colors.posViewTextColor}
                            fontWeight={300}
                          >
                            {category.cat_name}
                          </Text>
                          <Spacer />
                          <Text color={Colors.posTextInfo}>
                            ${category.total_sale_price}
                          </Text>
                        </Flex>
                        <Divider mt={2}></Divider>
                      </Flex>
                    ))}
                  </Box>
                </>
              )}
            </Flex>
          ) : (
            <PosNoDataFound
              title={"There are no Sales Summary For Single Location."}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(SalesSummaryForSingleLocations);
