import {
  Box,
  ButtonGroup,
  Flex,
  SimpleGrid,
  Text,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
  ProductEditDrawer,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  commonDateFormate,
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";

const Shrinkage = (props) => {
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const [searchQuery, setSearchQuery] = useState("");
  const [totalcount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [isVisible, setIsVisible] = useState(false);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const { error } = PosErrorHook();
  const [locations, setLocations] = useState([]);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const searchInputHideRef = useRef(false);
  const { addToast } = PosTostMessage();
  const [id, setId] = useState(0);
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const btnRef = React.useRef();
  const [editFlag, setEditFlag] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "created_at",
      sort: "desc",
    },
  ]);
  const sortRef = useRef(sortModel);
  const [listOlColumnVisibilityModel, setOlColumnVisibilityModel] =
    React.useState({
      // id: false,
    });
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const actionColumnXS = isMobile ? { width: 120 } : { flex: 0.9 };
  const [timeoutId, setTimeoutId] = useState(null);
  const Reasons = [
    {
      label: "Expired",
      value: "EXP",
    },
    {
      label: "Damaged",
      value: "DAM",
    },
    {
      label: "Dead Stock",
      value: "DEAD",
    },
    {
      label: "Other",
      value: "OTHER",
    },
  ];
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
          effectRun.current = false;
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLoactions",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    // getRefundOrderData();/
  }, []);
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        if (!searchInputHideRef.current && actionResponse.error.data === 0) {
          searchInputHideRef.current = true;
        }
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Shrinkage Report Exported successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.SHRINKAGE_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          searchInputHideRef.current = false;
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      selectedReasons: [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.mixed().when(['fromDate', 'toDate'], {
        is: (fromDate, toDate) => fromDate || toDate,
        then: () => Yup.string().required(Constants.LOCATION_IS_REQUIRED),
        otherwise: () => Yup.mixed().nullable()
      }),
      // fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      // toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      fetchData();
    }
  }, [
    paginationModel,
    // searchQuery,
    sortModel,
    formik.values.fromDate,
    formik.values.selectedLoactions,
    formik.values.selectedReasons,
    formik.values.toDate,
  ]);
  const fetchData = async () => {
    if (
      formik.values.selectedLoactions == "" &&
      locations.length > 1 &&
      ((formik.values.fromDate != null && formik.values.fromDate != "") ||
        (formik.values.toDate != "" && formik.values.toDate != null))
    ) {
      effectRun.current = false;
      formik.setTouched({
        selectedLoactions: true,
        fromDate: true,
        toDate: true,
      });
      if(!formik.isValid){
        return;
      }
      // addToast({
      //   alertStatus: Constants.TOAST_TYPE_WARNING,
      //   alertTitle: Constants.REPORT_MODULE,
      //   alertDescription: Constants.LOCATION_IS_REQUIRED,
      // });
    } else {
      setLoading(true);
      let data = {
        getreport: true,
        location: JSON.stringify(selectedLocation),
        reason: JSON.stringify(formik.values.selectedReasons),
        dateFrom:
          formik.values.fromDate != null && formik.values.fromDate != ""
            ? reportDateFormate(formik.values.fromDate)
            : "",
        dateTo:
          formik.values.toDate != null && formik.values.toDate != ""
            ? reportDateFormate(formik.values.toDate)
            : "",
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          filter: searchQuery,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.SHRINKAGE_REPORT_ROUTE,
      });
    }
  };

  const exportData = async () => {
    if (
      formik.values.selectedLoactions == "" &&
      locations.length > 1 &&
      ((formik.values.fromDate != null && formik.values.fromDate != "") ||
        (formik.values.toDate != "" && formik.values.toDate != null))
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.LOCATION_IS_REQUIRED,
      });
    } else {
      myContext.handleLoading(true);
      let data = {
        getreport: false,
        type: "xls",
        location: JSON.stringify(selectedLocation),
        reason: JSON.stringify(formik.values.selectedReasons),
        dateFrom:
          formik.values.fromDate != null && formik.values.fromDate != ""
            ? reportDateFormate(formik.values.fromDate)
            : "",
        dateTo:
          formik.values.toDate != null && formik.values.toDate != ""
            ? reportDateFormate(formik.values.toDate)
            : "",
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          filter: searchQuery,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.SHRINKAGE_REPORT_ROUTE,
      });
    }
  };

  const handleOptionSelect = (e) => {
    effectRun.current = true;
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
    }
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };
  const handleReasonSelect = (e) => {
    effectRun.current = true;
    if (undefined !== e.value) {
      formik.setFieldValue("selectedReasons", e.value);
    }
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "")){
          effectRun.current = true;
        }
        
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "")){
          effectRun.current = true;
        }
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "") ){
        effectRun.current = true;
      }
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };

  const clearFilterDAta = () => {
    effectRun.current = true;

    if (searchQuery.length > 0) {
      setSearchQuery("");
    }
    if (locations.length > 1) {
      setSelectedLocation([]);
      formik.setFieldValue("selectedLoactions", "");
    }
    formik.setFieldValue("fromDate", "");
    formik.setFieldValue("toDate", "");
    formik.setFieldValue("selectedReasons", []);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    formik.resetForm();
  };
  const renderReportLayout = () => {
    return (
      <>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 2, lg: 2, xl: 5 }}
          ml={4}
          mb = {4}
          mr={4}
          spacing={{
            base: "1rem",
            sm: "1rem",
            md: "1rem",
            lg: "1rem",
            xl: "1rem",
          }}
        >
          {locations.length > 1 && (
            <Box>
              <PosDropDown
                id="selectedLoactions"
                options={locations}
                value={selectedLocation}
                resetFilterOnHide={true}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                optionLabel="name"
                placeholder="Select Location"
                width={"100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </Box>
          )}
          <Box>
            <PosDropDown
              id="selectedReasons"
              options={Reasons}
              value={formik.values.selectedReasons}
              resetFilterOnHide={true}
              multiSelect={true}
              onChange={handleReasonSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedReasons");
                formik.handleBlur(e);
              }}
              optionLabel="label"
              placeholder="Select Reasons"
              width={"100%"}
              height={"2.5rem"}
              className="w-full md:w-20rem"
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedReasons &&
              formik.errors.selectedReasons ? (
                <span>{formik.errors.selectedReasons}</span>
              ) : null}
            </Text>
          </Box>
          <Box>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date"}
              width={"100%"}
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box >
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date"}
              width={"100%"}
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                effectRun.current = setPaginationModel({
                  page: 0,
                  pageSize: 25,
                });
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
                if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "") ){
                  effectRun.current = true;
                }
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : undefined
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box mt={{ base: 2, md: 0 }}>
            <ButtonGroup>
              <PosFormButton
                isDisabled={
                  searchQuery.length > 0 ||
                  formik.values.selectedLoactions !== "" ||
                  formik.values.selectedReasons.length > 0 ||
                  (formik.values.fromDate !== "" && formik.values.fromDate !== null) ||
                  (formik.values.toDate !== "" && formik.values.toDate !== null)
                    ? false
                    : true
                }
                onClick={() => {
                  clearFilterDAta();
                }}
                buttonText={"Clear"}
                CancelButton={true}
              />
            </ButtonGroup>
          </Box>
        </SimpleGrid>
      </>
    );
  };
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    if (locations.length > 1) {
      setSelectedLocation([]);
      formik.setFieldValue("selectedLoactions", "");
      formik.setFieldTouched("selectedLoactions", false);
    }
    if (formik.values.selectedReasons.length > 0) {
      formik.setFieldValue("selectedReasons", []);
    }
    formik.setFieldValue("fromDate", "");
    formik.setFieldTouched("fromDate", false);
    formik.setFieldValue("toDate", "");
    formik.setFieldTouched("toDate", false);
  };
  const columnNames = [
    {
      field: "product_name",
      headerName: "Product Name",
      sortable: true,
      ...actionColumn,
      renderCell: (params) => {
        const rowData = params.row;
        const handleProductDrawer = () => {
          myContext.handleLoading(true);
          let id = rowData.product_id;
          setEditFlag(true);
          setId(id);
        };
        return (
          <Text
            onClick={handleProductDrawer}
            cursor={"pointer"}
            color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={"underline"}
            fontWeight={600}
          >
            {rowData.product_name}
          </Text>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity(-)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "user_name",
      headerName: "User",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "location_name",
      headerName: "Location",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "subtraction_reason",
      headerName: "Reason",
      sortable: false,
      ...actionColumnXS,
    },
    {
      field: "created_at",
      headerName: "Date",
      sortable: true,
      ...actionColumn,
    },
  ];
  const modifiedData = tableData.map((data, i) => {
    let reason;
    reason = "N.A.";
    if (
      data.subtraction_reason != undefined &&
      data.subtraction_reason != null &&
      data.subtraction_reason != ""
    ) {
      switch (data.subtraction_reason) {
        case "EXP":
          reason = "Expired";
          break;
        case "DAM":
          reason = "Damaged";
          break;
        case "DEAD":
          reason = "Dead Stock";
          break;
        default:
          const match = data.subtraction_reason.match(/^OTHER\{\}(.*)$/);
          reason = match
            ? "Other - " + (match[1] ? match[1] : "Description Missing")
            : "N.A.";
          break;
      }
    }

    return {
      id: i + 1,
      created_at: commonDateFormate(data.created_at),
      barcode: data.barcode,
      product_id: data.product_id,
      subtraction_reason: reason,
      location_id: data.location_id,
      location_name: data.location_name,
      narration: data.narration,
      quantity: data.quantity,
      product_name: data.product_name,
      user_name: data.user_name,
    };
  });

  const handleSearchList = () => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });

    // fetchData();
  };

  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };

  const apply = () => {
    // effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    // setLoading(true);
    // setApplyButtonClicked(1);
    // if (timeoutId) {
    //   clearTimeout(timeoutId);
    // }
    // const newTimeoutId = setTimeout(() => {
    // fetchDataAfterSearch(value);
    // }, 250);
    // setTimeoutId(newTimeoutId);
  };
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      // setPaginationModel({
      //   page: 0,
      //   pageSize: 25,
      // });
      effectRun.current = true;
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };

  const editModelClose = () => {
    onEditClose();
    setId(0);
    setEditFlag(false);
  };

  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <ProductEditDrawer
        fetchData={fetchData}
        isEditOpen={isEditOpen}
        placement="right"
        onEditClose={editModelClose}
        onEditOpen={onEditOpen}
        finalFocusRef={btnRef}
        editFlag={editFlag}
        id={id}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Shrinkage"]}
          breadCrumTitle={"Shrinkage"}
        />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={exportData}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>

      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        {!searchInputHideRef.current ? (
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={onChange}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
            <Box
              display={"flex"}
              justifyContent={"center"}
              w={"3rem"}
              ml={{ base: 0, md: "1rem" }}
              mt={{ base: "1rem", md: 0 }}
            >
              <IconButton
                onClick={() => {
                  toggleVisibility();
                }}
                style={{
                  border: "1px solid #ccc",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                  variant: "posoutline",
                  color: Colors.posTextInfo,
                  borderColor: Colors.dividerColor,
                  borderRadius: "5px",
                  padding: 5,
                }}
              >
                <FilterListIcon style={{ color: Colors.posTextInfo }} />
              </IconButton>
            </Box>
          </Flex>
        ) : null}
        {isVisible && <>{renderReportLayout()}</>}
        {loading ? (
          <PosProgress />
        ) : totalcount > 0 ? (
          <Box padding={"1rem"}>
            <PosDataGridTable
              columns={columnNames}
              rows={modifiedData}
              totalcount={totalcount}
              columnVisibilityModel={listOlColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setOlColumnVisibilityModel(newModel)
              }
              paginationModel={paginationModel}
              paginationMode="server"
              sortingMode="server"
              onPaginationModelChange={setPaginationModel}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                sortRef.current = sortModel;
                setSortModel(newSortModel);
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              fileName={Constants.ORDER_CSV}
            />
          </Box>
        ) : (
          <Box padding={"1rem"}>
            <PosNoDataFound title={Constants.SHRINKAGE_NOT_FOUND} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default WithRouter(Shrinkage);
