import { ArrowBackIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  IconButton,
} from "@chakra-ui/react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useActionData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import warnignIcon from "../../../assets/images/Ellipse2.svg";
import image from "../../../assets/images/newcropped.png";
import {
  AppContext,
  PosAlert,
  PosMainProgress,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
const ForgotPassword = (props) => {
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [allTouchedField, setAllTouchedField] = useState(false);
  const actionResponse = useActionData();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .matches(Constants.EMAIL_REGEX, Constants.USER_EMAIL_NOT_VALID)
        .required(Constants.USER_EMAIL_REQUIRED)
        .email(Constants.USER_EMAIL_NOT_VALID)
        .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
    }),

    onSubmit: (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      setAllTouchedField(false);
      const trimmedValues = {
        email: values.email.trim(), // explicitly trim the email value here
      };
      submit(trimmedValues, {
        method: Constants.POST,
        path: Constants.FORGOT_PASSWORD_PATH,
      });
    },
  });
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      myContext.handleLoading(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
      } else {
        setShowSuccessMessage(true);
        myContext.handleLoading(false);
        formik.setFieldValue("email", "");
        formik.setFieldTouched("email", false);
      }
    }
  }, [actionResponse]);
  return (
    <Flex
      bg={Colors.loginAuthBackground}
      minHeight={"100vh"}
      flexDirection={"column"}
    >
      <Box
        w={"100%"}
        sx={{
          position: "-webkit-sticky",
          /* Safari */ position: "sticky",
          top: "0",
          zIndex: 999,
        }}
      >
        <Box
          // w={"100%"}
          sx={{
            position: "-webkit-sticky",
            /* Safari */ position: "sticky",
            top: "0",
            zIndex: 99999999,
          }}
        >
          {myContext.loading ? <PosMainProgress /> : null}
        </Box>
        <Flex
          h={"100vh"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          <Box
            w={"100%"}
            maxWidth={"54.125rem"}
            bg={Colors.loginAuthBackground}
            style={{
              borderRadius: "0.63rem",
              border: "0.13rem solid #FFF",
              boxShadow: "0rem 1.25rem 3.13rem 0rem #59595926",
              boxSizing: "border-box",
            }}
          >
            <IconButton
              bg={Colors.forgotBackButtone}
              mt={{ base: "2.25rem", md: "3.25rem" }}
              // mt={"3.25rem"}
              ml={{ base: "2.85rem", md: "5.69rem" }}
              onClick={() => {
                props.navigate(Constants.SIGNIN_PATH);
              }}
              icon={<ArrowBackIcon color={Colors.blackIcon} />}
            />
            <Box
              mt={{ base: "1rem", md: 0 }}
              justifyContent="center"
              display="flex"
              alignItems="center"
              flexDir={"column"}
            >
              <Image w={"15.31rem"} src={image} alt="Octopos" />
              <Text
                mt={"2.63rem"}
                style={{
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                  color: "var(--Primary-color, #010048)",
                }}
              >
                Forgot Your Password
              </Text>
            </Box>
            <Flex
              mt={"7.06rem"}
              ml={{ base: "1rem", md: "9.75rem" }}
              mr={{ base: "1rem", md: "9.75rem" }}
              flexDir={"column"}
              // alignItems={"flex-start"}
            >
              <Text
                fontWeight="300"
                fontSize="0.94rem"
                lineHeight="1.18rem"
                letterSpacing="-0.015em"
                color={Colors.posTextInfo}
                mb={"0.31rem"}
              >
                Enter the email linked to this account
              </Text>
              <InputGroup w={"100%"} mb={"0.31rem"}>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="#5881FE" />
                </InputLeftElement>
                <Input
                  bg={Colors.loginAuthInput}
                  id={"email"}
                  placeholder="Email Address"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  inputerror={
                    formik.touched.email && formik.errors.email
                      ? "true"
                      : undefined
                  }
                />
              </InputGroup>
              {formik.touched.email && formik.errors.email ? (
                <PosAlert
                  width={"100%"}
                  alertStatus={"error"}
                  alertTitle={""}
                  image={warnignIcon}
                  bg={Colors.errorsign}
                  color={Colors.orangeButton}
                  alertDescription={formik.errors.email}
                />
              ) : null}
              <Text
                fontSize="0.94rem"
                fontWeight="300"
                lineHeight="1.19rem"
                letterSpacing="-0.015em"
                textAlign="left"
                color="#01004861"
                mt={"0.31rem"}
              >
                A verification link will be sent to your email
              </Text>
              <Button
                onClick={() => {
                  // submit time all validation fired and error massage display.
                  if (!formik.isValid) {
                    if (!allTouchedField) {
                      formik.handleSubmit();
                      setAllTouchedField(true);
                    }
                  } else {
                    formik.handleSubmit();
                  }
                }}
                isDisabled={buttonDisable}
                bg={Colors.loginButtonBackground}
                w={"100%"}
                mt={"2.62rem"}
              >
                Send Link
              </Button>
              {showSuccessMessage && (
                <Text
                  fontWeight="300"
                  fontSize="0.94rem"
                  lineHeight="1.13rem"
                  textAlign="center"
                  color="#0EBF3F"
                  mt={"1.12rem"}
                >
                  We have sent you an Email to reset your password!
                </Text>
              )}
            </Flex>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#38128A",
                fontSize: "0.75rem",
                fontWeight: "300",
              }}
              mt={"12rem"}
              mb={"2rem"}
            >
              <CopyrightIcon
                style={{
                  width: "1.13rem",
                  height: "1.13rem",
                  flexShrink: 0,
                  marginRight: "0.25rem",
                }}
              ></CopyrightIcon>
              2024,
              <Link target="_blank" href="https://www.octopos.com/">
                Octopos.
              </Link>
              All rights reserved
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
export default RouteWithLayout(ForgotPassword);
