import moment from "moment";
/**
 * tofixtwo().
 *
 * this function is used to do toFixed(2) after every calculation in order and order item calculation.
 *
 * @param {string or number} number Which number's toFixed(2) we have to do that number.
 *
 * @return {type} toFixed(2) value of requested value.
 */
export var twofix = (number) => {
  if (null !== number && "" !== number && !isNaN(number)) {
    return parseFloat(number).toFixed(2);
  }
};
export var twofixs = (number) => {
  if (null !== number && !isNaN(number)) {
    return "$" + parseFloat(number).toFixed(2);
  }
  return "$0.00";
};

export var reportDateFormate = (str) => {
  var date = new Date(str);
  var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);
  var hours = ("0" + date.getHours()).slice(-2);
  var minutes = ("0" + date.getMinutes()).slice(-2);
  var sec = ("0" + date.getSeconds()).slice(-2);
  return (
    mnth +
    "/" +
    day +
    "/" +
    date.getFullYear() +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    sec
  );
};

export var startOfTodaysDay = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to 00:00:00:000
  return today;
};

export var endOfTodaysDay = () => {
  const today = new Date();
  today.setHours(23, 59, 59, 0); // Set time to 00:00:00:000
  return today;
};

export var commonDateFormate = (date) => {
  const today = moment(date).format("DD MMM, YYYY hh:mm A");
  return today;
};

export var onlyDateFormate = (date) => {
  const today = moment(date).format("DD MMM, YYYY");
  return today;
};

export var toFixTwoForDiscountCalculate = (num) => {
  var d = 2 || 0;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n),
    f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
};

// Calculate Retail Price from Cash Price by location cash discount percentage.
export var calculateDiscountedPrice = (salePrice, discountPercentage) => {
  if (!salePrice && !discountPercentage) return "";

  const price = parseFloat(salePrice);
  const discount = parseFloat(discountPercentage);

  if (isNaN(price) || isNaN(discount)) return "";

  if (price && !discount) return price.toFixed(2);

  // const discountedPrice = (price + (price * (discount / 100)));
  var total = toFixTwoForDiscountCalculate( /// twofix issue of 2.5*79.29 = 192.225 but twoFix in this code will be 192.22 instead of 192.23
    parseFloat(price) * (parseFloat(discount) / 100)
  ).toFixed(2);
  return toFixTwoForDiscountCalculate(
    parseFloat(price) + parseFloat(total)
  ).toFixed(2);
};

// Reverse Calculate Cash Price from Retail Price by location cash discount percentage.
export var reverseCalculateDiscountedPrice = (
  salePrice,
  discountPercentage
) => {
  if (!salePrice && !discountPercentage) return "";

  const price = parseFloat(salePrice);
  const discount = parseFloat(discountPercentage);

  if (isNaN(price) || isNaN(discount)) return "";

  if (price && !discount) return price.toFixed(2);

  // const discountedPrice = ((price * 100) / (discount + 100));
  const discountedPrice = price / (1 + discount / 100);
  // const discountedPrice = price - (price * discount / 100);

  return discountedPrice.toFixed(2);
};

// UPC Barcode Logic
export var calculateEAN13CheckDigit = (digits) => {
  // Ensure we have exactly 12 digits (EAN-13 without check digit)
  if (typeof digits !== 'string' || digits.length !== 12 || !/^\d+$/.test(digits)) {
      throw new Error('Input must be a string of 12 digits');
  }
  // Convert string to array of numbers
  const numbers = digits.split('').map(Number);
  // Calculate sum according to EAN-13 algorithm
  // Multiply each digit by 1 or 3 alternately and sum
  const sum = numbers.reduce((acc, digit, index) => {
      const multiplier = index % 2 === 0 ? 1 : 3;
      return acc + (digit * multiplier);
  }, 0);
  // Calculate check digit: (10 - (sum mod 10)) mod 10
  const checkDigit = (10 - (sum % 10)) % 10;
  return checkDigit;
};

// UPC Barcode Logic
export var processExpandedBarcodeFormat = (barcode) => {
  let finalBarcode = barcode;
  if (null != finalBarcode && "" != finalBarcode) {
    let firstTwoDigits = finalBarcode.slice(0,2);
    if (barcode.length === 8) {
      let upceCode = finalBarcode;
      let manufacturerCode = upceCode.slice(0, 6); // First 6 digits of UPC-E
      let lastDigit = upceCode[6]; // 7th digit of UPC-E
      let upcaCode = '';
      if (lastDigit >= '0' && lastDigit <= '2') {
        // Last digit 0-2: Expand by adding zeroes between 3rd and 4th digit
        upcaCode =
          manufacturerCode.slice(0, 3) +
          lastDigit +
          '0000' +
          manufacturerCode.slice(3);
      } else if (lastDigit === '3') {
        // Last digit 3: Add zeroes after the 4th digit
        upcaCode =
          manufacturerCode.slice(0, 4) + '00000' + manufacturerCode.slice(4);
      } else if (lastDigit === '4') {
        // Last digit 4: Add zeroes after the 5th digit
        upcaCode = manufacturerCode.slice(0, 5) + '00000' + manufacturerCode[5];
      } else if (lastDigit >= '5' && lastDigit <= '9') {
        // Last digit 5-9: Replace last three digits
        upcaCode = manufacturerCode.slice(0, 6) + '0000' + lastDigit;
      }
      // Add leading zero for the full UPC-A format
      finalBarcode = '0' + upcaCode;
      finalBarcode = finalBarcode.padStart(13, '0');
    } else if (barcode.length === 12) {
      finalBarcode = finalBarcode.slice(0,-1)
      finalBarcode = finalBarcode.padStart(13, 0);
    } else if (barcode.length === 13 && firstTwoDigits != "00") {
      const lastChar = finalBarcode.charAt(finalBarcode.length - 1);
      const checkDigit = calculateEAN13CheckDigit(finalBarcode.slice(0,-1))
      if(lastChar == checkDigit){
        finalBarcode = finalBarcode.slice(0,-1)
      }
      finalBarcode = finalBarcode.padStart(13, 0);
    }
  }
  return finalBarcode;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Calculate current margin percentage based on cost and sale price
 * @param {number} costPrice - The cost/purchase price
 * @param {number} salePrice - The selling price
 * @returns {object} Object containing current margin percentage and status
 */
export function calculateCurrentMarginPercentage(costPrice, salePrice) {
  if(isNaN(costPrice) || isNaN(salePrice) || costPrice == 0 || salePrice == 0) return '0.00%'
    // Calculate profit/loss amount
    const difference = salePrice - costPrice;
    
    // Calculate percentage
    const percentage = (difference / salePrice) * 100;
    
    // Determine if it's profit or loss
    const status = percentage == 0 ? 'neutral' : percentage > 0 ? 'profit' : 'loss';
  
    // return status+' '+Math.abs(percentage.toFixed(2)) + '%'
  
    return {
      percentage: Math.abs(percentage)?.toFixed(2), // Absolute value, rounded to 2 decimals
      status: status
    };
  }