import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
  SingleOrderView,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  commonDateFormate,
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
const VariableItem = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const stateData = useLocation();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnSM = isMobile ? { width: 130 } : { flex: 0.8 };
  const [isExtraLarge] = useMediaQuery("(min-width: 1710px)");
  const pageRef = useRef(false);
  const [isLarge] = useMediaQuery("(max-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [applyClicked, setApplyClicked] = useState(false);
  const searchInputHideRef = useRef(false);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page - 1
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "order_id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    category_names: false,
  });
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const columnNames = [
    {
      field: "order_id",
      headerName: "Order Id",
      sortable: true,
      width: 120,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          myContext.handleLoading(true);
          handleDisplayOrderData(rowData.order_id);
        };
        return (
          <Text
            onClick={handleView}
            cursor="pointer"
            color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration="underline"
            fontWeight={600}
          >
            {rowData.order_id}
          </Text>
        );
      },
    },
    {
      field: "location_name",
      headerName: "Location",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "order_date",
      headerName: "Order Date",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "product_name",
      headerName: "Product Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "category_names",
      headerName: "Category",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: true,
      ...actionColumnSM,
    },
    {
      field: "sale_price",
      headerName: "Sale Price($)",
      sortable: true,
      ...actionColumnSM,
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      sortable: true,
      ...actionColumn,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEmpView = () => {
          myContext.handleLoading(true);
          const id = rowData?.emp_id;
          let data = {
            reportFlag: "variable_item_report",
            location: JSON.stringify(formik.values.selectedLoactions),
            category: JSON.stringify(formik.values.selectedCategories),
            dateFrom: formik.values.fromDate,
            dateTo: formik.values.toDate,
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            filter: searchQuery,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
          };
          props.navigate(`${Constants.EMPLOYEES_EDIT + id}`, {
            state: data,
          });
        };
        return (
          <Text
            onClick={handleEmpView}
            cursor="pointer"
            color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration="underline"
            fontWeight={600}
          >
            {rowData.employee_name}
          </Text>
        );
      },
    },
  ];
  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderId(id);
      onDrawerOpen();
    },
    [orderId]
  );
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions:
        undefined !== stateData?.state?.location
          ? JSON.parse(stateData?.state?.location)
          : undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ? loaderResponse?.data?.data?.location
          : [],
      selectedCategories: undefined !== stateData?.state?.category
      ? JSON.parse(stateData?.state?.category): [],
      fromDate:
        undefined !== stateData?.state?.dateFrom
          ? stateData?.state?.dateFrom
          : "",
      toDate:
        undefined !== stateData?.state?.dateTo ? stateData?.state?.dateTo : "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setOlColumnVisibilityModel((prev) => ({
              ...prev,
              location_name: false,
            }));
          }
          setLocations(loaderResponse?.data?.data?.location);
          setCategories(loaderResponse?.data?.data?.category);
          if (undefined !== stateData?.state && null !== stateData?.state) {
            formik.setFieldValue(
              "selectedLoactions",
              JSON.parse(stateData?.state?.location)
            );
            formik.setFieldValue("fromDate", stateData?.state?.dateFrom);
            formik.setFieldValue("toDate", stateData?.state?.dateTo);
            getVariableItemData();
          }
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);
  useEffect(() => {
    if (pageRef.current) {
      getVariableItemData();
    }
  }, [paginationModel, sortModel, searchQuery]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        if (!searchInputHideRef.current && actionResponse.error.data === 0) {
          searchInputHideRef.current = true;
        }
        // error({ actionData });
        setTableData([]);
        pageRef.current = false;
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Variable Item Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.VARIABLE_ITEM_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          searchInputHideRef.current = true;
          setTableData(actionResponse.data.data.variableItemData);
          setTotalCount(actionResponse.data.data.totalItems);
          pageRef.current = false;
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        pageRef.current = false;
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    formik.setTouched(
      {
        selectedLoactions: true,
        fromDate: true,
        toDate: true,
      },
      true
    );
    if(formik.values.selectedLoactions?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLoactions ||
      formik.errors.fromDate ||
      formik.errors.toDate
    ) {
      return;
    }
    if (formik.values.selectedLoactions.length > 0) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          let data = {
            getreport: false,
            category: JSON.stringify(formik.values.selectedCategories),
            location: JSON.stringify(formik.values.selectedLoactions),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            type: "xls",
          };
          myContext.handleLoading(true);
          submit(data, {
            method: Constants.POST,
            path: Constants.VARIABLE_ITEM_ROUTE,
          });
        // } else {
        //   clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      // } else {
      //   clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    // } else {
    //   clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    searchInputHideRef.current = false;
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getVariableItemData = () => {
    formik.setTouched(
      {
        selectedLoactions: true,
        fromDate: true,
        toDate: true,
      },
      true
    );
    if(formik.values.selectedLoactions?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLoactions ||
      formik.errors.fromDate ||
      formik.errors.toDate
    ) {
      return;
    }
    if (formik.values.selectedLoactions.length > 0) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          setApplyClicked(true);
          let data = {
            getreport: true,
            location: JSON.stringify(formik.values.selectedLoactions),
            category: JSON.stringify(formik.values.selectedCategories),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            query: JSON.stringify({
              limit: paginationModel.pageSize,
              filter: searchQuery,
              page: paginationModel.page + 1,
              order:
                sortModel.length > 0
                  ? sortModel[0].field
                  : sortRef.current[0].field,
              order_type:
                sortModel.length > 0
                  ? sortModel[0].sort
                  : sortRef.current[0].sort,
            }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.VARIABLE_ITEM_ROUTE,
          });
        // } else {
        //   clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      // } else {
      //   clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    // } else {
    //   clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLoactions", e.value);
  };
  const handleCategorySelect = (e) => {
    formik.setFieldValue("selectedCategories", e.value);
  };
  const handleResetAllData = () => {
    searchInputHideRef.current = false;
    formik.setFieldValue("selectedLoactions", []);
    formik.setFieldValue("selectedCategories", []);
    formik.setFieldValue("fromDate", "");
    formik.setFieldValue("toDate", "");
    formik.setFieldTouched("toDate", false);
    formik.setFieldTouched("fromDate", false);
    pageRef.current = false;
    setSortModel([
      {
        field: "order_id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setApplyClicked(false);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    // formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card justify-content-center"
          flexDirection={isLarge ? "column" : "row"}
          spacing={4}
          width={isLarge ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDropDown
                options={locations}
                value={formik.values.selectedLoactions}
                onChange={handleOptionSelect}
                resetFilterOnHide={true}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                multiSelect={true}
                optionLabel="name"
                placeholder="Select Locations*"
                width={isExtraLarge ? "17rem" : "100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDropDown
                options={categories}
                value={formik.values.selectedCategories}
                onChange={handleCategorySelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedCategories");
                  formik.handleBlur(e);
                }}
                multiSelect={true}
                optionLabel="name"
                placeholder="Select Categories"
                width={isExtraLarge ? "17rem" : "100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedCategories &&
                formik.errors.selectedCategories ? (
                  <span>{formik.errors.selectedCategories}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>

          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                readOnlyInput={true}
                value={formik.values.fromDate}
                label={"From Date*"}
                width={isExtraLarge ? "17rem" : "100%"}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                readOnlyInput={true}
                value={formik.values.toDate}
                label={"To Date*"}
                width={isExtraLarge ? "17rem" : "100%"}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </>
    );
  };
  const modifiedData = tableData.map((data, i) => ({
    id: i + 1,
    order_date: commonDateFormate(data.order_date),
    order_id: data.order_id,
    location_name: data.location_name,
    product_name: data.product_name,
    quantity: data.quantity,
    employee_name: data.employee_name,
    sale_price: data.sale_price,
    emp_id: data.employee_id,
    category_names: data.category_names,
    description:
      data.description != null && data.description != null
        ? data.description
        : "N.A.",
  }));
  const onOrderDrawerClose = () => {
    onDrawerClose();
    setOrderId("");
  };
  const handleSearchList = () => {
    // search time get active and deactive list
    pageRef.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    // getVariableItemData();
    // setLoading(true);
  };
  const handleClear = () => {
    // clear input value and get all data
    pageRef.current = true;
    setSearchQuery("");
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <SingleOrderView
        visible={isDrawerOpen}
        onHide={onOrderDrawerClose}
        position="right"
        showCloseIcon={true}
        orderId={orderId}
        breadCrumNames={[]}
        handleClick={(i) => {
          // if (i == 0) {
          //   props.navigate(Constants.REPORT_PATH);
          // }
        }}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Variable Item"]}
          breadCrumTitle={"Variable Item"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isLarge ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isLarge ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isLarge ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              isDisabled={applyClicked ? false : true}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={buttonDisable}
              // isDisabled={
                // formik.values.selectedLoactions?.length === 0 ||
                // formik.values.fromDate === "" ||
                // formik.values.toDate === "" ||
                // formik.errors.selectedLoactions ||
                // formik.errors.fromDate ||
                // formik.errors.toDate ||
              //   buttonDisable
              // }
              onClick={getVariableItemData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mb={3}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              <Spacer />
            </Flex>
          ) : null}
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.VARIABLE_ITEM_CSV}
              />
              {/* <PosDataGridTable
                    columns={totalColumnName}
                    rows={totalDailySales}
                    totalcount={totalcount}
                    rowId={"totalDailyslesData"}
                    hideFooter={true}
                  /> */}
            </Flex>
          ) : (
            <PosNoDataFound title={"There are no Variable Item."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(VariableItem);
